// Heavily inspired from: https://github.com/jonatanklosko/material-ui-confirm
import type { FC, ReactNode } from 'react';
import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { ButtonProps, DialogProps } from '@mui/material';

import i18n from 'src/services/i18n/i18n';
import ConfirmDialog from 'src/components/ConfirmDialog/ConfirmDialog';

const DEFAULT_OPTIONS = {
  onClose: {
    disableBackdropClick: false,
    shouldResolve: false,
    valueReason: 'onClose',
  },
  onPrimary: { shouldResolve: true, valueReason: true },
  onSecondary: { shouldResolve: false, valueReason: 'onSecondary' },
  primaryText: i18n.t('General.confirm'),
  secondaryText: i18n.t('General.abort'),
  title: i18n.t('General.areYouSure'),
};
export interface ConfirmOptions {
  autoCloseAfter?: number;
  content?: ReactNode;
  description?: ReactNode;
  dialogProps?: Omit<DialogProps, 'onClose' | 'open'>;
  onClose?: {
    disableBackdropClick?: boolean;
    shouldResolve?: boolean;
    valueReason?: any;
  };
  onPrimary?: { shouldResolve?: boolean; valueReason?: any };
  onSecondary?: { shouldResolve?: boolean; valueReason?: any };
  primaryButtonProps?: ButtonProps;
  primaryText?: ReactNode;
  secondaryButtonProps?: ButtonProps;
  secondaryText?: ReactNode;
  title?: ReactNode;
  variant?: 'dark' | 'light';
  hideCloseButton?: boolean;
  hideSecondaryButton?: boolean;
}

export interface ConfirmProviderProps {
  children?: ReactNode;
  defaultOptions?: ConfirmOptions;
}

const buildOptions = (
  defaultOptions: ConfirmOptions,
  confirmOptions: ConfirmOptions,
) => {
  const dialogProps = {
    ...defaultOptions.dialogProps,
    ...(confirmOptions.dialogProps || {}),
  };
  const onClose = {
    ...(defaultOptions.onClose || DEFAULT_OPTIONS.onClose),
    ...(confirmOptions.onClose || {}),
  };
  const onPrimary = {
    ...(defaultOptions.onPrimary || DEFAULT_OPTIONS.onPrimary),
    ...(confirmOptions.onPrimary || {}),
  };
  const onSecondary = {
    ...(defaultOptions.onSecondary || DEFAULT_OPTIONS.onSecondary),
    ...(confirmOptions.onSecondary || {}),
  };
  const primaryButtonProps = {
    ...defaultOptions.primaryButtonProps,
    ...(confirmOptions.primaryButtonProps || {}),
  };
  const secondaryButtonProps = {
    ...defaultOptions.secondaryButtonProps,
    ...(confirmOptions.secondaryButtonProps || {}),
  };

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...confirmOptions,
    dialogProps,
    onClose,
    onPrimary,
    onSecondary,
    primaryButtonProps,
    secondaryButtonProps,
  };
};

export const ConfirmContext = createContext(
  (confirmOptions?: ConfirmOptions) => new Promise((resolve, reject) => {}),
);

const ConfirmProvider: FC<ConfirmProviderProps> = ({
  children,
  defaultOptions = {},
}) => {
  const [options, setOptions] = useState({
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
  });
  const [resolveReject, setResolveReject] = useState<
    [(value: unknown) => void, (reason?: any) => void]
  >([() => {}, () => {}]);
  const [resolve, reject] = resolveReject;
  const [open, setOpen] = useState(false);

  const confirm = useCallback(
    (confirmOptions: ConfirmOptions = {}) =>
      new Promise((_resolve, _reject) => {
        setOptions(buildOptions(defaultOptions, confirmOptions));
        setResolveReject([_resolve, _reject]);
        setOpen(true);
      }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePrimary = useCallback(() => {
    const { shouldResolve, valueReason } = options.onPrimary;

    shouldResolve ? resolve(valueReason) : reject(valueReason);
    setOpen(false);
  }, [resolve, reject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSecondary = useCallback(() => {
    const { shouldResolve, valueReason } = options.onSecondary;

    shouldResolve ? resolve(valueReason) : reject(valueReason);
    setOpen(false);
  }, [resolve, reject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = useCallback(
    (event: any, reason: string) => {
      const { disableBackdropClick, shouldResolve, valueReason } =
        options.onClose;

      if (disableBackdropClick && reason && reason === 'backdropClick') return;

      shouldResolve ? resolve(valueReason) : reject(valueReason);
      setOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resolve, reject],
  );

  useEffect(() => {
    if (open && options.autoCloseAfter)
      setTimeout(handleClose, options.autoCloseAfter);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>

      <ConfirmDialog
        onClose={handleClose}
        onPrimary={handlePrimary}
        onSecondary={handleSecondary}
        open={open}
        options={options}
      />
    </Fragment>
  );
};

export const useConfirm = () => useContext(ConfirmContext);

export default ConfirmProvider;

export enum CareAllowanceOptions {
  YES = 1,
  PARTIALLY = 2,
  NO = 3,
}

interface NeedsAssessmentData {
  deploymentPlanning: {
    careAllowance: boolean;
    privateAccount: boolean;
    dispositionSelection: 'weekly' | 'biWeekly';
    dispositionHoursWeekly: number;
    dispositionHoursBiWeekly: number;
  };
  freeOfChargeAidRequested: {
    homeEmergencyCall: boolean;
    nursingAdvice: boolean;
    shouldOrderCareBox: boolean;
  };
  healthInsuranceBenefits: {
    selection: 'EL&VP' | 'withoutVP';
  };
  healthInsuranceInfo: {
    budgetEl: number;
    budgetVp: number;
    registeredCarer: string;
    vpAlreadyBilled: boolean;
  };
  signatureCustomer: string;
}

export interface NeedsAssessmentFormData extends NeedsAssessmentData {
  receivingCareAllowance: {
    careService: string;
    // Cannot be only boolean as RadioButtons convert value to string but we send boolean to backend
    qualityAssuranceInterview: 'yes' | 'no';
    qualityAssurancePerson: string;
    value: CareAllowanceOptions;
  };
  requiredServices: {
    bedLinen: boolean;
    cooking: boolean;
    errands: boolean;
    ironing: boolean;
    normalChores: boolean;
    shopping: boolean;
    strolling: boolean;
    windowCleaning: boolean;
  };
  specifics: { animals: string; others: string; smoker: 'yes' | 'no' };
  startDate: Date;
  submit?: string;
}

export interface NeedsAssessmentPostData extends NeedsAssessmentData {
  receivingCareAllowance: {
    careService?: string;
    qualityAssuranceInterview: boolean;
    qualityAssurancePerson?: string;
    value: number;
  };
  requiredServices: {
    label: string;
    value: boolean;
  }[];
  specifics: { animals: string; others: string; smoker: boolean };
  startDate: string;
  teamManagerComment: string;
}

export const themeColors = {
  error: '#ff2800',
  errorBackground: '#ffd4cc',
  errorFont: '#b21c00',
  primary1: '#f0f8ff',
  primary2: '#c0d9eb',
  primary3: '#5894bf',
  primary4: '#223645',
  primary5: '#06141f',
  secondary1: '#ffebeb',
  secondary2: '#ffb2b5',
  secondary3: '#ff595f',
  secondary4: '#c52127',
  secondary5: '#610508',
  shade1: '#f0f3f5',
  shade2: '#dce2e5',
  shade3: '#a5adb2',
  shade4: '#55595c',
  shade5: '#282c2e',
  success: '#1bca83',
  successBackground: '#daffee',
  successFont: '#29664d',
  warning: '#f2ae00',
  warningBackground: '#ffedbe',
  warningFont: '#7b4d08',
  white: '#ffffff',
};

export const themeGradients = {
  primary: `linear-gradient(10deg, ${themeColors.primary4} 60%, #446688 120%)`,
  secondary: `linear-gradient(10deg, ${themeColors.secondary4} 60%, ${themeColors.secondary3} 120%)`,
};

export default themeColors;

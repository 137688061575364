import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';

import { TestIds } from 'src/testIds';
import { routes } from 'src/services/routing';
import Button from 'src/components/Button/Button';
import Page from 'src/components/Page/Page';

import useStyles from './NotFoundView.styles';

const ViewTestIds = TestIds.views.notFound;

const NotFoundView: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page
      className={classes.root}
      data-test-id={ViewTestIds.pageWrapper}
      title={t('Views.NotFound.title')}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Box mb={4}>
            <Typography align="center" variant="h1" gutterBottom>
              {t('Views.NotFound.header')}
            </Typography>

            <Typography align="center" component="h2" variant="h4" gutterBottom>
              {t('Views.NotFound.subheader')}
            </Typography>
          </Box>

          <Box alignItems="center" display="flex" flexDirection="column" mb={4}>
            <Button
              color="secondary"
              component={Link}
              to={routes.app.routes.home.path}
              variant="contained"
            >
              {t('Views.NotFound.home')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default NotFoundView;

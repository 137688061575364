import type { FC } from 'react';
import type { TabsProps } from '@mui/material';
import {
  addMonths,
  closestIndexTo,
  eachDayOfInterval,
  format,
  isToday,
  subMonths,
} from 'date-fns';
import { de, enIN } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';

import hasAppointmentsAtDate from 'src/utils/hasAppointmentsAtDate';
import { hasBlockTimeEntryAtDate } from 'src/utils/hasBlockTimeEntryAtDate';
import i18n from 'src/services/i18n/i18n';
import {
  TimeIntervalSelectionSlider,
  TimeIntervalTab,
} from 'src/components/TimeIntervalSelectionSlider/TimeIntervalSelectionSlider';

export interface DaySelectionSliderProps extends TabsProps {
  onDateChange?: (currentDate: Date) => void;
}

export const DaySelectionSlider: FC<DaySelectionSliderProps> = ({
  className,
  onDateChange = () => {},
  ...props
}) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const dateOneMonthAgo = subMonths(today, 1);
  const dateInThreeMonth = addMonths(today, 3);
  const dates = eachDayOfInterval({
    end: dateInThreeMonth,
    start: dateOneMonthAgo,
  });
  const indexOfToday = closestIndexTo(today, dates);
  const locales: { [key: string]: Locale } = { de, en: enIN };
  const locale = locales[i18n.language];

  const tabs: TimeIntervalTab[] = dates.map((date) => ({
    date: date,
    hasAppointments: hasAppointmentsAtDate(date),
    hasBlockTimes:
      hasBlockTimeEntryAtDate(date, 'single') ||
      hasBlockTimeEntryAtDate(date, 'recurring'),
    highlight: isToday(date),
    lowerLabel: `${format(date, 'dd')}`,
    testLabel: `${format(date, 'Y-MM-dd', { locale: locale })}`,
    upperLabel: `${format(date, 'EEEEEE', { locale: locale })}`,
  }));

  const handleTabChange = (value: number) => {
    onDateChange(dates[value]);
  };

  return (
    <TimeIntervalSelectionSlider
      tabs={tabs}
      indexOfCurrentDate={indexOfToday}
      onTabChange={handleTabChange}
      className={className}
      {...props}
    />
  );
};

export default observer(DaySelectionSlider);

import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundImage: theme.palette.gradients.primary,
      minHeight: '100vh',
      padding: `${theme.spacing(12)} 0px`,
      color: theme.palette.text.contrast,
    },

    backLink: {
      top: theme.spacing(4),
      left: theme.spacing(4),
      position: 'absolute',
    },
  }),
  { name: 'ForgotPasswordView' },
);

export default useStyles;

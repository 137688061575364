import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
    },
    appointmentCreateButton: {
      right: theme.spacing(2),
      bottom: `calc(${theme.settings.bottomNavHeight}px + ${theme.spacing(
        2.5,
      )})`,
      position: 'fixed',
      zIndex: 10,

      // Fixed position from right, regarding maximum container width on screens larger than 'md'.
      [theme.breakpoints.up('md')]: {
        // eslint-disable-next-line prettier/prettier
        right: `calc(0px + ${theme.spacing(2)} + ${theme.spacing(
          3,
        )} + (100vw - ${theme.breakpoints.values.md}px) / 2)`,
      },
    },
  }),
  { name: 'HomeView' },
);

export default useStyles;

import type { FC } from 'react';
import { useState } from 'react';
import type { TabsProps } from '@mui/material';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { TestIds } from 'src/testIds';
import { WeekYear } from 'src/types';
import type { Theme } from 'src/theme/types';

import useStyles from './TimeIntervalSelectionSlider.styles';

const ComponentTestIds = TestIds.components.timeIntervalSelectionSlider;

export interface TimeIntervalTab {
  date: Date | WeekYear;
  hasAppointments: boolean;
  hasBlockTimes: boolean;
  highlight: boolean;
  lowerLabel?: string;
  testLabel: string;
  upperLabel?: string;
}

export interface TimeIntervalSliderProps extends TabsProps {
  tabs: TimeIntervalTab[];
  indexOfCurrentDate?: number;
  onTabChange?: (arg0: any) => void;
}

export const TimeIntervalSelectionSlider: FC<TimeIntervalSliderProps> = ({
  className,
  onTabChange = () => {},
  tabs,
  indexOfCurrentDate,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const [sliderTab, setSliderTab] = useState(indexOfCurrentDate);

  const handleChange = (event: any, newValue: number) => {
    setSliderTab(newValue);
    onTabChange(newValue);
  };

  return (
    <Tabs
      classes={{ indicator: classes.muiIndicator }}
      className={clsx(classes.root, className)}
      data-test-id={ComponentTestIds.selectionTabs}
      onChange={handleChange}
      scrollButtons
      textColor="inherit"
      value={sliderTab}
      variant="scrollable"
      {...props}
      allowScrollButtonsMobile
    >
      {tabs.map(
        (
          {
            hasBlockTimes,
            hasAppointments,
            highlight,
            lowerLabel,
            upperLabel,
            testLabel,
          },
          index,
        ) => (
          <Tab
            classes={{
              root: clsx(classes.muiTabRoot),
              selected: clsx(classes.muiSelected),
            }}
            className={clsx({
              [classes.currentDay]: highlight,
            })}
            data-test-id={`${ComponentTestIds.selectionTab}-${testLabel}`}
            key={index}
            value={index}
            label={
              <>
                <Typography
                  style={{ textTransform: 'none' }}
                  variant="subtitle2"
                >
                  {upperLabel}
                </Typography>
                <Typography
                  style={{
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                  variant="subtitle2"
                >
                  {lowerLabel}
                </Typography>
                <Box display="flex" flexDirection="column" gap="1px">
                  <span
                    className={
                      hasAppointments
                        ? clsx(classes.dot, classes.appointmentColor)
                        : classes.placeholderDot
                    }
                  />
                  <span
                    className={
                    hasBlockTimes
                        ? clsx(classes.dot, classes.absenceColor)
                        : classes.placeholderDot
                    }
                  />
                </Box>
              </>
            }
          />
        ),
      )}
    </Tabs>
  );
};

export default observer(TimeIntervalSelectionSlider);

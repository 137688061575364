import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabsProps, Typography } from '@mui/material';

import { TestIds } from 'src/testIds';

export enum TimeIntervalType {
  DAY,
  WEEK,
}

const ComponentTestIds = TestIds.components.timeIntervalSelectionSlider;

export interface TimeIntervalTypeTabsProps extends TabsProps {
  onTypeChange?: (currentType: number) => void;
}

export const TimeIntervalTypeTabs: FC<TimeIntervalTypeTabsProps> = ({
  className,
  onTypeChange = () => {},
  ...props
}) => {
  const { t } = useTranslation();
  const [timeIntervalType, setTimeIntervalType] = useState(0);
  const handleTimeIntervalChange = (event: any, newValue: number) => {
    setTimeIntervalType(newValue);

    onTypeChange(newValue);
  };

  return (
    <Tabs
      value={timeIntervalType}
      onChange={handleTimeIntervalChange}
      centered
      className={className}
      data-test-id={`${ComponentTestIds.selectionTabs}-type`}
      {...props}
    >
      <Tab
        key={TimeIntervalType.DAY}
        value={TimeIntervalType.DAY}
        data-test-id={`${ComponentTestIds.selectionTab}-day`}
        label={
          <Typography
            gutterBottom
            style={{ textTransform: 'none' }}
            variant="subtitle2"
          >
            {t('TimeIntervalTypeTabs.day')}
          </Typography>
        }
      />
      <Tab
        key={TimeIntervalType.WEEK}
        value={TimeIntervalType.WEEK}
        data-test-id={`${ComponentTestIds.selectionTab}-week`}
        label={
          <Typography
            gutterBottom
            style={{ textTransform: 'none' }}
            variant="subtitle2"
          >
            {t('TimeIntervalTypeTabs.week')}
          </Typography>
        }
      />
    </Tabs>
  );
};

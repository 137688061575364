import type { FC, FocusEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Add as AddIcon,
  Create as EditIcon,
  DateRange as CalendarIcon,
  Error as ErrorIcon,
  Info,
} from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Switch,
  Typography,
} from '@mui/material';
import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import clsx from 'clsx';
import { add, differenceInMinutes, format, parse } from 'date-fns';
import type { FormikHelpers, FormikProps } from 'formik';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConfirm } from 'src/contexts/ConfirmContext';
import { TestIds } from 'src/testIds';
import type { Appointment, AppointmentRecordPostData } from 'src/types';
import { getCareBoxInfo } from 'src/services/api/api';
import i18n from 'src/services/i18n/i18n';
import { routes } from 'src/services/routing';
import {
  actions as appointmentRecordsActions,
  getters as appointmentRecordsGetters,
} from 'src/services/state/AppointmentRecords';
import { getters as customersGetters } from 'src/services/state/Customers';
import UserState from 'src/services/state/User';
import Alert from 'src/components/Alert/Alert';
import Button from 'src/components/Button/Button';
import { EndTimeSelect } from 'src/components/EndTimeSelect/EndTimeSelect';
import Form from 'src/components/Form/Form';
import Image from 'src/components/Image/Image';
import { NeedsAssessmentModal } from 'src/components/NeedsAssessment/NeedsAssessmentModal';
import type { SignatureProps } from 'src/components/Signature/Signature';
import Signature from 'src/components/Signature/Signature';
import { StartTimeSelect } from 'src/components/StartTimeSelect/StartTimeSelect';
import TextField from 'src/components/TextField/TextField';

import useStyles from 'src/components/AppointmentRecordForm/AppointmentRecordForm.styles';

const {
  startTimeField: startTimeFieldTestId,
  endTimeField: endTimeFieldTestId,
} = TestIds.components;
const ComponentTestIds = TestIds.components.appointmentRecordForm;

export interface AppointmentRecordFormProps {
  afterSubmit?: () => void;
  appointment: Appointment;
  className?: string;
}

interface Values extends AppointmentRecordPostData {
  orderCareBox?: boolean;
  submit?: string;
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const validationSchema = Yup.object().shape({
  dateDay: Yup.date()
    .nullable()
    .required(i18n.t('General.Form.dateDayRequired'))
    // Note: perform date validation in .test instead of simply using .max(lastDayOfMonth), since that variable
    // otherwise would be out-of-date after some time (see ticket #558)
    .test(
      'validate-same-month',
      i18n.t('AppointmentRecordForm.Fields.DateDay.canNotBeFollowingMonth'),
      (value, context) => {
        if (!value) {
          return false;
        }

        const today = new Date();
        const lastDayOfMonth = new Date();
        lastDayOfMonth.setFullYear(
          today.getFullYear(),
          today.getMonth() + 1,
          0,
        );
        return value < lastDayOfMonth;
      },
    )
    .test(
      'date-not-too-past',
      i18n.t('AppointmentRecordForm.Fields.StartTime.tooFarInThePast'),
      (value) => {
        const today = new Date();
        const pastMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate(),
        );
        return !value || value >= pastMonth;
      },
    ),
  startTime: Yup.date()
    .nullable()
    .required(i18n.t('AppointmentRecordForm.Fields.StartTime.required')),
  endTime: Yup.date()
    .nullable()
    .required(i18n.t('AppointmentRecordForm.Fields.EndTime.required'))
    .when(
      'startTime',
      (startTime, schema) =>
        startTime &&
        schema.min(
          add(startTime, { minutes: 1 }),
          i18n.t('AppointmentRecordForm.Fields.EndTime.mustBeAfterStartTime'),
        ),
    ),
  travelDistanceKm: Yup.number()
    .min(0, i18n.t('AppointmentRecordForm.Fields.TravelDistanceKm.positive'))
    .integer(i18n.t('AppointmentRecordForm.Fields.TravelDistanceKm.integer'))
    .required(i18n.t('AppointmentRecordForm.Fields.TravelDistanceKm.required')),

  signatureDopp: Yup.string().when('wasFirstInterview', {
    is: true,
    then: Yup.string().required(
      i18n.t('AppointmentRecordForm.Fields.SignatureDopp.required'),
    ),
  }),
  signatureRecordCustomer: Yup.string().required(
    i18n.t('AppointmentRecordForm.Fields.SignatureRecordCustomer.required'),
  ),
  signatureRecordEmployee: Yup.string().required(
    i18n.t('AppointmentRecordForm.Fields.SignatureRecordEmployee.required'),
  ),
});
/* eslint-enable */

const initialModalOptions = {
  onSuccess: () => {},
  open: false,
  signatory: 'signatory',
};

export const AppointmentRecordForm: FC<AppointmentRecordFormProps> = ({
  afterSubmit: _afterSubmit,
  appointment,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<any>();
  const confirm = useConfirm();
  const confirmStartTime = useConfirm();
  const confirmEndTime = useConfirm();
  const canConductFirstInterviews = UserState.user?.canConductFirstInterviews;
  const customer = customersGetters.getCustomerById(appointment.customerId);
  const formikRef = useRef<FormikProps<Values>>(null);
  const signatoryFor = location.hash;
  const [modalOptions, setModalOptions] =
    useState<SignatureProps>(initialModalOptions);
  const [needsAssessmentFormSubmitted, setNeedsAssessmentFormSubmitted] =
    useState(false);
  const [openNeedsAssessmentForm, setOpenNeedsAssessmentForm] = useState(false);
  const [shouldOrderCareBox, setShouldOrderCareBox] = useState(false);
  const [shouldDisableCareBox, setShouldDisableCareBox] = useState(false);
  const [showInfoText, setShowInfoText] = useState(false);
  const afterSubmit =
    _afterSubmit || (() => history.push(routes.app.routes.home.path));
  const today = new Date();
  const appointmentId = appointment.id;
  const recurrenceIndex = appointment.recurrenceIndex;
  const dateDay = appointment.dateDay;
  const endTime = parse(appointment.endTime, 'HH:mm:ss', today).toString();
  const startTime = parse(appointment.startTime, 'HH:mm:ss', today).toString();
  const appointmentDate = new Date(dateDay);
  // should only be signable if it's not too far in the future, not next month
  const isSignable =
    appointmentDate < new Date(today.getFullYear(), today.getMonth() + 1, 1);

  // Get a potentially pending appointment record for this appointment.
  let appointmentRecordPending =
    appointmentRecordsGetters.getAppointmentRecordPending(
      appointment.id,
      recurrenceIndex,
    );

  // Format the start- and endTime values of the AppointmentRecordPending to fit the form inputs.
  if (appointmentRecordPending) {
    appointmentRecordPending = {
      ...appointmentRecordPending,
      endTime: parse(
        appointmentRecordPending.endTime,
        'HH:mm',
        today,
      ).toString(),
      startTime: parse(
        appointmentRecordPending.startTime,
        'HH:mm',
        today,
      ).toString(),
    };
  }

  // Use the pending appointment record or the appointments data to pre-fill the form.
  const initialValues: Values = appointmentRecordPending || {
    appointmentId,
    dateDay,
    endTime,
    orderCareBox: false,
    recurrenceIndex,
    signatureDoa: '',
    signatureDopp: '',
    signatureRecordCustomer: '',
    signatureRecordEmployee: '',
    startTime,
    submit: '',
    travelDistanceKm: '' as unknown as number,
    wasFirstInterview: false,
  };

  /**
   * Add hash with the provided signatory to the location and keep the stackRoot while navigating.
   */
  const goToSignatureModal = (signatory: string) => {
    history.push(`${location.pathname}${location.search}#${signatory}`, {
      stackRoot: location.state.stackRoot,
    });
  };

  /**
   * Scrolls to the first third of the select drop down if no item has been selected yet.
   */
  const handleSelectFocus = (
    event: FocusEvent<HTMLDivElement>,
    value?: string,
  ) => {
    if (value) return;

    const menuElement = event.target.closest('[class*="MuiList-root"]')
      ?.parentElement;

    // Only scroll if the drop down was not scrolled already to prevent
    // strange behavior caused by bubbling focus events.
    if (menuElement && !menuElement.scrollTop) {
      menuElement.scrollTo({ top: menuElement?.scrollHeight / 3 });
    }
  };

  /**
   * Format endTimeSteps depending on the startTime value, handles StartTime
   * change and confirmation modal
   */
  const handleStartTimeChange = (
    event: SelectChangeEvent,
    initialStartTime?: string,
  ) => {
    const startTime = (event.target.value as string) ?? undefined;

    if (startTime !== initialStartTime) {
      confirmStartTime({
        description: t('AppointmentRecordForm.confirmStartTime'),
        hideCloseButton: true,
        onClose: { disableBackdropClick: true },
        primaryText: t('AppointmentRecordForm.confirmTime'),
        secondaryText: t('AppointmentRecordForm.rejectTime'),
        title: t('AppointmentRecordForm.modalTitleStartTime'),
      })
        .then(() => {
          formikRef.current?.setFieldValue('startTime', startTime);
        })
        .catch(() => {
          formikRef.current?.setFieldValue('startTime', initialStartTime);
        });
    } else {
      formikRef.current?.setFieldValue('startTime', startTime);
    }
  };

  /**
   * Handles EndTime change and confirmation modal
   */
  const handleEndTimeChange = (event: any, initialEndTime?: string) => {
    const endTime = (event.target.value as string) ?? undefined;

    if (endTime !== initialEndTime) {
      confirmEndTime({
        description: t('AppointmentRecordForm.confirmEndTime'),
        hideCloseButton: true,
        onClose: { disableBackdropClick: true },
        primaryText: t('AppointmentRecordForm.confirmTime'),
        secondaryText: t('AppointmentRecordForm.rejectTime'),
        title: t('AppointmentRecordForm.modalTitleEndTime'),
      })
        .then(() => {
          formikRef.current?.setFieldValue('endTime', endTime);
        })
        .catch(() => {
          formikRef.current?.setFieldValue('endTime', initialEndTime);
        });
    } else {
      formikRef.current?.setFieldValue('endTime', endTime);
    }
  };

  /**
   * Handles opening the signature modal with the appropriate texts for employees or customers.
   */
  const handleModal = (signatoryFor: string) => {
    if (!formikRef.current) return;

    const setFieldValue = formikRef.current.setFieldValue;
    const values = formikRef.current.values;
    const day = format(new Date(values.dateDay), 'dd.MM.yyyy');
    const duration =
      differenceInMinutes(
        new Date(values.endTime),
        new Date(values.startTime),
      ) / 60;

    let description;
    let open = false;
    let title;
    let signatory;

    switch (signatoryFor) {
      case 'signatureRecordCustomer':
        description = t(
          'AppointmentRecordForm.Fields.SignatureRecordCustomer.description',
          {
            count: duration,
            day,
          },
        );
        open = true;
        signatory = t('General.customer');
        title = t('AppointmentRecordForm.Fields.SignatureRecordCustomer.label');
        break;

      case 'signatureRecordEmployee':
        description = t(
          'AppointmentRecordForm.Fields.SignatureRecordEmployee.description',
        );
        open = true;
        signatory = t('General.employee');
        title = t('AppointmentRecordForm.Fields.SignatureRecordEmployee.label');
        break;

      case 'signatureDoa':
        description = t(
          'AppointmentRecordForm.Fields.SignatureDoa.description',
        );
        open = true;
        signatory = t('General.customer');
        title = t('AppointmentRecordForm.Fields.SignatureDoa.label');
        break;

      case 'signatureDopp':
        description = t(
          'AppointmentRecordForm.Fields.SignatureDopp.description',
        );
        open = true;
        signatory = t('General.customer');
        title = t('AppointmentRecordForm.Fields.SignatureDopp.label');
        break;

      default:
        description = '';
        open = false;
        signatory = '';
        title = '';
        break;
    }

    setModalOptions({
      ...modalOptions,
      description,
      onSuccess: (signature) => setFieldValue(signatoryFor, signature),
      open,
      signatory,
      title,
    });
  };

  /**
   * Prepare the form`s values and handle success and error cases after submitting them.
   */
  const handleSubmit = async (
    values: Values,
    { setErrors }: FormikHelpers<Values>,
  ) => {
    try {
      // Notify user on unusual mileage
      if (values.travelDistanceKm >= 100) {
        await confirm({
          description: t('AppointmentRecordForm.highDistanceAlertDescription'),
          primaryText: t('AppointmentRecordForm.highDistanceAlertSubmit'),
          title: t('AppointmentRecordForm.highDistanceAlertTitle'),
        });
      }

      // Get user confirmation before submitting.
      await confirm({
        description: t('AppointmentRecordForm.submitDescription'),
        primaryText: shouldOrderCareBox
          ? `${t('AppointmentRecordForm.submitOrder')} ${t(
              'AppointmentRecordForm.submit',
            )}`
          : t('AppointmentRecordForm.submit'),
        title: t('AppointmentRecordForm.submitTitle'),
      });

      // Clone values then parse them to usable post data for the createAppointment request.
      const data = { ...values };

      delete data.submit;
      data.endTime = format(new Date(data.endTime), 'HH:mm');
      data.startTime = format(new Date(data.startTime), 'HH:mm');

      // Wait for the request.
      await appointmentRecordsActions.createAppointmentRecord({ data });

      // Show a success snackbar message.
      enqueueSnackbar(t('AppointmentRecordForm.successMessage'), {
        variant: 'success',
      });

      // Conditionally redirect to HIP (Hilfe im Paket) website
      if (shouldOrderCareBox) {
        const redirectQueryParameter = await getCareBoxInfo({
          customerId: appointment.customerId,
        });

        window.location.replace(
          `https://hilfeimpaket-box.boxkonfigurator.de?prefill=${redirectQueryParameter.data}`,
        );
      }

      // Execute the afterSubmit callback. If no callback was provided, this redirects to the HomeView.
      afterSubmit();
    } catch (error: any) {
      if (error === 'onClose' || error === 'onSecondary') return;

      if (error.response?.data) {
        const errorMessage =
          error.response.data.detail ||
          error.response?.data[0] ||
          error.response.data.nonFieldErrors?.join(' - ');

        setErrors({ submit: errorMessage });
      } else {
        setErrors({ submit: t('AppointmentRecordForm.couldNotCreate') });
      }
    }
  };

  /**
   * On location hash change handle the signature modal accordingly.
   */
  useEffect(() => {
    // Use "substring(1)" to remove the "#".
    handleModal(signatoryFor.substring(1));
  }, [signatoryFor]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form className={clsx(classes.root, className)} noValidate {...props}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  {t('AppointmentRecordForm.title')}
                </Typography>
              </Grid>

              <Grid item xs={12} data-test-id={ComponentTestIds.dateDayField}>
                <DatePicker
                  closeOnSelect
                  inputFormat="dd | MM | yyyy"
                  renderInput={(props) => (
                    <TextField
                      helperText={touched.dateDay && errors.dateDay}
                      variant="outlined"
                      name="dateDay"
                      required
                      fullWidth
                      {...props}
                      error={!!(touched.dateDay && errors.dateDay)}
                    />
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                  label={t('General.Form.dateLabel')}
                  onChange={(value) =>
                    formikRef.current?.setFieldValue(
                      'dateDay',
                      format(new Date(value || ''), 'yyyy-MM-dd'),
                    )
                  }
                  value={values.dateDay}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  data-test-id={startTimeFieldTestId}
                  error={!!(touched.startTime && errors.startTime)}
                  fullWidth
                  required
                  variant="outlined"
                >
                  <InputLabel id="startTime-label">
                    {t('General.Form.startTimeLabel')}
                  </InputLabel>
                  <StartTimeSelect
                    error={touched.startTime ? errors.startTime : undefined}
                    value={values.startTime}
                    handleBlur={handleBlur}
                    handleOnChange={(event: SelectChangeEvent<any>) =>
                      handleStartTimeChange(event, initialValues.startTime)
                    }
                    handleOnFocus={(event) =>
                      handleSelectFocus(event, values.startTime)
                    }
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  data-test-id={endTimeFieldTestId}
                  error={!!(touched.endTime && errors.endTime)}
                  fullWidth
                  required
                  variant="outlined"
                >
                  <InputLabel id="endTime-label">
                    {t('General.Form.endTimeLabel')}
                  </InputLabel>
                  <EndTimeSelect
                    error={touched.endTime ? errors.endTime : undefined}
                    startTime={
                      values.startTime ? new Date(values.startTime) : undefined
                    }
                    value={values.endTime}
                    handleBlur={handleBlur}
                    handleOnChange={(event) => {
                      handleEndTimeChange(event, initialValues.endTime);
                    }}
                    handleOnFocus={(event) =>
                      handleSelectFocus(event, values.startTime)
                    }
                    hideDuration
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  data-test-id={ComponentTestIds.travelDistanceField}
                  error={
                    !!(touched.travelDistanceKm && errors.travelDistanceKm)
                  }
                  fullWidth
                  helperText={
                    touched.travelDistanceKm && errors.travelDistanceKm
                  }
                  label={t(
                    'AppointmentRecordForm.Fields.TravelDistanceKm.label',
                  )}
                  name="travelDistanceKm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  type="number"
                  value={values.travelDistanceKm}
                  variant="outlined"
                />
              </Grid>

              {canConductFirstInterviews && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.wasFirstInterview}
                        color="success"
                        data-test-id={ComponentTestIds.wasFirstInterviewSwitch}
                        disabled={needsAssessmentFormSubmitted}
                        name="wasFirstInterview"
                        onChange={(event) => {
                          if (event.target.checked) {
                            setOpenNeedsAssessmentForm(true);
                          }

                          handleChange(event);
                        }}
                      />
                    }
                    label={t(
                      'AppointmentRecordForm.Fields.WasFirstInterview.label',
                    )}
                    labelPlacement="start"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  // data-test-id={ComponentTestIds.freeOfChargeAid.careBox}
                  name="orderCareBox"
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        values?.orderCareBox ||
                        (needsAssessmentFormSubmitted && shouldOrderCareBox)
                      }
                      disabled={
                        !customer?.canOrderCareBox || shouldDisableCareBox
                      }
                      onBlur={handleBlur}
                      onChange={(event) => {
                        handleChange(event);
                        setShouldOrderCareBox((prev) => !prev);
                      }}
                    />
                  }
                  label={
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {t('NeedsAssessmentForm.FreeOfChargeAid.careBox')}
                      {!customer?.canOrderCareBox && (
                        <IconButton
                          children={<Info />}
                          color="warning"
                          onClick={() => {
                            values && setShowInfoText(!showInfoText);
                          }}
                        />
                      )}
                    </Box>
                  }
                  labelPlacement="end"
                />
                {showInfoText && (
                  <Box mt={3}>
                    <Alert severity="info">
                      {t('NeedsAssessmentForm.FreeOfChargeAid.infoTextCareBox')}
                    </Alert>
                  </Box>
                )}
              </Grid>

              {values.wasFirstInterview && isSignable && (
                <>
                  <Grid item xs={12}>
                    <FormControl
                      data-test-id={ComponentTestIds.signatureDoaField}
                      fullWidth
                    >
                      {!values.signatureDoa && (
                        <FormLabel
                          className={classes.signatureFieldLabel}
                          onClick={() => goToSignatureModal('signatureDoa')}
                        >
                          <AddIcon />
                          {t('AppointmentRecordForm.Fields.SignatureDoa.label')}
                        </FormLabel>
                      )}

                      {values.signatureDoa && (
                        <Box className={classes.signaturePreview}>
                          <Image src={values.signatureDoa} />

                          <Typography className={classes.signatureName}>
                            {t(
                              'AppointmentRecordForm.Fields.SignatureDoa.helperText',
                            )}
                          </Typography>

                          <EditIcon
                            className={classes.editIcon}
                            onClick={() => goToSignatureModal('signatureDoa')}
                          />
                        </Box>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      data-test-id={ComponentTestIds.signatureDoppField}
                      error={!!(errors.signatureDopp && touched.signatureDopp)}
                      fullWidth
                    >
                      {!values.signatureDopp && (
                        <FormLabel
                          className={classes.signatureFieldLabel}
                          onClick={() => goToSignatureModal('signatureDopp')}
                        >
                          <AddIcon />
                          {t(
                            'AppointmentRecordForm.Fields.SignatureDopp.label',
                          )}
                        </FormLabel>
                      )}

                      {values.signatureDopp && (
                        <Box mb={2} className={classes.signaturePreview}>
                          <Image src={values.signatureDopp} />

                          <Typography className={classes.signatureName}>
                            {t(
                              'AppointmentRecordForm.Fields.SignatureDopp.helperText',
                            )}
                          </Typography>

                          <EditIcon
                            onClick={() => goToSignatureModal('signatureDopp')}
                            className={classes.editIcon}
                          />
                        </Box>
                      )}

                      {errors.signatureDopp && touched.signatureDopp && (
                        <FormHelperText>
                          <ErrorIcon /> {errors.signatureDopp}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}

              {isSignable && (
                <Grid item xs={12}>
                  <FormControl
                    data-test-id={ComponentTestIds.signatureEmployeeField}
                    error={
                      !!(
                        errors.signatureRecordEmployee &&
                        touched.signatureRecordEmployee
                      )
                    }
                    fullWidth
                  >
                    {!values.signatureRecordEmployee && (
                      <FormLabel
                        className={classes.signatureFieldLabel}
                        onClick={() =>
                          goToSignatureModal('signatureRecordEmployee')
                        }
                      >
                        <AddIcon />
                        {t(
                          'AppointmentRecordForm.Fields.SignatureRecordEmployee.label',
                        )}
                      </FormLabel>
                    )}

                    {values.signatureRecordEmployee && (
                      <Box className={classes.signaturePreview}>
                        <Image src={values.signatureRecordEmployee} />

                        <Typography className={classes.signatureName}>
                          {t(
                            'AppointmentRecordForm.Fields.SignatureRecordEmployee.helperText',
                          )}
                        </Typography>

                        <EditIcon
                          onClick={() =>
                            goToSignatureModal('signatureRecordEmployee')
                          }
                          className={classes.editIcon}
                        />
                      </Box>
                    )}

                    {errors.signatureRecordEmployee &&
                      touched.signatureRecordEmployee && (
                        <FormHelperText>
                          <ErrorIcon /> {errors.signatureRecordEmployee}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}

              {isSignable && (
                <Grid item xs={12}>
                  <FormControl
                    data-test-id={ComponentTestIds.signatureCustomerField}
                    error={
                      !!(
                        errors.signatureRecordCustomer &&
                        touched.signatureRecordCustomer
                      )
                    }
                    fullWidth
                  >
                    {!values.signatureRecordCustomer && (
                      <FormLabel
                        className={classes.signatureFieldLabel}
                        onClick={() =>
                          goToSignatureModal('signatureRecordCustomer')
                        }
                      >
                        <AddIcon />
                        {t(
                          'AppointmentRecordForm.Fields.SignatureRecordCustomer.label',
                        )}
                      </FormLabel>
                    )}

                    {values.signatureRecordCustomer && (
                      <Box mb={2} className={classes.signaturePreview}>
                        <Image src={values.signatureRecordCustomer} />

                        <Typography className={classes.signatureName}>
                          {t(
                            'AppointmentRecordForm.Fields.SignatureRecordCustomer.helperText',
                          )}
                        </Typography>

                        <EditIcon
                          onClick={() =>
                            goToSignatureModal('signatureRecordCustomer')
                          }
                          className={classes.editIcon}
                        />
                      </Box>
                    )}

                    {errors.signatureRecordCustomer &&
                      touched.signatureRecordCustomer && (
                        <FormHelperText>
                          <ErrorIcon /> {errors.signatureRecordCustomer}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              )}
            </Grid>
            {errors.submit && (
              <Box mt={3}>
                <Alert
                  data-test-id={ComponentTestIds.submitError}
                  severity="error"
                >
                  {errors.submit}
                </Alert>
              </Box>
            )}
            {!isSignable && (
              <Box mt={3}>
                <Alert
                  data-test-id={ComponentTestIds.infoText}
                  severity="error"
                >
                  <Typography>
                    {t('AppointmentRecordForm.notSignable')}
                  </Typography>
                </Alert>
              </Box>
            )}
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                data-test-id={ComponentTestIds.submitButton}
                color="secondary"
                disabled={isSubmitting || !isSignable}
                loading={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                {shouldOrderCareBox
                  ? `${t('AppointmentRecordForm.submitOrder')} ${t(
                      'AppointmentRecordForm.submit',
                    )}`
                  : t('AppointmentRecordForm.submit')}
              </Button>
            </Box>
            <Signature onClose={() => history.goBack()} {...modalOptions} />
          </Form>
        )}
      </Formik>

      {customer && (
        <NeedsAssessmentModal
          customer={customer}
          handleCancel={() => {
            formikRef.current?.setFieldValue('wasFirstInterview', false);
            setOpenNeedsAssessmentForm(false);
          }}
          handleClose={() => {
            setOpenNeedsAssessmentForm(false);
            setNeedsAssessmentFormSubmitted(true);
          }}
          open={openNeedsAssessmentForm}
          orderCareBox={() => {
            setShouldOrderCareBox(true);
            setShouldDisableCareBox(true);
          }}
        />
      )}
    </>
  );
};

export default observer(AppointmentRecordForm);

import { FC } from 'react';
import BlockIcon from '@mui/icons-material/Block';
import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';

import useStyles from './CalendarCard.styles';

interface Props extends BoxProps {
  allDay?: boolean;
  type: 'blockTime' | 'vacation' | 'vacationPending';
}

export const CalendarCard: FC<Props> = ({
  allDay,
  className,
  type,
  ...props
}) => {
  const { root, allDayStyle, blockTimeStyle, icon } = useStyles();

  return (
    <Box
      className={clsx(root, blockTimeStyle, allDay && allDayStyle, className)}
      {...props}
    >
      {!allDay && <BlockIcon className={icon} />}
    </Box>
  );
};

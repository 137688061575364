import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    alert: {
      backgroundColor: theme.palette.info.dark,
    },
    font: {
      color: theme.palette.common.white,
    },
    margin: {
      marginRight: '1rem',
    },
  }),
  { name: 'UpdateNotification' },
);

export default useStyles;

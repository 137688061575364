import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& [class*="MuiSelect-root"]': {
        '& $durationLabel': {
          display: 'none',
        },
      },
    },
    durationLabel: {
      marginLeft: 'auto',
    },
    attachmentField: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& [class*="MuiButton-label"]': {
        display: 'flex',
        flexDirection: 'column',
      },
      height: theme.spacing(30),
      marginTop: theme.spacing(2),
      border: `dashed 2px ${theme.palette.primary.main}`,
      borderRadius: 4,
      cursor: 'pointer',
    },
    attachmentFieldError: {
      borderColor: theme.palette.error.main,
    },
    attachmentFieldLabel: {
      fontSize: '18px',
      fontWeight: theme.typography.fontWeightBold,
    },
    attachmentPreview: {
      position: 'relative',
      height: theme.spacing(30),
      backgroundColor: theme.palette.primary.dark,
      borderRadius: 4,
      '& > img': {
        display: 'block',
        height: 'inherit',
        margin: 'auto',
      },
    },
    createIcon: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      color: theme.palette.primary.light,
    },
    link: { color: themeColors.shade5 },
    tabs: {
      display: 'flex',
      width: '100%',
    },
    marginRight: {
      marginRight: '16px',
    },
    tabLabel: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'start',
      marginTop: '20px',
      paddingLeft: 0,
      paddingBottom: 0,
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'none',
    },
    selectHelperText: {
      display: 'inline-flex',
    },
  }),
  { name: 'BlockTimesView' },
);

export default useStyles;

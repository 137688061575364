import { FC } from 'react';
import { Error } from '@mui/icons-material';
import { FormHelperText } from '@mui/material';

import { TestIds } from 'src/testIds';
import themeColors from 'src/theme/colors';

interface Props {
  type: 'biWeekly' | 'weekly';
  errors?: { biWeekly?: string; weekly?: string };
}

export const HourSelectError: FC<Props> = ({ type, errors }) => (
  <FormHelperText
    data-test-id={`${TestIds.components.hourSelectError}-${type}`}
    style={{ color: themeColors.error }}
  >
    {errors &&
      ((type === 'biWeekly' && errors.biWeekly) ||
        (type === 'weekly' && errors.weekly)) && <Error />}
    {type === 'biWeekly' ? errors?.biWeekly : errors?.weekly}
  </FormHelperText>
);

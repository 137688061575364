import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import {
  getAllDayBlockTimeEntryAtDate,
} from 'src/utils';

import WeeklyBlockTimeNote from './WeeklyBlockTimeNote';

import useStyles from './WeeklyBanner.styles';

interface Props {
  children: React.ReactNode;
  date: Date;
}

const getType = (date: Date) => {
  const isAllDayBlockTime = !!getAllDayBlockTimeEntryAtDate(date);

  switch (true) {
    case isAllDayBlockTime:
      return 'blockTime';
    default:
      return 'default';
  }
};

const WeeklyAgendaDayWrapper: FC<Props> = ({ children, date }) => {
  const classes = useStyles();
  const type = getType(date);

  return (
    <Box
      className={clsx(
        classes.noteWrapper,
        type === 'blockTime' && classes.noteWrapperBlockDay,
      )}
    >
      {type === 'blockTime' && <WeeklyBlockTimeNote />}

      {children}
    </Box>
  );
};

export default WeeklyAgendaDayWrapper;

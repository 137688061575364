import { FormikErrors } from 'formik';

import { NeedsAssessmentFormData } from 'src/components/NeedsAssessment/needsAssessment';

const scrollToError = (errors: FormikErrors<NeedsAssessmentFormData>) => {
  const errorKeys = Object.keys(errors);

  if (errorKeys.length > 0) {
    if (errorKeys[0] === 'requiredServices') {
      const element = document.getElementById('requiredServices');
      element?.scrollIntoView();
    } else {
      const element = document.querySelector('.Mui-error, [data-error]');
      (element?.parentElement ?? element)?.scrollIntoView();
    }
  }
};

export default scrollToError;

import type { FC, ReactNode } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  LocationOnOutlined as LocationIcon,
  PhoneOutlined as PhoneIcon,
} from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import {
  format as formatTime,
  intervalToDuration,
  parse as parseTime,
} from 'date-fns';

import type { Appointment, Customer } from 'src/types';
import { getOS } from 'src/utils';
import type { Theme } from 'src/theme/types';

import useStyles from './AppointmentInformation.styles';

export const AppointmentInformation: FC<{
  appointment: Appointment;
  className?: string;
  customer: Customer;
  icon?: ReactNode;
}> = ({ appointment, className, customer, icon, ...props }) => {
  const now = new Date();
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { t } = useTranslation();
  const OS = getOS();
  const startTime = parseTime(appointment.startTime, 'HH:mm:ss', now);
  const geoQuery = `q=${customer.street} ${customer.streetNo}, ${customer.zipCode} ${customer.city}`;
  const geoURL = OS === 'Android' ? `geo:0,0` : `https://maps.apple.com/`;
  const startTimeFormatted = formatTime(startTime, 'HH:mm');
  const endTime = parseTime(appointment.endTime, 'HH:mm:ss', now);
  const duration = intervalToDuration({
    end: endTime,
    start: startTime,
  });

  /**
   * Handle map redirect depending on OS.
   */
  const handleAddressClick = () =>
    (window.location.href = `${geoURL}?${geoQuery}`);

  /**
   * Handle phone redirect.
   */
  const handlePhoneNoClick = () => {
    window.location.href = `tel:${customer.phonePrivate}`;
  };

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Box className={classes.iconWrapper} />

      <Box className={classes.textWrapper}>
        <Typography
          align="center"
          className={classes.textIcon}
          color="textSecondary"
          onClick={handleAddressClick}
          variant="h6"
        >
          <LocationIcon />

          {`${customer.street} ${customer.streetNo} | ${customer.zipCode} ${customer.city}`}
        </Typography>

        {customer.phonePrivate && (
          <Typography
            align="center"
            className={classes.textIcon}
            color="textSecondary"
            onClick={handlePhoneNoClick}
            variant="h6"
          >
            <PhoneIcon />
            {t('AppointmentInformation.tel', {
              telNo: customer.phonePrivate,
            })}
          </Typography>
        )}

        <Typography
          align="center"
          className={classes.durationWrapper}
          variant="h6"
        >
          <span style={{ marginRight: theme.spacing(2) }}>
            <Trans
              i18nKey={'AppointmentInformation.start'}
              values={{ startTimeFormatted }}
            />
          </span>

          <Trans
            i18nKey={
              duration.minutes
                ? 'AppointmentInformation.duration'
                : 'AppointmentInformation.durationFullHour'
            }
            values={{ ...duration }}
          />
        </Typography>
      </Box>

      <Box className={classes.iconWrapper}>{icon}</Box>
    </Box>
  );
};

export default AppointmentInformation;

import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
    },

    dialogTitle: {
      boxShadow: theme.shadows[1],
      padding: theme.spacing(2),
    },

    signaturePadWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
    },

    // Wrapper, which prevents signature to have faulty behavior, leading to signature offsets
    // see https://stackoverflow.com/questions/68018558/problem-with-react-signature-canvas-offset-ink
    signatureCanvasWrapper: {
      [theme.breakpoints.down('lg')]: {
        height: 900,
        width: 900,
      },
      [theme.breakpoints.up('sm')]: {
        height: 1200,
        width: 1200,
      },
      [theme.breakpoints.up('md')]: {
        height: 1920,
        width: 1920,
      },
    },

    signaturePad: {
      width: '100%',
      height: '100%',
    },

    signBorder: {
      width: `calc(100% - ${theme.spacing(4)})`,
      border: 'none',
      borderBottom: `1px solid ${theme.palette.common.black}`,
      position: 'absolute',
      bottom: theme.spacing(2),
    },

    signatureArrow: {
      backgroundColor: theme.palette.ternary.main,
      padding: '10px 20px',
      position: 'absolute',
      bottom: theme.spacing(3),
      left: theme.spacing(4),
      transform: 'rotate(-90deg)',
      transformOrigin: 'left',

      '&:after': {
        content: '""',
        position: 'absolute',
        width: 0,
        height: 0,
        borderTop: '21px solid transparent',
        borderRight: `20px solid ${theme.palette.ternary.main}`,
        borderBottom: '20px solid transparent',
        bottom: 0,
        left: -20,
      },
    },

    buttonWrapper: {
      display: 'flex',
      flexGrow: 0,
    },
  }),

  { name: 'Signature' },
);

export default useStyles;

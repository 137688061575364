import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),

  { name: 'AppointmentListView' },
);

export default useStyles;

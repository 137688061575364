import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { useConfirm } from 'src/contexts/ConfirmContext';
import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import { routes } from 'src/services/routing';
import {
  actions as appointmentsActions,
  getters as appointmentsGetters,
} from 'src/services/state/Appointments';
import { getters as customersGetters } from 'src/services/state/Customers';
import AppointmentForm from 'src/components/AppointmentForm/AppointmentForm';
import AppointmentInformation from 'src/components/AppointmentInformation/AppointmentInformation';
import Page from 'src/components/Page/Page';

import useStyles from './AppointmentEditView.styles';

const ViewTestIds = TestIds.views.appointmentEdit;

const AppointmentEditView: FC = () => {
  const { t } = useTranslation();
  const { singleOrEvery, id, index } = useParams<{
    singleOrEvery: 'single' | 'every';
    id: string;
    index?: string;
  }>();
  const appointmentId = parseInt(id);
  const recurrenceIndex = index === undefined ? null : parseInt(index);
  const editRecurrence = singleOrEvery === 'every';
  const classes = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const topBar = useTopBar();
  const appointment = appointmentsGetters.getAppointment(
    appointmentId,
    recurrenceIndex,
  );
  const customer = appointment
    ? customersGetters.getCustomerById(appointment.customerId)
    : undefined;

  /**
   * Deletes the current appointment
   */
  const deleteAppointment = async () => {
    if (!appointment) return;

    try {
      await confirm({
        description: editRecurrence
          ? t('Views.AppointmentEdit.recurrenceSubmitDescription', {
              date: format(new Date(appointment.dateDay), 'dd.MM.yyyy'),
            })
          : t('Views.AppointmentEdit.submitDescription'),
        primaryText: t('General.delete'),
        title: editRecurrence
          ? t('Views.AppointmentEdit.recurrenceSubmitTitle')
          : t('Views.AppointmentEdit.submitTitle'),
      });

      // Wait for the request.
      await appointmentsActions.deleteAppointment({
        appointmentId,
        recurrenceIndex: editRecurrence ? null : recurrenceIndex,
        startRecurrenceIndex: recurrenceIndex,
      });

      // Show a success snackbar message.
      enqueueSnackbar(
        editRecurrence
          ? t('Views.AppointmentEdit.deleteSuccessMessageEvery')
          : t('Views.AppointmentEdit.deleteSuccessMessageSingle'),
        {
          variant: 'success',
        },
      );

      // Redirect to HomeView on success. Do not use 'topBar.handleBack' to avoid
      // being redirected to an AppointmentRecordView of the just deleted appointment.
      history.push(routes.app.routes.home.path);
    } catch (error: any) {
      if (error === 'onClose' || error === 'onSecondary') return;

      if (error.response?.data) {
        const errorMessage = error.response.data.detail || error.response.data;

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('General.somethingWentWrong'), {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (customer && appointment) {
      topBar.setTitle(`${customer.firstName} ${customer.lastName}`);
      topBar.setActionIcon(<DeleteOutlineIcon color="error" />);
      topBar.setHandleAction(() => deleteAppointment);
      topBar.setContent(
        <AppointmentInformation
          customer={customer}
          appointment={appointment}
        />,
      );
    }

    // Set TopBar properties back to default, when unmounting this view.
    return () => {
      topBar.resetTopBar();
    };
  }, [appointment, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page
      className={classes.root}
      data-test-id={ViewTestIds.pageWrapper}
      title={t('Views.AppointmentCreate.title')}
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <AppointmentForm
            afterSubmit={topBar.handleBack}
            appointment={appointment}
            editRecurrence={editRecurrence}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default AppointmentEditView;

import React, { FC, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Error, Schedule as ScheduleIcon } from '@mui/icons-material';
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { TestIds } from 'src/testIds';
import { getTimeSteps } from 'src/utils';

interface Props {
  disabled?: boolean;
  error?: string;
  required?: boolean;
  value: string;
  handleBlur: (event: React.FocusEvent<any>) => void;
  handleOnChange: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => void;
  handleOnFocus?: (event: FocusEvent<HTMLDivElement>, value?: string) => void;
}

const ComponentTestIds = TestIds.components;

export const StartTimeSelect: FC<Props> = ({
  disabled,
  error,
  required,
  value,
  handleBlur,
  handleOnChange,
  handleOnFocus,
}) => {
  const { t } = useTranslation();
  const startTimeSteps = [...getTimeSteps('start')];

  return (
    <>
      <InputLabel id="startTime-label" required={required}>
        {t('General.Form.startTimeLabel')}
      </InputLabel>

      <Select
        disabled={disabled}
        IconComponent={ScheduleIcon}
        label={t('General.Form.startTimeLabel')}
        labelId="startTime-label"
        onBlur={handleBlur}
        onChange={handleOnChange}
        MenuProps={{
          onFocus: handleOnFocus,
        }}
        name="startTime"
        placeholder=" " // Compare with TextField component.
        value={value}
      >
        {startTimeSteps.map((timeStep) => (
          <MenuItem
            data-test-id={`${ComponentTestIds.startTimeField}-${timeStep.timeString}`}
            key={timeStep.timeString}
            value={timeStep.dateString}
          >
            {timeStep.timeString}
          </MenuItem>
        ))}
      </Select>

      {error && (
        <FormHelperText>
          <Error />
          {error}
        </FormHelperText>
      )}
    </>
  );
};

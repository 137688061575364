import type { FC, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useUserStore } from 'src/services/auth/auth';
import { routes } from 'src/services/routing';
import UserState from 'src/services/state/User';

interface AuthGuardProps {
  children?: ReactNode;
}

/**
 * Shows nested views only to logged in users and redirects logged out users accordingly.
 */
const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { updateUserInfo } = useUserStore();
  const location = useLocation();

  // Update user info on every route change.
  useEffect(() => {
    updateUserInfo();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!UserState.user) {
    return (
      <Redirect
        to={{ pathname: routes.login.path, state: { from: location } }}
      />
    );
  }

  return <>{children}</>;
};

export default observer(AuthGuard);

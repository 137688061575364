import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export interface Dimensions {
  borderBoxHeight: number | undefined;
  borderBoxWidth: number | undefined;
  height: number | undefined;
  width: number | undefined;
}

const initialDimensions = {
  borderBoxHeight: undefined,
  borderBoxWidth: undefined,
  height: undefined,
  width: undefined,
};

/**
 * Creates a hook that uses the useState  and useRef hook internally for storing the previous dimensions.
 * Changes to the ref object will update the dimensions by triggering the ResizeObserver.
 * Inspired by: https://medium.datadriveninvestor.com/usedimension-react-hook-dcd0ecaf1160
 */
const useRefDimensions = <T extends HTMLElement = HTMLDivElement>(
  ref: MutableRefObject<T | undefined | null>,
) => {
  const [dimensions, setDimensions] = useState<Dimensions>(initialDimensions);

  // The ref object will only be set once. Thus the ResizeObserver`s entries will never
  // contain more than the current ref element.
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const { height, width } = entry.contentRect;

      setDimensions({
        borderBoxHeight: entry.target.scrollHeight,
        borderBoxWidth: entry.target.scrollWidth,
        height,
        width,
      });
    });
  });

  useEffect(() => {
    // Set the current ref element to be observed and apply the above callback after each dimension change.
    if (ref.current) resizeObserver.observe(ref.current);

    // Clear the ResizeObserver when the ref element unmounts.
    return () => resizeObserver.disconnect();
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  return dimensions;
};

export default useRefDimensions;

import { colors } from '@mui/material';

import typography from './typography';

export const muiOverrides = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
        },

        contained: {
          textTransform: 'none',
        },

        sizeLarge: {
          padding: '6px 18px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: 'auto',
        },
      },
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          marginTop: 'auto',
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 16,
          flexDirection: 'column',
          alignItems: 'flex-end',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        light: {
          backgroundColor: `${colors.common.white}3`,
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        fullWidth: {
          // Fixes labels being full width as well which led to invisible clickable areas.
          '& .MuiFormControlLabel-root': {
            paddingRight: 16,
            marginRight: 'auto',
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        labelPlacementStart: {
          marginLeft: 0,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 4,
          display: 'inline-flex',

          '& svg:first-of-type': {
            fontSize: '1.5em',
            marginRight: 8,
          },
        },

        contained: {
          marginLeft: 0,
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& svg': {
            width: '0.7em',
            height: '0.7em',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 16, // Font-size below 16 causes zooms into the input field on Safari iOS.

          '&:placeholder': {
            opacity: 1,
            color: colors.blueGrey[500],
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        // Fixes an issue where select labels can overflow the select input.
        formControl: {
          maxWidth: 'calc(100% - 35px)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: {
          fontSize: '1.1em',
          top: 'calc(50% - 8px)',
        },

        iconOutlined: {
          right: 16,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .MuiTypography-body1': {
            fontSize: 'inherit', // Fixes different default font-sizes when using the Typography component inside a table cell.
          },
        },

        head: {
          fontWeight: typography.fontWeightBold,
        },
      },
    },
  },
};

export default muiOverrides;

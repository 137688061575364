import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Grid, IconButton, InputAdornment } from '@mui/material';
import clsx from 'clsx';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useQuery } from 'src/hooks';
import { TestIds } from 'src/testIds';
import { useUserStore } from 'src/services/auth/auth';
import { routes } from 'src/services/routing';
import Alert from 'src/components/Alert/Alert';
import Button from 'src/components/Button/Button';
import Form from 'src/components/Form/Form';
import TextField from 'src/components/TextField/TextField';

import useStyles from './ResetPasswordForm.styles';

type FormSubmitStatus = 'initial' | 'invalidLink' | 'success' | 'error';

interface ResetPasswordFormProps {
  className?: string;
  afterSubmit?: (formSubmitStatus: FormSubmitStatus) => void;
}

interface Values {
  password: string;
  repeatPassword: string;
  submit: string;
}

const ComponentTestIds = TestIds.components.resetPasswordForm;

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  className,
  afterSubmit = () => {},
  ...props
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const { resetPassword } = useUserStore();
  const query = useQuery();
  const ident = query.get('ident');
  const token = query.get('token');
  const { t } = useTranslation();

  // redirect to NotFoundView if authentication query string parameters are not present
  if (!query.has('ident') || !query.has('token')) {
    return <Redirect to={{ pathname: routes.notFound.path }} />;
  }

  const initialValues: Values = {
    password: '',
    repeatPassword: '',
    submit: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .max(255)
      .required(t('ResetPasswordForm.Fields.Password.required'))
      .matches(/^.{6,}$/, t('General.Form.minLength', { minLength: 6 }))
      .matches(
        /[A-Z]/,
        t('ResetPasswordForm.Fields.Password.atLeastOneCapitalLetter'),
      )
      .matches(
        /[a-z]/,
        t('ResetPasswordForm.Fields.Password.atLeastOneLowercase'),
      )
      .matches(/[0-9]/, t('ResetPasswordForm.Fields.Password.atLeastOneDigit')),
    repeatPassword: Yup.string()
      .max(255)
      .oneOf(
        [Yup.ref('password')],
        t('ResetPasswordForm.Fields.RepeatPassword.repeatPasswordMustMatch'),
      )
      .required(
        t('ResetPasswordForm.Fields.RepeatPassword.repeatPasswordMustMatch'),
      ),
  });

  const handleSubmit = async (
    values: Values,
    { setErrors }: FormikHelpers<Values>,
  ) => {
    try {
      await resetPassword(ident!, token!, values.password);

      afterSubmit('success');
    } catch (error: any) {
      if (error.response.data.password) {
        setErrors({ password: t('Views.ResetPassword.passwordTooWeak') });
      } else if (error.response.data.error) {
        afterSubmit('invalidLink');
      } else {
        setErrors({ submit: t('General.somethingWentWrong') });
        afterSubmit('error');
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form
          className={clsx(classes.root, className)}
          invertColor
          noValidate
          {...props}
        >
          <TextField
            data-test-id={ComponentTestIds.passwordField}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t('ResetPasswordForm.Fields.Password.label')}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setShowPassword(!showPassword)}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            data-test-id={ComponentTestIds.repeatPasswordField}
            error={Boolean(touched.repeatPassword && errors.repeatPassword)}
            fullWidth
            helperText={touched.repeatPassword && errors.repeatPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    edge="end"
                    size="large"
                  >
                    {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={t('ResetPasswordForm.Fields.RepeatPassword.label')}
            margin="normal"
            name="repeatPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showRepeatPassword ? 'text' : 'password'}
            value={values.repeatPassword}
            variant="outlined"
          />

          {errors.submit && (
            <Box mt={3}>
              <Alert
                data-test-id={ComponentTestIds.submitError}
                severity="error"
                variant="transparent"
              >
                {errors.submit}
              </Alert>
            </Box>
          )}

          <Grid item container justifyContent="center" xs={12}>
            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                data-test-id={ComponentTestIds.submitButton}
                size="large"
                type="submit"
                variant="contained"
              >
                {t('ResetPasswordForm.resetPassword')}
              </Button>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;

import type { ComponentProps, FC } from 'react';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import useStyles from './Image.styles';

interface ImageProps extends ComponentProps<'img'> {}

export const Image: FC<ImageProps> = ({ alt, className, src, ...props }) => {
  const classes = useStyles();
  const [imageSource, setImageSource] = useState(src);

  const handleImageLoadingError = () => {
    setImageSource('/static/images/placeholder.png');
  };

  // Allow image source to update.
  useEffect(() => {
    setImageSource(src);
  }, [src]);

  return (
    <img
      alt={alt} // Fixes warning for missing alt property on the img-tag. It is included within the ImageProps-Spread though.
      className={clsx(classes.root, className)}
      src={imageSource}
      onError={handleImageLoadingError}
      {...props}
    />
  );
};

export default Image;

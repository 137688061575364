import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      minHeight: '100%',
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
    },
  }),

  { name: 'LoadingScreen' },
);

export default useStyles;

import localforage from 'localforage';
import { autorun, observable, toJS } from 'mobx';

import { MonthlySummary } from 'src/types';

export interface MonthlySummariesStateProps {
  initialized: boolean;
  monthlySummaries: MonthlySummary[];
}

export const initialMonthlySummariesState: MonthlySummariesStateProps = {
  initialized: false,
  monthlySummaries: [],
};

const MonthlySummariesState = observable<MonthlySummariesStateProps>(
  initialMonthlySummariesState,
);

autorun(() => {
  // ! Note: 'initialized' is set to 'false' after hot module reload in development environment.
  if (!MonthlySummariesState.initialized) {
    return;
  }

  // Converts the observable-object to a javascript-object as mobx-observables can not be stored.
  const monthlySummariesState = toJS(MonthlySummariesState);

  localforage
    .setItem('monthlySummariesState', monthlySummariesState)
    .catch((error: any) => console.warn('could not be stored locally ', error));
});

export default MonthlySummariesState;

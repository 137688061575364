import type { FC, ReactNode } from 'react';
import React from 'react';
import { Helmet } from 'react-helmet';
import type { GridProps } from '@mui/material';
import { Grid } from '@mui/material';
import clsx from 'clsx';

import {
  DESCRIPTION,
  HEADER_LOGO,
  MANIFEST_DATA,
  PAGE_TITLE,
} from 'src/constants';
import i18n from 'src/services/i18n/i18n';

import useStyles from './Page.styles';

interface PageProps extends GridProps {
  children?: ReactNode;
  className?: string;
  title?: string;
}

const Page: FC<PageProps> = ({ children, className, title, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.root, className)} {...props}>
      <Helmet>
        <html lang={i18n.language} className="notranslate" translate="no" />
        <link rel="manifest" href={MANIFEST_DATA} />
        <link rel="apple-touch-icon" href={HEADER_LOGO} />
        <link rel="apple-touch-icon" sizes="180x180" href={HEADER_LOGO} />
        <link rel="icon" type="image/png" sizes="16x16" href={HEADER_LOGO} />
        <link rel="icon" type="image/png" sizes="32x32" href={HEADER_LOGO} />
        <link rel="mask-icon" href={HEADER_LOGO} color="#5bbad5" />
        <link rel="shortcut icon" href={HEADER_LOGO} />
        <title>
          {title} | {PAGE_TITLE}
        </title>
        <meta name="description" content={DESCRIPTION} />
      </Helmet>

      {children}
    </Grid>
  );
};

export default Page;

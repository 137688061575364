import { isEqual } from 'date-fns';

import AppointmentsState from 'src/services/state/Appointments';

const hasAppointmentsAtDate = (date: Date) => {
  const appointments = AppointmentsState.appointments;

  return appointments.some((appointment) =>
    isEqual(new Date(appointment.dateDay).setHours(0, 0, 0, 0), date),
  );
};

export default hasAppointmentsAtDate;

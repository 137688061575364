import type { FC } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import type { DialogProps } from '@mui/material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

import type { ConfirmOptions } from 'src/contexts/ConfirmContext';
import { TestIds } from 'src/testIds';

import useStyles from './ConfirmDialog.styles';

const ComponentTestIds = TestIds.components.confirmDialog;
export interface ConfirmDialogProps extends DialogProps {
  onClose: any;
  onPrimary: any;
  onSecondary: any;
  options: ConfirmOptions;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  onClose,
  onPrimary,
  onSecondary,
  options,
  ...props
}) => {
  const {
    content,
    description,
    dialogProps,
    primaryButtonProps,
    primaryText,
    secondaryButtonProps,
    secondaryText,
    title,
    variant,
    hideCloseButton,
    hideSecondaryButton,
  } = options;
  const classes = useStyles({ variant });

  return (
    <Dialog
      data-test-id={ComponentTestIds.dialogWrapper}
      fullWidth
      onClose={onClose}
      PaperProps={{ classes: { root: classes.paperRoot } }}
      {...props}
      {...dialogProps}
    >
      <DialogTitle variant="h5">
        {title}

        {!hideCloseButton && (
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </DialogTitle>

      {!!content && !description && <DialogContent>{content}</DialogContent>}

      {!content && !!description && (
        <DialogContent>
          <DialogContentText color="inherit">{description}</DialogContentText>
        </DialogContent>
      )}

      {(!!secondaryText || !!primaryText) && (
        <DialogActions>
          {!hideSecondaryButton && !!secondaryText && (
            <Button
              className={classes.secondaryButton}
              color="secondary"
              data-test-id={ComponentTestIds.denyButton}
              {...secondaryButtonProps}
              onClick={onSecondary}
            >
              {secondaryText}
            </Button>
          )}

          {!!primaryText && (
            <Button
              autoFocus
              className={classes.primaryButton}
              color="primary"
              data-test-id={ComponentTestIds.confirmButton}
              {...primaryButtonProps}
              onClick={onPrimary}
            >
              {primaryText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: '100%',
    },
  }),

  { name: 'Image' },
);

export default useStyles;

import { makeStyles } from '@mui/styles';

import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',

      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },

    iconWrapper: {
      minWidth: 33, // Avoid shifting the title to the left or right regardless of if this icon is rendered or not.
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },

    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',

      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(0.25),
      },
    },

    textIcon: {
      alignItems: 'center',
      display: 'inline-flex',

      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },

      '& svg': {
        fontSize: 18,
        marginRight: theme.spacing(0.5),
      },
    },

    durationWrapper: {
      color: theme.palette.ternary.dark,
    },
  }),

  { name: 'AppointmentInformation' },
);

export default useStyles;

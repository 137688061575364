import localforage from 'localforage';
import { autorun, observable, toJS } from 'mobx';

import { Customer } from 'src/types';

export interface CustomersStateProps {
  customers: Customer[];
  initialized: boolean;
}

export const initialCustomersState: CustomersStateProps = {
  customers: [],
  initialized: false,
};

const CustomersState = observable<CustomersStateProps>(initialCustomersState);

autorun(() => {
  // ! Note: 'initialized' is set to 'false' after hot module reload in development environment.
  if (!CustomersState.initialized) {
    return;
  }

  // Converts the observable-object to a javascript-object as mobx-observables can not be stored.
  const customersState = toJS(CustomersState);

  localforage
    .setItem('customersState', customersState)
    .catch((error: any) => console.warn('could not be stored locally ', error));
});

export default CustomersState;

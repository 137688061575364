import type { FC, ReactNode, Ref } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { SlideProps } from '@mui/material';
import { Box, Container, Dialog, Slide } from '@mui/material';
import clsx from 'clsx';

import { TopBarProvider } from 'src/contexts/TopBarContext';
import { useRefDimensions } from 'src/hooks';
import { TestIds } from 'src/testIds';
import { getRouteByPath, routes } from 'src/services/routing';
import TopBar from 'src/components/TopBar/TopBar';

import useStyles from './StackLayout.styles';

const LayoutTestIds = TestIds.layouts.stackLayout;

export interface StackLayoutProps {
  children?: ReactNode;
  className?: string;
}

const SlideTransition = forwardRef(
  ({ children, ...props }: SlideProps, ref: Ref<unknown>) => (
    <Slide direction="left" ref={ref} {...props}>
      {children}
    </Slide>
  ),
);

export const StackLayout: FC<StackLayoutProps> = ({
  children,
  className,
  ...props
}) => {
  const [open, setOpen] = useState(true);
  const [stackCount, setStackCount] = useState<number>(0);
  const [topBarHeight, setTopBarHeight] = useState<number>();
  const topBarRef = useRef<HTMLDivElement>(null);
  const topBarDimensions = useRefDimensions(topBarRef);
  const topBarBoxHeight = topBarDimensions.borderBoxHeight;
  const classes = useStyles({ topBarHeight: topBarBoxHeight || topBarHeight });
  const history = useHistory();
  const location = useLocation();
  const currentRoute = getRouteByPath(location.pathname);
  const isStackRoute = currentRoute?.routeObject.isStack;

  // Navigate back but on the last stack just close the Dialog and use the onExited callback to navigate back.
  const handleClose = () => {
    if (stackCount > 1) {
      history.goBack();
    } else {
      setOpen(false);
    }
  };

  // Use the onExited callback to wait for the closing animation to complete before actually going back.
  const handleExited = () => {
    if (open) return;

    // Safety net when refreshing the page in the middle of a stack and thus creating a falsy stackCount.
    if (isStackRoute && stackCount < 1) {
      history.push(routes.app.routes.home.path);
    } else {
      history.goBack();
    }
  };

  // Can't use "useRefDimensions" hook here as the TopBar and its ref is only rendered after
  // the "onEntered" event was triggered and thus is "undefined" when passed to the hook initially.
  const handleEntered = () => {
    setTopBarHeight(topBarRef.current?.scrollHeight);
  };

  // Update stack count within the StackLayout as soon as the location changes.
  useEffect(() => {
    if (history.action === 'PUSH') {
      setStackCount(stackCount + 1);
    }

    if (history.action === 'POP') {
      setStackCount(stackCount - 1);
    }
  }, [history.location.key]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      className={clsx(classes.root, className)}
      data-test-id={LayoutTestIds.wrapper}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      TransitionProps={{
        onEntered: handleEntered,
        onExited: handleExited,
        unmountOnExit: true,
      }}
      {...props}
    >
      <TopBarProvider value={{ handleBack: handleClose }}>
        <TopBar ref={topBarRef} />

        <Box className={classes.contentWrapper}>
          <Container className={classes.content} maxWidth="md">
            <>{children}</>
          </Container>
        </Box>
      </TopBarProvider>
    </Dialog>
  );
};

export default StackLayout;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BlockIcon from '@mui/icons-material/Block';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import themeColors from 'src/theme/colors';

import useStyles from './WeeklyBanner.styles';

const WeeklyBlockTimeNote: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'BlockTimeBannerNote',
  });

  return (
    <Box className={clsx(classes.note, classes.blockTimeNote)}>
      <Typography color={themeColors.shade4} variant="h6">
        {t('text')}
      </Typography>
      <BlockIcon className={classes.blockTimeIcon} fontSize="inherit" />
    </Box>
  );
};

export default WeeklyBlockTimeNote;

import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      height: '100%',
    },

    swiper: {
      // Add width, padding and negativ margin to allow for drop shadow of the nested accordion components.
      width: `calc(100% + ${theme.spacing(2)})`,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginRight: -theme.spacing(1),
      marginLeft: -theme.spacing(1),

      '& .swiper-slide': {
        height: 'auto',
      },
    },
  }),

  { name: 'MonthlySummaryView' },
);

export default useStyles;

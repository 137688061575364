/* eslint-disable */ // Disable import sorting to ensure polyfills are applied correctly.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@fontsource/work-sans/400.css';
import '@fontsource/work-sans/500.css';
import '@fontsource/work-sans/700.css';
import 'src/services/i18n/i18n';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from 'src/components/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { SENTRY_URL, ENVIRONMENT } from 'src/constants';
import { ServiceWorkerConfig } from './serviceWorkerRegistration';
/* eslint-enable */

const config: ServiceWorkerConfig = {
  onUpdate: (registration) => {
    const event = new CustomEvent('onNewServiceWorker', {
      detail: { registration },
    });

    document.dispatchEvent(event);
  },
};

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary>
    <App />
  </Sentry.ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { FC } from 'react';
import { Error, Schedule } from '@mui/icons-material';
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { isValid } from 'date-fns';
import { t } from 'i18next';

import { TestIds } from 'src/testIds';
import { TimeStep } from 'src/types';
import { formatEndTimeSteps } from 'src/utils';

import useStyles from './EndTimeSelect.styles';

const ComponentTestIds = TestIds.components;

interface Props {
  disabled?: boolean;
  error?: string;
  hideDuration?: boolean;
  required?: boolean;
  startTime?: Date;
  value: string;
  handleBlur: (event: React.FocusEvent<any>) => void;
  handleOnChange: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => void;
  handleOnFocus?: React.FocusEventHandler<HTMLDivElement> | undefined;
}

export const EndTimeSelect: FC<Props> = ({
  disabled,
  error,
  startTime,
  hideDuration,
  required,
  value,
  handleBlur,
  handleOnChange,
  handleOnFocus,
}) => {
  const endTimeSteps = isValid(startTime) ? formatEndTimeSteps(startTime) : [];
  const classes = useStyles();

  //  Creates an additional TimeStep (23:59:59) (Useful for generating all day entries)
  const todayEndTime = new Date();
  todayEndTime.setHours(23, 59, 59, 0);

  const lastTimeStep: TimeStep = {
    dateObject: todayEndTime,
    dateString: todayEndTime.toString(),
    durationString: '23:59:59',
    hide: true,
    timeString: '23:59:59',
  };

  return (
    <>
      <InputLabel id="endTime-label" required={required}>
        {t('General.Form.endTimeLabel')}
      </InputLabel>

      <Select
        disabled={disabled}
        IconComponent={Schedule}
        label={t('General.Form.endTimeLabel')}
        labelId="endTime-label"
        MenuProps={{
          onFocus: handleOnFocus,
        }}
        name="endTime"
        onBlur={handleBlur}
        onChange={handleOnChange}
        placeholder=" " // Compare with TextField component.
        value={value}
      >
        {[...endTimeSteps, lastTimeStep].map((timeStep) => (
          <MenuItem
            data-test-id={`${ComponentTestIds.endTimeField}-${timeStep.timeString}`}
            key={timeStep.timeString}
            style={{ display: timeStep.hide ? 'none' : undefined }}
            value={timeStep.dateString}
          >
            {timeStep.timeString}
            {!hideDuration && timeStep.durationString && (
              <Typography className={classes.durationLabel} variant="subtitle2">
                ({timeStep.durationString})
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>

      {error && (
        <FormHelperText>
          <Error />
          {error}
        </FormHelperText>
      )}
    </>
  );
};

/**
 * @description Takes an Url (string) and opens the url in a new tab
 *
 * @example openInNewTab('www.example.com')
 */
const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

  if (newWindow) newWindow.opener = null;
};

export default openInNewTab;

import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  ArrowBackIos as ArrowBackIosIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';
import { Box, Container, Grid, Link, Typography } from '@mui/material';

import { TestIds } from 'src/testIds';
import { routes } from 'src/services/routing';
import ForgotPasswordForm from 'src/components/ForgotPasswordForm/ForgotPasswordForm';
import Page from 'src/components/Page/Page';
import { FeeLogoWhite, LogoWhite } from 'src/components/Svg';

import { ENVIRONMENT } from '../../constants';

import useStyles from './ForgotPasswordView.styles';

const ViewTestIds = TestIds.views.forgotPassword;

const ForgotPasswordView: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [formSubmitStatus, setFormSubmitStatus] = useState('initial');

  const setFormStatus = (submitStatus: 'initial' | 'success' | 'error') => {
    setFormSubmitStatus(submitStatus);
  };

  return (
    <Page className={classes.root} title={t('Views.ForgotPassword.title')}>
      <Link
        className={classes.backLink}
        color="inherit"
        component={RouterLink}
        to={routes.login.path}
      >
        <ArrowBackIosIcon fontSize="small" />
      </Link>

      <Container maxWidth="sm">
        <Grid container>
          <Grid item container justifyContent="center" xs={12}>
            {ENVIRONMENT.startsWith('AFH') ? (
              <LogoWhite width="70" />
            ) : (
              <FeeLogoWhite width="140" />
            )}
          </Grid>

          {formSubmitStatus === 'initial' && (
            <Grid item xs={12}>
              <Typography align="center" gutterBottom variant="h2">
                {t('Views.ForgotPassword.title')}
              </Typography>

              <Typography align="center" variant="subtitle2">
                {t('Views.ForgotPassword.description')}
              </Typography>
            </Grid>
          )}

          {formSubmitStatus === 'success' && (
            <Grid item xs={12}>
              <Typography
                align="center"
                data-test-id={ViewTestIds.successMessage}
                gutterBottom
                variant="h2"
              >
                <CheckCircleOutlineIcon /> <br />
                {t('Views.ForgotPassword.successTitle')}
              </Typography>

              <Typography align="center" variant="subtitle2">
                {t('Views.ForgotPassword.successDescription')}
              </Typography>
            </Grid>
          )}
        </Grid>

        {['initial', 'error'].includes(formSubmitStatus) && (
          <Box mt={3}>
            <ForgotPasswordForm afterSubmit={setFormStatus} />
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default ForgotPasswordView;

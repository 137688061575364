import themeColors, { themeGradients } from './colors';
import shadows from './shadows';
import { ThemeConfig } from './types';

const themes: ThemeConfig[] = [
  {
    name: 'light',

    palette: {
      mode: 'light',

      action: {
        active: themeColors.primary4,
      },

      background: {
        dark: themeColors.shade2,
        default: themeColors.white,
        paper: themeColors.white,
      },

      error: {
        dark: themeColors.errorFont,
        light: themeColors.errorBackground,
        main: themeColors.error,
      },

      gradients: {
        primary: themeGradients.primary,
        secondary: themeGradients.secondary,
      },

      primary: {
        dark: themeColors.primary5,
        light: themeColors.primary3,
        main: themeColors.primary4,
      },

      secondary: {
        light: themeColors.secondary3,
        main: themeColors.secondary4,
      },

      success: {
        dark: themeColors.successFont,
        light: themeColors.successBackground,
        main: themeColors.success,
      },

      ternary: {
        dark: themeColors.primary3,
        light: themeColors.primary1,
        main: themeColors.primary2,
      },

      text: {
        contrast: themeColors.white,
        primary: themeColors.shade5,
        secondary: themeColors.shade4,
      },

      warning: {
        dark: themeColors.warningFont,
        light: themeColors.warningBackground,
        main: themeColors.warning,
      },
    },

    overrides: {
      MuiAlert: {
        standardError: {
          backgroundColor: themeColors.errorBackground,
          border: `1px solid ${themeColors.errorFont}`,

          '& $icon': {
            color: 'inherit',
          },
        },

        standardInfo: {
          backgroundColor: themeColors.primary2,
          border: `1px solid ${themeColors.primary4}`,

          '& $icon': {
            color: 'inherit',
          },
        },

        standardSuccess: {
          backgroundColor: themeColors.successBackground,
          border: `1px solid ${themeColors.successFont}`,

          '& $icon': {
            color: 'inherit',
          },
        },

        standardWarning: {
          backgroundColor: themeColors.warningBackground,
          border: `1px solid ${themeColors.warning}`,

          '& $icon': {
            color: 'inherit',
          },
        },
      },

      MuiFormLabel: {
        filled: {
          color: themeColors.primary3,
        },
      },

      MuiSwitch: {
        colorPrimary: {
          '&$checked': {
            color: themeColors.primary3,
          },
          '&$checked + $track': {
            backgroundColor: themeColors.primary2,
          },
        },
      },

      MuiCheckbox: {
        colorPrimary: {
          '&$checked': {
            color: themeColors.primary3,
          },
        },
      },

      MuiRadio: {
        root: {
          color: themeColors.primary4,
        },
        colorPrimary: {
          '&$checked': {
            color: themeColors.primary3,
          },
        },
      },

      MuiInputBase: {
        root: {
          // Override border-color of input fields.
          '&:not(.Mui-error) > input ~ fieldset': {
            borderColor: themeColors.primary4,
          },

          // Override border-color of non-empty input fields.
          '&:not(.Mui-error) > input:not(:placeholder-shown) ~ fieldset': {
            borderColor: themeColors.primary3,
          },

          // Override border-color of hovered input fields.
          '&:not(.Mui-error):hover > input[class*="MuiInputBase-input"] ~ fieldset':
            {
              borderColor: themeColors.primary3,
            },
        },
      },

      MuiPickersDay: {
        current: {
          backgroundColor: themeColors.primary1,
        },

        daySelected: {
          backgroundColor: themeColors.secondary4,
        },
      },
    },

    shadows: shadows.softShadows,
  },

  {
    name: 'dark',

    palette: {
      mode: 'dark',

      action: {
        active: themeColors.white,
      },

      background: {
        dark: themeColors.shade1,
        default: themeColors.shade5,
        paper: themeColors.shade4,
      },

      error: {
        main: themeColors.error,
      },

      gradients: {
        primary: themeGradients.primary,
        secondary: themeGradients.secondary,
      },

      primary: {
        main: themeColors.primary3,
      },

      secondary: {
        main: themeColors.secondary4,
      },

      success: {
        main: themeColors.success,
      },

      text: {
        contrast: themeColors.shade5,
        primary: themeColors.primary1,
        secondary: themeColors.shade3,
      },

      warning: {
        main: themeColors.warning,
      },
    },

    overrides: {
      MuiFormLabel: {
        filled: {
          color: themeColors.primary3,
        },
      },

      MuiInputBase: {
        root: {
          // Override border-color of input fields.
          '&:not(.Mui-error) > input ~ fieldset': {
            borderColor: themeColors.primary1,
          },

          // Override border-color of non-empty input fields.
          '&:not(.Mui-error) > input:not(:placeholder-shown) ~ fieldset': {
            borderColor: themeColors.primary3,
          },

          // Override border-color of hovered input fields.
          '&:not(.Mui-error):hover > input[class*="MuiInputBase-input"] ~ fieldset':
            {
              borderColor: themeColors.primary3,
            },
        },
      },

      MuiPickersDay: {
        current: {
          backgroundColor: themeColors.primary1,
        },

        daySelected: {
          backgroundColor: themeColors.secondary4,
        },
      },
    },

    shadows: shadows.softShadows,
  },
];

export default themes;

import { addDays, isAfter } from 'date-fns';
import localforage from 'localforage';
import NotificationsState from './state';

/**
 * Get unseenNotifications.
 */

export const getUnseenAndValidNotifications = () => {
  let updatedNotifications = [...NotificationsState.notifications];

  updatedNotifications = updatedNotifications.filter((item) => {
    const endDate = new Date(item.endDate);

    return isAfter(addDays(endDate, 1), new Date());
  });
  NotificationsState.notifications = updatedNotifications;

  localforage.setItem('notificationsState', updatedNotifications);

  const unseenAndValidNotifications = updatedNotifications.filter(
    (item) => !item.hasSeen,
  );

  return unseenAndValidNotifications;
};

import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: (nestedTheme: Theme) => ({
      '& input': {
        // Avoid background-color on auto filled fields.
        // https://github.com/mui-org/material-ui/issues/14427#issuecomment-466054906
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
          '-webkit-box-shadow': 'none',
          '-webkit-text-fill-color': nestedTheme.palette.text.primary,
        },
      },
    }),

    invertColor: {},
  }),

  { name: 'Form' },
);

export default useStyles;

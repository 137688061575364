import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material/';
import type { AppBarProps } from '@mui/material';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import clsx from 'clsx';

import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import { getRouteByPath, routes } from 'src/services/routing';

import useStyles from './TopBar.styles';

const ComponentTestIds = TestIds.components.topBar;

export interface TopBarProps extends AppBarProps {
  actionIcon?: ReactNode;
  className?: string;
  onAction?: () => void;
  onBack?: () => void;
  title?: string;
}

export const TopBar = forwardRef<any, TopBarProps>(
  (
    {
      actionIcon: _actionIcon,
      className,
      onAction: _onAction,
      onBack: _onBack,
      title: _title,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const topBar = useTopBar();
    const currentRoute = getRouteByPath(location.pathname);
    const isStackRoute = currentRoute?.routeObject.isStack;
    const routeTransKey = currentRoute
      ? currentRoute.routeName?.[0].toUpperCase() +
        currentRoute.routeName.substring(1)
      : undefined;
    const title = topBar.title || _title || t(`Views.${routeTransKey}.title`);
    const onBack = topBar.handleBack || _onBack;
    const onAction = topBar.handleAction || _onAction;
    const actionIcon = topBar.actionIcon || _actionIcon;

    // Handle navigating back.
    const handleBack = () => {
      if (!!onBack) {
        return onBack();
      }

      // If no onBack method was provided, redirect go back in history.
      history.goBack();
    };

    // Handle the Actions
    const handleAction = () => {
      if (!!onAction) {
        return onAction();
      }

      // If no onAction method was provided, redirect to app root.
      history.push(routes.app.path);
    };

    return (
      <AppBar
        className={clsx(classes.root, className)}
        color="default"
        data-test-id={ComponentTestIds.wrapper}
        ref={ref}
        {...props}
      >
        <Toolbar classes={{ root: classes.toolBar }}>
          <Box className={classes.iconWrapper}>
            {isStackRoute && (
              <IconButton
                data-test-id={ComponentTestIds.backIcon}
                onClick={handleBack}
                size="small"
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
          </Box>
          <Box className={classes.titleWrapper}>
            <Typography variant="h2">{title}</Typography>
          </Box>
          <Box className={classes.iconWrapper}>
            {actionIcon && (
              <IconButton
                data-test-id={ComponentTestIds.actionIcon}
                onClick={handleAction}
                size="small"
              >
                <>{actionIcon}</>
              </IconButton>
            )}
          </Box>
        </Toolbar>

        {topBar.content && (
          <Box className={classes.content}>
            <>{topBar.content}</>
          </Box>
        )}
      </AppBar>
    );
  },
);

export default TopBar;

import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { jssPreset, StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { de, enIN } from 'date-fns/locale';
import { create } from 'jss';

import ConfirmProvider from 'src/contexts/ConfirmContext';
import { SnackbarProvider } from 'src/contexts/SnackbarContext';
import { createTheme } from 'src/theme';
import { UserStore } from 'src/services/auth/auth';
import i18n from 'src/services/i18n/i18n';
import Routes from 'src/services/routing';
import StateInit from 'src/components/StateInit/StateInit';
import { UpdateNotification } from 'src/components/UpdateNotification/UpdateNotification';

import useStyles from './App.styles';

const App: FC = () => {
  const locales: { [key: string]: Locale } = { de, en: enIN };
  const locale = locales[i18n.language];
  const jss = create({ id: { minify: false }, plugins: jssPreset().plugins });
  const theme = createTheme('light');
  const registration = useRef<ServiceWorkerRegistration>();
  const [shouldShowUpdateNotification, setShouldShowUpdateNotification] =
    useState(false);

  useEffect(() => {
    const handleUpdateEvent = (event: any) => {
      registration.current = event.detail.registration;
      setShouldShowUpdateNotification(true);
    };

    document.addEventListener('onNewServiceWorker', handleUpdateEvent);

    return () =>
      document.removeEventListener('onNewServiceWorker', handleUpdateEvent);
  }, []);

  void useStyles();

  const reloadPage = () => {
    registration.current?.waiting?.postMessage({ type: 'SKIP_WAITING' });
    setShouldShowUpdateNotification(false);
    window.location.reload();
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <UserStore>
            <StateInit>
              <ConfirmProvider>
                <LocalizationProvider
                  adapterLocale={locale}
                  dateAdapter={AdapterDateFns}
                >
                  <SnackbarProvider>
                    <UpdateNotification
                      isOpen={shouldShowUpdateNotification}
                      onClick={reloadPage}
                    />
                    <Router>
                      <Routes />
                    </Router>
                  </SnackbarProvider>
                </LocalizationProvider>
              </ConfirmProvider>
            </StateInit>
          </UserStore>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

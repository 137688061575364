import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    accent: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,

      '&:hover': {
        backgroundColor: theme.palette.background.dark,
      },
    },

    ternary: {
      backgroundColor: theme.palette.background.default,

      '&:hover': {
        backgroundColor: theme.palette.background.dark,
      },
    },
  }),

  { name: 'Button' },
);

export default useStyles;

import localforage from 'localforage';
import { autorun, toJS, makeAutoObservable } from 'mobx';

import { BlockTime, RecurrenceType } from 'src/types';

export interface BlockTimeObject {
  recurring: BlockTime[];
  single: BlockTime[];
}

export class BlockTimesStateStore {
  blockTimes: BlockTimeObject = {recurring: [], single: []};
  initialized: boolean = false;

  constructor() {
    makeAutoObservable(this)
  }

  reset() {
    this.blockTimes = {recurring: [], single: []}
    this.initialized = false
  }
  
  get not_recurring() {
    console.log("recomputing")
    return this.blockTimes.single.filter(
      blockTime => blockTime.recurrenceType === RecurrenceType.ONCE
    );
  }
}

export interface BlockTimesStateProps {
  blockTimes: BlockTimeObject
  initialized: boolean;
}
  
const BlockTimesState = new BlockTimesStateStore();

autorun(() => {
  // ! Note: 'initialized' is set to 'false' after hot module reload in development environment.
  if (!BlockTimesState.initialized) {
    return;
  }

  // Converts the observable-object to a javascript-object as mobx-observables can not be stored.
  const blockTimesState = toJS(BlockTimesState);

  localforage
    .setItem('blockTimesState', blockTimesState)
    .catch((error: any) => console.warn('could not be stored locally ', error));
});

export default BlockTimesState;

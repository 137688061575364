import type { FC } from 'react';
import React from 'react';
import type { AlertProps as MUIAlertProps } from '@mui/lab';
import { Alert as MUIAlert } from '@mui/lab'; // eslint-disable-line no-restricted-imports
import clsx from 'clsx';

import useStyles from './Alert.styles';

/**
 * @property variant
 *
 *  Default: 'standard', 'filled', 'outlined'

 *  Custom: 'transparent'
 */
export interface AlertProps extends Omit<MUIAlertProps, 'variant'> {
  variant?: 'standard' | 'filled' | 'outlined' | 'transparent';
}

export const Alert: FC<AlertProps> = ({
  children,
  severity,
  variant,
  ...props
}) => {
  const classes = useStyles();
  const muiVariantProp = variant === 'transparent' ? undefined : variant;

  return (
    <MUIAlert
      classes={{
        root: clsx({
          [classes.transparent]:
            variant === 'transparent' && severity === undefined,
          [classes.transparentError]:
            variant === 'transparent' && severity === 'error',
          [classes.transparentInfo]:
            variant === 'transparent' && severity === 'info',
          [classes.transparentSuccess]:
            variant === 'transparent' && severity === 'success',
          [classes.transparentWarning]:
            variant === 'transparent' && severity === 'warning',
        }),
      }}
      severity={severity}
      variant={muiVariantProp}
      {...props}
    >
      {children}
    </MUIAlert>
  );
};

export default Alert;

import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';

const useStyles = makeStyles(
  () => ({
    root: {},
    noteWrapper: {
      position: 'sticky',
      left: 0,
      right: 0,
      top: 0,
      backgroundColor: themeColors.white,
      borderRadius: '4px',
      zIndex: 10,
    },
    noteWrapperBlockDay: {
      backgroundColor: 'rgba(240, 243, 245, 0.5)',
      borderLeft: `3px solid ${themeColors.shade4}`,
      backgroundImage: `repeating-linear-gradient(-39deg, transparent, transparent 21px, rgba(220, 226, 229, 0.5) 21px, rgba(220, 226, 229, 0.5) 41px)`,
    },
    note: {
      left: 0,
      right: 0,
      top: 0,
      zIndex: 1,
      height: '34px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 4px',
      borderRadius: '4px',
      marginBottom: '2px',
      marginLeft: '-3px',
    },
    blockTimeNote: {
      justifyContent: 'space-between',
      backgroundColor: 'transparent',
      borderLeft: `3px solid ${themeColors.shade4}`,
    },
    blockTimeIcon: {
      color: themeColors.shade4,
    },
  }),
  { name: 'WeeklyBanner' },
);

export default useStyles;

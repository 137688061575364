import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Add as AddIcon } from '@mui/icons-material';
import { getISOWeek } from 'date-fns';

import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import { WeekYear } from 'src/types/time';
import { routes } from 'src/services/routing';
import Button from 'src/components/Button/Button';
import DailyAgenda from 'src/components/DailyAgenda/DailyAgenda';
import DaySelectionSlider from 'src/components/DaySelectionSlider/DaySelectionSlider';
import Page from 'src/components/Page/Page';
import {
  TimeIntervalType,
  TimeIntervalTypeTabs,
} from 'src/components/TimeIntervalTypeTabs/TimeIntervalTypeTabs';
import { WeeklyAgenda } from 'src/components/WeeklyAgenda/WeeklyAgenda';
import { WeekSelectionSlider } from 'src/components/WeekSelectionSlider/WeekSelectionSlider';

import useStyles from './HomeView.styles';

const ViewTestIds = TestIds.views.home;

const HomeView: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'Views.Home' });
  const topBar = useTopBar();
  const classes = useStyles();
  const location = useLocation<any>();
  const appointmentCreatePath = routes.stack.routes.appointmentCreate.path;
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentWeekYear, setCurrentWeekYear] = useState<WeekYear>({
    week: getISOWeek(currentDate),
    year: currentDate.getFullYear(),
  });
  const [weekOrDayView, setWeekOrDayView] = useState(TimeIntervalType.DAY);
  const setDateOrWeekYear = (date: Date | WeekYear) => {
    if (date instanceof Date) {
      setCurrentDate(date);
    } else {
      setCurrentWeekYear(date);
    }
  };

  // reset date after type change for right date in agenda
  useEffect(() => {
    if (weekOrDayView === TimeIntervalType.DAY) {
      setCurrentDate(new Date());
    } else {
      setCurrentWeekYear({
        week: getISOWeek(new Date()),
        year: new Date().getFullYear(),
      });
    }
  }, [weekOrDayView]);

  useEffect(() => {
    topBar.setTitle(
      currentDate.toLocaleDateString('de-DE', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
    );

    topBar.setContent(
      <>
        {weekOrDayView === TimeIntervalType.DAY ? (
          <DaySelectionSlider onDateChange={setCurrentDate} />
        ) : (
          <WeekSelectionSlider
            today={currentDate}
            onDateChange={setDateOrWeekYear}
          />
        )}
        <TimeIntervalTypeTabs onTypeChange={setWeekOrDayView} />
      </>,
    );

    // Set TopBar properties back to default, when unmounting this view.
    return () => {
      topBar.resetTopBar();
    };
  }, [currentDate, weekOrDayView]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page
      className={classes.root}
      data-test-id={ViewTestIds.pageWrapper}
      title={t('title')}
    >
      {weekOrDayView === TimeIntervalType.DAY ? (
        <DailyAgenda selectedDate={currentDate} />
      ) : (
        <WeeklyAgenda selectedWeekYear={currentWeekYear} />
      )}

      <Button
        className={classes.appointmentCreateButton}
        color="secondary"
        component={RouterLink}
        data-test-id={ViewTestIds.appointmentCreateButton}
        elevation="3"
        to={{
          pathname: appointmentCreatePath,
          state: { stackRoot: location },
        }}
        variant="contained"
      >
        <AddIcon />
        {t('appointment')}
      </Button>
    </Page>
  );
};

export default HomeView;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    durationLabel: {
      marginLeft: 'auto',
    },
  }),
  {
    name: 'EndTimeSelect',
  },
);

export default useStyles;

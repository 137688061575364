import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { addMonths, format, isBefore, subMonths } from 'date-fns';
import { de, enIN } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import SwiperCore from 'swiper';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { ANO_URL } from 'src/constants';
import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import { openInNewTab, withHttps } from 'src/utils';
import MonthlySummariesState, {
  actions as monthlySummariesActions,
} from 'src/services/state/MonthlySummaries';
import type { Theme } from 'src/theme/types';
import Page from 'src/components/Page/Page';
import SummaryAccordion from 'src/components/SummaryAccordion/SummaryAccordion';

import useStyles from './MonthlySummaryView.styles';

import 'swiper/css';

type LoadingState = boolean | 'initial' | 'prev' | 'next';

SwiperCore.use([Mousewheel]);

const ViewTestIds = TestIds.views.monthlySummary;

const MonthlySummaryView: FC = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<LoadingState>('initial');
  const [swiper, setSwiper] = useState<SwiperCore>();
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const topBar = useTopBar();
  const monthlySummaries = MonthlySummariesState.monthlySummaries;
  const locales: { [key: string]: Locale } = { de, en: enIN };
  const locale = locales[i18n.language];
  const today = new Date();
  const currentYearMonth = format(today, 'yyyy-MM');
  const indexOfCurrentMonth = monthlySummaries.findIndex(
    (monthlySummary) => monthlySummary.yearMonth === currentYearMonth,
  );

  /**
   * Update slides after accordion animations have ended.
   */
  const handleAccordionToggle = () => {
    swiper?.updateSlides();
  };

  /**
   * Fetch additional monthly summaries.
   */
  const fetchAdditionalSummaries = async (_swiper: SwiperCore) => {
    if (!(_swiper.isBeginning || _swiper.isEnd) || loading) return;

    const refYearMonth = _swiper.isBeginning
      ? monthlySummaries[0].yearMonth
      : monthlySummaries[monthlySummaries.length - 1].yearMonth;

    const start = _swiper.isBeginning
      ? format(subMonths(new Date(refYearMonth), 12), 'yyyy-MM')
      : refYearMonth;

    const end = _swiper.isBeginning
      ? refYearMonth
      : format(addMonths(new Date(start), 12), 'yyyy-MM');

    setLoading(_swiper.isBeginning ? 'prev' : 'next');

    await monthlySummariesActions
      .fetchMonthlySummaries({ end, start })
      .catch(() => console.warn('Could not fetch additional summaries'));

    setLoading(false);
  };

  const swiperProps: SwiperProps = {
    direction: 'vertical',
    freeMode: true,
    initialSlide: indexOfCurrentMonth,
    mousewheel: { thresholdDelta: 10 },
    noSwipingSelector: '[class*=scrollable]',
    onSwiper: setSwiper,
    onToEdge: fetchAdditionalSummaries,
    slidesOffsetAfter: parseInt(theme.spacing(2)),
    slidesOffsetBefore: parseInt(theme.spacing(2)),
    slidesPerView: 'auto',
    spaceBetween: parseInt(theme.spacing(5)),
    threshold: 3,
  };

  /**
   * Set TopBar link when mounting this view.
   */
  useEffect(() => {
    topBar.setContent(
      <Link
        onClick={() => openInNewTab(withHttps(ANO_URL))}
        style={{ display: 'block', textAlign: 'center' }}
        underline="always"
        variant="caption"
      >
        {t('Views.MonthlySummary.ano')}
      </Link>,
    );

    // Set TopBar properties back to default, when unmounting this view.
    return () => {
      topBar.resetTopBar();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Refetch monthly summaries when mounting this view to have most up to date data.
   */
  useEffect(() => {
    const refetchSummaries = async () => {
      const replaceExisting = true;

      setLoading('initial');

      await monthlySummariesActions
        .fetchMonthlySummaries(undefined, replaceExisting)
        .catch(() => console.warn('Could not refetch summaries'));

      setLoading(false);
    };

    refetchSummaries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page
      className={classes.root}
      data-test-id={ViewTestIds.pageWrapper}
      title={t('Views.MonthlySummary.title')}
    >
      {loading === 'initial' && (
        <Box
          alignItems="center"
          display="flex"
          flexGrow={1}
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      {loading !== 'initial' && (
        <Swiper
          className={classes.swiper}
          data-test-id={ViewTestIds.slider}
          {...swiperProps}
        >
          {loading === 'prev' && (
            <SwiperSlide style={{ textAlign: 'center' }}>
              <CircularProgress />
            </SwiperSlide>
          )}

          {monthlySummaries.map((monthlySummary) => {
            const { workDone, workPlanned, workHoursPredicted, yearMonth } =
              monthlySummary;

            const yearMonthDate = new Date(yearMonth);
            const isCurrent = currentYearMonth === yearMonth;
            const showDone = isCurrent || isBefore(yearMonthDate, today);
            const showPlanned = isCurrent || !isBefore(yearMonthDate, today);
            const showPredicted = isCurrent;

            return (
              <SwiperSlide data-test-id={ViewTestIds.slide} key={yearMonth}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      {format(new Date(yearMonth), 'MMMM yyyy', {
                        locale,
                      })}
                    </Typography>
                  </Grid>

                  {showDone && (
                    <Grid item xs={12}>
                      <SummaryAccordion
                        summaryType="done"
                        TransitionProps={{
                          onEntered: handleAccordionToggle,
                          onExited: handleAccordionToggle,
                        }}
                        workSummary={workDone}
                      />
                    </Grid>
                  )}

                  {showPlanned && (
                    <Grid item xs={12}>
                      <SummaryAccordion
                        summaryType="planned"
                        TransitionProps={{
                          onEntered: handleAccordionToggle,
                          onExited: handleAccordionToggle,
                        }}
                        workSummary={workPlanned}
                      />
                    </Grid>
                  )}

                  {showPredicted && (
                    <Grid item xs={12}>
                      <SummaryAccordion
                        summaryType="predicted"
                        TransitionProps={{
                          onEntered: handleAccordionToggle,
                          onExited: handleAccordionToggle,
                        }}
                        workHoursPredicted={workHoursPredicted}
                      />
                    </Grid>
                  )}
                </Grid>
              </SwiperSlide>
            );
          })}

          {loading === 'next' && (
            <SwiperSlide style={{ textAlign: 'center' }}>
              <CircularProgress />
            </SwiperSlide>
          )}
        </Swiper>
      )}
    </Page>
  );
};

export default observer(MonthlySummaryView);

import CustomersState from './state';

/**
 * Get customer by its id.
 */
export const getCustomerById = (customerId: number | string) => {
  const customers = CustomersState.customers;

  return customers.find((customer) => customer.id === customerId);
};

import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
  }),
  { name: 'BlockTimeEditView' },
);

export default useStyles;

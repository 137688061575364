import type { FC } from 'react';
import { TabsProps } from '@mui/material';

import { WeekYear } from 'src/types';
import getWeekTimeIntervalTabs from 'src/utils/getWeekTimeIntervalTabs';
import { TimeIntervalSelectionSlider } from 'src/components/TimeIntervalSelectionSlider/TimeIntervalSelectionSlider';

export interface WeekSelectionSliderProps extends TabsProps {
  today: Date;
  onDateChange?: (date: Date | WeekYear) => void;
}

export const WeekSelectionSlider: FC<WeekSelectionSliderProps> = ({
  className,
  onDateChange = () => {},
  ...props
}) => {
  const today = new Date();
  const tabs = getWeekTimeIntervalTabs(today);
  const indexOfCurrentWeek = tabs.findIndex((element) => element.highlight);

  const handleTabChange = (value: number) => {
    const date = tabs[value].date;
    onDateChange(date);
  };

  return (
    <TimeIntervalSelectionSlider
      tabs={tabs}
      indexOfCurrentDate={indexOfCurrentWeek}
      onTabChange={handleTabChange}
      className={className}
      {...props}
    />
  );
};

import { eachDayOfInterval, lastDayOfWeek } from 'date-fns';

import getFirstDateOfISOWeek from './getFirstDateOfISOWeek';
import { hasAppointmentsAtDate } from '.';

const hasEntryInWeek = (
  week: number,
  year: number,
  type: 'appointment' | 'vacation',
) => {
  const firstDateInWeek = getFirstDateOfISOWeek(week, year);
  const lastDateInWeek = lastDayOfWeek(firstDateInWeek);
  const daysInWeek = eachDayOfInterval({
    end: lastDateInWeek,
    start: firstDateInWeek,
  });

  switch (type) {
    case 'appointment':
      return daysInWeek.some((date: Date) => hasAppointmentsAtDate(date));
    default:
      return false;
  }
};

export default hasEntryInWeek;

import type { FC } from 'react';
import React from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';
import clsx from 'clsx';

import useStyles from './LoadingScreen.styles';

export interface LoadingScreenProps extends BoxProps {
  size?: number;
}

export const LoadingScreen: FC<LoadingScreenProps> = ({
  className,
  size = 80,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default LoadingScreen;

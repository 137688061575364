import i18next from 'i18next';

import { TestIds } from 'src/testIds';

import { CareAllowanceOptions } from './needsAssessment';

const ComponentTestIds = TestIds.components.needsAssessmentForm;

export const careAllowanceOptions = [
  {
    dataTestId: ComponentTestIds.receivingCareAllowance.yes,
    label: i18next.t('NeedsAssessmentForm.yes'),
    value: CareAllowanceOptions.YES,
  },
  {
    dataTestId: ComponentTestIds.receivingCareAllowance.partially,
    label: i18next.t('NeedsAssessmentForm.ReceivingCareAllowance.partially'),
    value: CareAllowanceOptions.PARTIALLY,
  },
  {
    dataTestId: ComponentTestIds.receivingCareAllowance.no,
    label: i18next.t('NeedsAssessmentForm.no'),
    value: CareAllowanceOptions.NO,
  },
];

type DispositionOptions = {
  dataTestId: string;
  fieldName: string;
  label: string;
  selectValue: number;
  value: 'biWeekly' | 'weekly';
}[];

export const dispositionOptions = ({
  valueBiWeekly,
  valueWeekly,
}: {
  valueBiWeekly: number;
  valueWeekly: number;
}): DispositionOptions => [
  {
    dataTestId: ComponentTestIds.deploymentPlanning.dispositionHoursWeekly,
    fieldName: 'deploymentPlanning.dispositionHoursWeekly',
    label: i18next.t('NeedsAssessmentForm.DeploymentPlanning.hoursWeekly'),
    selectValue: valueWeekly,
    value: 'weekly',
  },
  {
    dataTestId: ComponentTestIds.deploymentPlanning.dispositionHoursBiWeekly,
    fieldName: 'deploymentPlanning.dispositionHoursBiWeekly',
    label: i18next.t('NeedsAssessmentForm.DeploymentPlanning.hoursBiWeekly'),
    selectValue: valueBiWeekly,
    value: 'biWeekly',
  },
];

export const freeOfChargeAid = ({
  homeEmergencyCall,
  nursingAdvice,
}: {
  homeEmergencyCall: boolean;
  nursingAdvice: boolean;
}) => [
  {
    checked: homeEmergencyCall,
    dataTestId: ComponentTestIds.freeOfChargeAid.homeEmergencyCall,
    label: i18next.t(
      'NeedsAssessmentForm.FreeOfChargeAid.homeEmergencyCallRequested',
    ),
    name: 'freeOfChargeAidRequested.homeEmergencyCall',
  },
  {
    checked: nursingAdvice,
    dataTestId: ComponentTestIds.freeOfChargeAid.nursingAdvice,
    label: i18next.t(
      'NeedsAssessmentForm.FreeOfChargeAid.nursingAdviceRequested',
    ),
    name: 'freeOfChargeAidRequested.nursingAdvice',
  },
];

export const requiredServiceOptions = ({
  bedLinen,
  cooking,
  errands,
  ironing,
  normalChores,
  shopping,
  strolling,
  windowCleaning,
}: {
  bedLinen: boolean;
  cooking: boolean;
  errands: boolean;
  ironing: boolean;
  normalChores: boolean;
  shopping: boolean;
  strolling: boolean;
  windowCleaning: boolean;
}) => [
  {
    checked: normalChores,
    dataTestId: ComponentTestIds.requiredServices.normalChores,
    label: i18next.t('NeedsAssessmentForm.RequiredServices.normalChores'),
    name: 'requiredServices.normalChores',
  },
  {
    checked: cooking,
    dataTestId: ComponentTestIds.requiredServices.cooking,
    label: i18next.t('NeedsAssessmentForm.RequiredServices.cooking'),
    name: 'requiredServices.cooking',
  },
  {
    checked: shopping,
    dataTestId: ComponentTestIds.requiredServices.shopping,
    label: i18next.t('NeedsAssessmentForm.RequiredServices.shopping'),
    name: 'requiredServices.shopping',
  },
  {
    checked: strolling,
    dataTestId: ComponentTestIds.requiredServices.strolling,
    label: i18next.t('NeedsAssessmentForm.RequiredServices.strolling'),
    name: 'requiredServices.strolling',
  },
  {
    checked: ironing,
    dataTestId: ComponentTestIds.requiredServices.ironing,
    label: i18next.t('NeedsAssessmentForm.RequiredServices.ironing'),
    name: 'requiredServices.ironing',
  },
  {
    checked: windowCleaning,
    dataTestId: ComponentTestIds.requiredServices.windowCleaning,
    label: i18next.t('NeedsAssessmentForm.RequiredServices.windowCleaning'),
    name: 'requiredServices.windowCleaning',
  },
  {
    checked: errands,
    dataTestId: ComponentTestIds.requiredServices.errands,
    label: i18next.t('NeedsAssessmentForm.RequiredServices.errands'),
    name: 'requiredServices.errands',
  },
  {
    checked: bedLinen,
    dataTestId: ComponentTestIds.requiredServices.bedLinen,
    label: i18next.t('NeedsAssessmentForm.RequiredServices.bedLinen'),
    name: 'requiredServices.bedLinen',
  },
];

export const yesNoOptions = [
  { title: i18next.t('NeedsAssessmentForm.yes'), value: 'yes' },
  { title: i18next.t('NeedsAssessmentForm.no'), value: 'no' },
];

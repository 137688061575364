import type { AxiosResponse } from 'axios';
import { addDays, addMonths, format, subDays, subMonths } from 'date-fns';

import type {
  Appointment,
  AppointmentPatchData,
  AppointmentPostData,
  AppointmentRecord,
  AppointmentRecordPostData,
  Customer,
  MonthlySummary,
  Notification,
} from 'src/types';
import { BlockTime } from 'src/types/blockTimes';
import UserState from 'src/services/state/User';
import { NeedsAssessmentPostData } from 'src/components/NeedsAssessment/needsAssessment';

import axi from './axios';

const today = new Date();

const defaultAppointmentsTimeRange = {
  end: format(addDays(today, 90), 'yyyy-MM-dd'),
  start: format(subDays(today, 90), 'yyyy-MM-dd'),
};

const defaultSummariesTimeRange = {
  end: format(addMonths(today, 6), 'yyyy-MM'),
  start: format(subMonths(today, 6), 'yyyy-MM'),
};

export interface GetAppointmentsProps {
  end: string;
  start: string;
}

export type GetAppointments = {
  (requestProps?: GetAppointmentsProps): Promise<AxiosResponse<Appointment[]>>;
};
/**
 * @description Returns a list of appointments that are visible to the current user.
 */
export const getAppointments: GetAppointments = (
  requestProps = defaultAppointmentsTimeRange,
) => {
  const { ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/appointments/`;

  return axi.get(requestUrl, { ...UserState.authConfig, params });
};

export interface CreateAppointmentProps {
  data: AppointmentPostData;
}

interface AppointmentResponse {
  blockTimeConflicts: string[];
}
export type CreateAppointment = {
  (
    requestProps: CreateAppointmentProps,
  ): Promise<AxiosResponse<AppointmentResponse>>;
};
/**
 * @description Creates an appointment.
 */
export const postAppointment: CreateAppointment = (requestProps) => {
  const { data, ...params } = requestProps;
  const { id, recurrenceIndex, status, ...postData } = data;
  const requestUrl = `users/${UserState.user?.id}/appointments/`;

  return axi.post(requestUrl, postData, { ...UserState.authConfig, params });
};

export interface EditAppointmentProps {
  data: AppointmentPatchData;
}
export type EditAppointment = {
  (requestProps: EditAppointmentProps): Promise<AxiosResponse<Appointment>>;
};
/**
 * @description Update an appointment.
 */
export const patchAppointment: EditAppointment = (requestProps) => {
  const { data, ...params } = requestProps;
  const { id, recurrenceIndex, status, startRecurrenceIndex, ...patchData } =
    data;
  const queryParameters = new URLSearchParams({
    recurrence_index: `${recurrenceIndex}`,
  });

  if (startRecurrenceIndex !== null) {
    queryParameters.set(
      'start_recurrence_index',
      startRecurrenceIndex.toString(),
    );
  }

  const requestUrl = `users/${UserState.user?.id}/appointments/${id}/?${queryParameters}`;

  return axi.patch(requestUrl, patchData, { ...UserState.authConfig, params });
};

export interface DeleteAppointmentProps {
  appointmentId: number | string;
  recurrenceIndex: number | null;
  startRecurrenceIndex: number | null;
}
export type DeleteAppointment = {
  (requestProps: DeleteAppointmentProps): Promise<AxiosResponse>;
};
/**
 * @description Deletes an appointment.
 */
export const deleteAppointment: DeleteAppointment = (requestProps) => {
  const { appointmentId, recurrenceIndex, startRecurrenceIndex, ...params } =
    requestProps;
  const queryParameters = new URLSearchParams({
    recurrence_index: `${recurrenceIndex}`,
  });

  if (startRecurrenceIndex !== null) {
    queryParameters.set(
      'start_recurrence_index',
      startRecurrenceIndex.toString(),
    );
  }
  const requestUrl = `users/${UserState.user?.id}/appointments/${appointmentId}/?${queryParameters}`;

  return axi.delete(requestUrl, { ...UserState.authConfig, params });
};

export interface CreateAppointmentRecordProps {
  data: AppointmentRecordPostData;
}
type CreateAppointmentRecord = {
  (
    requestProps: CreateAppointmentRecordProps,
  ): Promise<AxiosResponse<AppointmentRecord>>;
};
/**
 * @description Creates an appointment record.
 */
export const postAppointmentRecord: CreateAppointmentRecord = (
  requestProps,
) => {
  const { data, ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/appointment-records/`;

  return axi.post(requestUrl, data, { ...UserState.authConfig, params });
};

export interface GetCustomersProps {}
export type GetCustomers = {
  (requestProps?: GetCustomersProps): Promise<AxiosResponse<Customer[]>>;
};
/**
 * @description Returns a list of customers that are visible to the current user.
 */
export const getCustomers: GetCustomers = (requestProps = {}) => {
  const { ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/customers/`;

  return axi.get(requestUrl, { ...UserState.authConfig, params });
};

export interface GetNotificationsProps {}
export type GetNotifications = {
  (requestProps?: GetNotificationsProps): Promise<AxiosResponse<Notification[]>>;
};
/**
 * @description Returns a list of notifications that are visible to the current user.
 */
export const getNotifications: GetNotifications = (requestProps = {}) => {
  const { ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/notifications/`;

  return axi.get(requestUrl, { ...UserState.authConfig, params });
};

export interface GetMonthlySummariesProps {
  end: string;
  start: string;
}

export type GetMonthlySummaries = {
  (
    requestProps?: GetMonthlySummariesProps,
  ): Promise<AxiosResponse<MonthlySummary[]>>;
};
/**
 * @description Returns a list of monthly summaries of the current user.
 */
export const getMonthlySummaries: GetMonthlySummaries = (
  requestProps = defaultSummariesTimeRange,
) => {
  const { ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/monthly-summaries/`;

  return axi.get(requestUrl, { ...UserState.authConfig, params });
};

export interface GetCareBoxInfoProps {
  customerId: number | string;
}
export type GetCareBoxInfoType = {
  (requestProps: GetCareBoxInfoProps): Promise<AxiosResponse<string>>;
};
/**
 * @description Returns base64 encoded query parameters.
 */
export const getCareBoxInfo: GetCareBoxInfoType = (requestProps) => {
  const { customerId } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/customers/${customerId}/get_care_box_info/`;

  return axi.get(requestUrl, { ...UserState.authConfig, params: customerId });
};

export interface CreateBlockTimeProps {
  data: BlockTime;
}

interface BlockTimeResponse {
  appointmentConflicts: string[];
}
export type CreateBlockTime = {
  (
    requestProps: CreateBlockTimeProps,
  ): Promise<AxiosResponse<BlockTimeResponse>>;
};

/**
 * @description Creates a BlockTime.
 */
export const postBlockTimes: CreateBlockTime = (
  requestProps: CreateBlockTimeProps,
) => {
  const { data, ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/block-times/`;

  return axi.post(requestUrl, data, { ...UserState.authConfig, params });
};

export interface EditBlockTimeProps {
  data: BlockTime;
}
export type EditBlockTime = {
  (requestProps: EditBlockTimeProps): Promise<AxiosResponse<BlockTime>>;
};

/**
 * @description Updates a BlockTime.
 */
export const patchBlockTime: EditBlockTime = (requestProps) => {
  const { data, ...params } = requestProps;
  const { id, recurrenceIndex, ...patchData } = data;
  const requestUrl = `users/${UserState.user?.id}/block-times/${id}/`;

  return axi.patch(requestUrl, patchData, { ...UserState.authConfig, params });
};

export interface DeleteBlockTimeProps {
  blockTimeId: number | string;
  recurrenceIndex?: number | null;
}
export type DeleteBlockTime = {
  (requestProps: DeleteBlockTimeProps): Promise<AxiosResponse>;
};

/**
 * @description Deletes a BlockTime.
 */
export const deleteBlockTime: DeleteBlockTime = (requestProps) => {
  const { blockTimeId, recurrenceIndex, ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/block-times/${blockTimeId}/`;

  return axi.delete(requestUrl, { ...UserState.authConfig, params });
};

export type GetBlockTimesType = {
  (): Promise<AxiosResponse<{ single: BlockTime[]; recurring: BlockTime[] }>>;
};

export const getBlockTimes: GetBlockTimesType = (
  requestProps = defaultAppointmentsTimeRange,
) => {
  const { ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/block-times/`;

  return axi.get(requestUrl, {
    ...UserState.authConfig,
    params
  });
};

interface CreateNeedsAssessmentProps {
  customerId: number;
  data: NeedsAssessmentPostData;
}
type CreateNeedsAssessment = {
  (requestProps: CreateNeedsAssessmentProps): Promise<AxiosResponse>;
};

export const createNeedsAssessment: CreateNeedsAssessment = (requestProps) => {
  const { customerId, data, ...params } = requestProps;
  const requestUrl = `users/${UserState.user?.id}/customers/${customerId}/needs_assessment/`;

  return axi.post(requestUrl, data, { ...UserState.authConfig, params });
};


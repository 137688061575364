import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import { Box, IconButton, Modal, ModalProps, Typography } from '@mui/material';

import { useConfirm } from 'src/contexts/ConfirmContext';
import { TestIds } from 'src/testIds';
import { Customer } from 'src/types';

import { NeedsAssessmentForm } from './NeedsAssessmentForm';

import useStyles from './NeedsAssessment.styles';

const CloseIconTestId = TestIds.components.needsAssessmentForm.closeIcon;

interface Props extends Partial<ModalProps> {
  customer: Customer;
  open: boolean;
  handleCancel: () => void;
  handleClose: () => void;
  orderCareBox: () => void;
}

export const NeedsAssessmentModal: FC<Props> = ({
  customer,
  handleCancel,
  handleClose,
  open,
  orderCareBox,
  ...props
}) => {
  const { closeButton, form, modal } = useStyles();
  const confirm = useConfirm();
  const { t } = useTranslation('translation');

  return (
    <Modal open={open} {...props}>
      <Box className={modal}>
        <Box className={form}>
          <IconButton
            data-test-id={CloseIconTestId}
            className={closeButton}
            onClick={() => {
              confirm({
                content: (
                  <Typography>
                    {t('NeedsAssessmentForm.ConfirmModal.text')}
                  </Typography>
                ),
                hideCloseButton: true,
                primaryText: t('General.confirm'),
                secondaryText: t('General.abort'),
              })
                .then(handleCancel)
                // Avoid getting uncaught onPromise error
                .catch(() => {});
            }}
          >
            <Close />
          </IconButton>
          <NeedsAssessmentForm
            afterSubmit={handleClose}
            customer={customer}
            orderCareBox={orderCareBox}
          />
        </Box>
      </Box>
    </Modal>
  );
};

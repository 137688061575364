import { makeStyles } from '@mui/styles';

import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '&.Mui-expanded': {
        margin: 0,
      },
    },

    accordionSummary: {
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,

      '&.done': {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.dark,
        borderBottom: `1px solid ${theme.palette.success.main}`,
      },

      '&.planned': {
        backgroundColor: theme.palette.ternary.main,
        color: theme.palette.primary.dark,
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
      },

      '&.predicted': {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.text.contrast,
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
      },
    },

    accordionSummaryContent: {
      margin: `${theme.spacing(2)} 0`,
      flexWrap: 'wrap',

      '&.Mui-expanded': {
        margin: `${theme.spacing(2)} 0`,
      },
    },

    accordionSummaryExpandIcon: {
      color: 'inherit',
      alignSelf: 'flex-start',
      // The icon button used to contain a 12px padding in MUI v4.
      // For Mui v5 this padding needs to be set explicitly.
      paddingTop: '12px',
      transformOrigin: 'center',

      '&.Mui-expanded': {
        transform: 'rotate(720deg)',
      },
    },

    title: {
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
    },

    subtitle: {
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
    },

    expandIcon: {
      fontSize: 'inherit',
      marginLeft: theme.spacing(1),
      transform: 'rotate(0deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '.Mui-expanded &': {
        transform: 'rotate(180deg)',
      },
    },

    accordionDetailsRoot: {
      padding: theme.spacing(2),
      overflow: 'auto',
      flexDirection: 'column',

      '&.scrollable': {
        maxHeight: 200, // Shows 6 1/2 items. 1/2 to indicate that the user can scroll this container.

        [theme.breakpoints.up('md')]: {
          maxHeight: 'none',
        },
      },
    },

    summaryItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },

    attachment: {
      textAlign: 'center',
      padding: theme.spacing(2),
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      position: 'relative',

      '& $title': {
        justifyContent: 'center',
      },
    },
  }),

  { name: 'SummaryAccordion' },
);

export default useStyles;

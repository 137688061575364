import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }),
  { name: 'Page' },
);

export default useStyles;

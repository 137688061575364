import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),

  { name: 'ForgotPasswordForm' },
);

export default useStyles;

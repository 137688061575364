import type { AxiosRequestConfig } from 'axios';
import localforage from 'localforage';
import { autorun, observable, toJS } from 'mobx';

import { User } from 'src/types';

export interface UserStateProps {
  authConfig: AxiosRequestConfig | undefined;
  initialized: boolean;
  user: User | null | undefined;
}

export const initialUserState: UserStateProps = {
  authConfig: undefined,
  initialized: false,
  user: undefined,
};

const UserState = observable<UserStateProps>(initialUserState);

autorun(() => {
  // ! Note: 'initialized' is set to 'false' after hot module reload in development environment.
  if (!UserState.initialized) {
    return;
  }

  // Converts the observable-object to a javascript-object as mobx-observables can not be stored.
  const userState = toJS(UserState);

  localforage
    .setItem('userState', userState)
    .catch((error: any) => console.warn('could not be stored locally ', error));
});

export default UserState;

import type { ElementType, FC } from 'react';
import React from 'react';
import type { ButtonProps as MUIButtonProps } from '@mui/material';
import { Button as MUIButton, CircularProgress } from '@mui/material'; // eslint-disable-line no-restricted-imports
import clsx from 'clsx';

import useStyles from './Button.styles';

/**
 * @property color
 *
 *  Default: 'inherit', 'primary', 'secondary', 'default'
 *
 *  Custom: 'accent', 'ternary'
 */
export interface ButtonProps
  extends Omit<MUIButtonProps<ElementType>, 'color'> {
  color?: 'accent' | 'inherit' | 'primary' | 'secondary' | 'ternary';
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  color,
  endIcon,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const loadingIcon = <CircularProgress color="inherit" size="0.8em" />;
  const muiColorProp =
    color === 'accent' || color === 'ternary' ? undefined : color;

  return (
    <MUIButton
      classes={{
        contained: clsx({
          [classes.accent]: color === 'accent',
          [classes.ternary]: color === 'ternary',
        }),
      }}
      color={muiColorProp}
      endIcon={loading ? loadingIcon : endIcon}
      {...props}
    >
      {children}
    </MUIButton>
  );
};

export default Button;

import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';
import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    closeButton: {
      float: 'right',
    },
    form: { maxWidth: theme.spacing(100) },
    malteserNumber: {
      color: theme.palette.primary.light,
      marginLeft: theme.spacing(1.5),
      textDecoration: 'none',
    },
    malteserNumberRow: {
      alignContent: 'center',
      color: theme.palette.primary.light,
      display: 'flex',
      fontWeight: 500,
    },
    modal: {
      backgroundColor: themeColors.white,
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      overflow: 'auto',
      padding: theme.spacing(2),
    },
    signatureFieldLabel: {
      color: theme.palette.text.primary,
      height: theme.spacing(10),
      border: `dashed 2px ${theme.palette.primary.main}`,
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '&[class*="error"]': {
        borderColor: theme.palette.error.main,
      },
    },
    signaturePreview: {
      backgroundColor: theme.palette.ternary.light,
      height: theme.spacing(10),
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: 4,
      position: 'relative',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    signatureName: {
      position: 'absolute',
      top: theme.spacing(0.5),
      left: theme.spacing(0.5),
      color: theme.palette.primary.dark,
    },
    editIcon: {
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      position: 'absolute',
      cursor: 'pointer',
    },
  }),
  { name: 'NeedsAssessment' },
);

export default useStyles;

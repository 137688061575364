import type { FC, FocusEvent } from 'react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DateRange as CalendarIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabelProps,
  SelectProps,
  Switch,
  Typography,
} from '@mui/material';
import type { MobileDatePickerProps } from '@mui/x-date-pickers';
import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import clsx from 'clsx';
import { add, addMonths, format, isAfter, parse } from 'date-fns';
import type { FormikHelpers, FormikProps } from 'formik';
import { Formik } from 'formik';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { TestIds } from 'src/testIds';
import { Appointment, Customer } from 'src/types';
import { RecurrenceType } from 'src/types/appointment';
import i18n from 'src/services/i18n/i18n';
import { routes } from 'src/services/routing';
import { actions as appointmentsActions } from 'src/services/state/Appointments';
import CustomersState from 'src/services/state/Customers';
import Alert from 'src/components/Alert/Alert';
import Button from 'src/components/Button/Button';
import Form from 'src/components/Form/Form';
import TextField from 'src/components/TextField/TextField';
import VirtualListBox from 'src/components/VirtualListBox/VirtualListBox';

import { EndTimeSelect } from '../EndTimeSelect/EndTimeSelect';
import RecurringElement from '../RecurringElement/RecurringElement';
import { StartTimeSelect } from '../StartTimeSelect/StartTimeSelect';

import useStyles from './AppointmentForm.styles';

const ComponentTestIds = TestIds.components.appointmentForm;

interface AppointmentFormProps {
  afterSubmit?: () => void;
  appointment?: Appointment;
  className?: string;
  editRecurrence?: boolean;
}

interface Values {
  customerId: string;
  dateDay: string;
  endTime: string;
  recurrenceEndDay: string;
  recurrenceIndex: number | null;
  recurrenceType: RecurrenceType;
  startTime: string;
  submit?: string;
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const validationSchema = Yup.object().shape({
  customerId: Yup.string()
    .max(255)
    .required(i18n.t('AppointmentForm.Fields.CustomerId.required')),
  dateDay: Yup.date()
    .nullable()
    .required(i18n.t('General.Form.dateDayRequired')),
  startTime: Yup.date()
    .nullable()
    .required(i18n.t('General.Form.startRequired')),
  endTime: Yup.date()
    .nullable()
    .required(i18n.t('General.Form.endRequired'))
    .when(
      'startTime',
      (startTime, schema) =>
        startTime &&
        schema.min(
          add(startTime, { minutes: 1 }),
          i18n.t('General.Form.mustBeAfterStartTime'),
        ),
    ),
  recurrenceType: Yup.number().required(
    i18n.t('General.Form.required'),
  ),
  recurrenceEndDay: Yup.string()
    .nullable()
    .when(['dateDay', 'recurrenceType'], (dateDay, recurrenceType) =>
      Yup.string()
        .nullable()
        .test(
          'is after DateDay',
          i18n.t('AppointmentForm.mustBeAfterDateDay'),
          (recurrenceEndDay) =>
            dateDay &&
            recurrenceType !== RecurrenceType.ONCE &&
            recurrenceEndDay
              ? isAfter(new Date(recurrenceEndDay), new Date(dateDay))
              : true,
        ),
    ),
});
/* eslint-enable */

export const AppointmentForm: FC<AppointmentFormProps> = ({
  afterSubmit: _afterSubmit,
  appointment,
  className,
  editRecurrence,
  ...props
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const formikRef = useRef<FormikProps<Values>>(null);
  const customers = CustomersState.customers;
  const today = new Date();
  const isEditView = !!appointment;
  const customerId = appointment ? `${appointment.customerId}` : '';
  const dateDay = appointment ? appointment.dateDay : null;
  const recurrenceIndex = appointment ? appointment.recurrenceIndex : null;
  const recurrenceType =
    appointment && editRecurrence
      ? appointment.recurrenceType
      : RecurrenceType.ONCE;
  const recurrenceEndDay = appointment
    ? appointment.recurrenceEndDay || null
    : format(addMonths(new Date(), 3), 'yyyy-MM-dd');
  const endTime = appointment
    ? parse(appointment.endTime, 'HH:mm:ss', today).toString()
    : '';
  const startTime = appointment
    ? parse(appointment.startTime, 'HH:mm:ss', today).toString()
    : '';
  const afterSubmit =
    _afterSubmit || (() => history.push(routes.app.routes.home.path));

  const initialValues: Values = {
    customerId,
    dateDay: dateDay as string, // Will be an empty string as soon as the DatePicker is initialized.
    endTime,
    recurrenceEndDay: recurrenceEndDay as string, // Will be an empty string as soon as the DatePicker is initialized.
    recurrenceIndex,
    recurrenceType,
    startTime,
    submit: '',
  };

  /**
   * Updates formik after changing the AutoComplete`s selection.
   */
  const handleAutoCompleteChange = (
    event: React.ChangeEvent<{}>,
    value: Customer | null,
  ) => {
    formikRef.current?.setFieldValue(
      'customerId',
      value ? value.id : initialValues.customerId,
    );
  };

  /**
   * Render the desired AutoCompleteOptionLabel.
   */
  const getAutoCompleteOptionLabel = (option: Customer) =>
    `${option.lastName}, ${option.firstName}`;

  /**
   * Render the AutocompleteOption
   */
  const renderAutocompleteOption = (props: object, option: Customer) => (
    <li {...props}>
      <Box>
        <Typography>
          {option.lastName}, {option.firstName}
        </Typography>

        <Typography variant="caption">
          {option.street} {option.streetNo} | {option.zipCode} {option.city}
        </Typography>
      </Box>
    </li>
  );

  /**
   * Updates formik after changing the DatePicker`s selection.
   */
  const handleDatePickerChange: MobileDatePickerProps<
    Date,
    string
  >['onChange'] = (date, fieldName) => {
    formikRef.current?.setFieldValue(
      fieldName!,
      format(new Date(date || ''), 'yyyy-MM-dd'),
    );
  };

  /**
   * Scrolls to the first third of the select drop down if no item has been selected yet.
   */
  const handleSelectFocus = (
    event: FocusEvent<HTMLDivElement>,
    value?: string,
  ) => {
    if (value) return;

    const menuElement = event.target.closest('[class*="MuiList-root"]')
      ?.parentElement;

    // Only scroll if the drop down was not scrolled already to prevent
    // strange behavior caused by bubbling focus events.
    if (menuElement && !menuElement.scrollTop) {
      menuElement.scrollTo({ top: menuElement?.scrollHeight / 3 });
    }
  };

  /**
   * Format endTimeSteps depending on the startTime value.
   */
  const handleStartTimeChange: SelectProps['onChange'] = (event) => {
    const startTime = (event.target.value as string) ?? undefined;

    formikRef.current?.setFieldValue('startTime', startTime);
  };

  /**
   * Sets the recurrenceType value to 'WORKDAYS' when the toggle is checked.
   * Setting it to any value above 'ONCE' it will render the recurrenceType radio group.
   */
  const handleRecurrenceToggleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = event.target;
    const value = target.checked
      ? RecurrenceType.WORKDAYS
      : RecurrenceType.ONCE;

    formikRef.current?.setFieldValue('recurrenceType', value);
  };

  /**
   * Prepare the form`s values and handle success and error cases after submitting them.
   */
  const handleSubmit = async (
    values: Values,
    { setErrors }: FormikHelpers<Values>,
  ) => {
    // Abort submitting when editing an appointment and the values have not been changed by the user.
    if (isEditView && _.isEqual(initialValues, values)) {
      return setErrors({ submit: t('AppointmentForm.changeValues') });
    }

    try {
      // Clone values then parse them to usable post data for the createAppointment request.
      const data = { ...values };
      delete data.submit;
      data.endTime = format(new Date(data.endTime), 'HH:mm');
      data.startTime = format(new Date(data.startTime), 'HH:mm');
      // Wait for the request.
      if (isEditView) {
        await appointmentsActions.editAppointment({
          data: {
            ...data,
            id: appointment!.id,
            recurrenceIndex: editRecurrence
              ? null
              : appointment!.recurrenceIndex,
            startRecurrenceIndex: appointment!.recurrenceIndex,
          },
        });
      } else {
        const { data: response } = await appointmentsActions.createAppointment({
          data,
        });

        if (response.blockTimeConflicts) {
          response.blockTimeConflicts.map((blockTimeConflict) =>
            enqueueSnackbar(blockTimeConflict, {
              variant: 'warning',
            }),
          );
        }
      }

      // Show a success snackbar message.
      enqueueSnackbar(
        isEditView
          ? t('AppointmentForm.successEdit')
          : t('AppointmentForm.successCreate'),
        {
          variant: 'success',
        },
      );

      // Execute the afterSubmit callback. If no callback was provided, this redirects to the HomeView.
      afterSubmit();
    } catch (error: any) {
      if (error.response?.data) {
        const errorMessage = error.response.data.detail || error.response.data;

        setErrors({ submit: errorMessage });
      } else {
        setErrors({ submit: t('General.somethingWentWrong') });
      }
    }
  };

  /**
   * On mount check if the startTime is pre-filled and set the endTime accordingly.
   */

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => {
        const isRecurring = values.recurrenceType > RecurrenceType.ONCE;
        const showRecurrenceFields =
          values.recurrenceType > RecurrenceType.ONCE || editRecurrence;
        const formTopic = isEditView
          ? editRecurrence
            ? t('AppointmentForm.editRecurrence')
            : t('AppointmentForm.edit')
          : isRecurring
          ? t('AppointmentForm.createRecurrence')
          : t('AppointmentForm.create');

        return (
          <Form className={clsx(classes.root, className)} noValidate {...props}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">{formTopic}</Typography>
              </Grid>

              {!isEditView && (
                <Grid item xs={12}>
                  <Autocomplete
                    data-test-id={ComponentTestIds.customerIdField}
                    getOptionLabel={getAutoCompleteOptionLabel}
                    renderOption={renderAutocompleteOption}
                    ListboxComponent={VirtualListBox}
                    ListboxProps={{
                      // @ts-ignore
                      'data-test-id': ComponentTestIds.customerIdList,
                    }}
                    onChange={handleAutoCompleteChange}
                    options={customers}
                    renderInput={(params) => (
                      <TextField
                        error={!!(touched.customerId && errors.customerId)}
                        helperText={touched.customerId && errors.customerId}
                        label={t('AppointmentForm.Fields.CustomerId.label')}
                        name="customerId"
                        onBlur={handleBlur}
                        required
                        value={values.customerId}
                        variant="outlined"
                        {...params}
                        InputLabelProps={
                          params.InputLabelProps as
                            | Partial<InputLabelProps>
                            | undefined
                        }
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} data-test-id={ComponentTestIds.dateDayField}>
                <DatePicker
                  closeOnSelect
                  inputFormat="dd | MM | yyyy"
                  renderInput={(props) => (
                    <TextField
                      helperText={touched.dateDay && errors.dateDay}
                      variant="outlined"
                      name="dateDay"
                      fullWidth
                      required
                      {...props}
                      error={!!(touched.dateDay && errors.dateDay)}
                    />
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                  label={t('General.Form.dateLabel')}
                  onChange={(date) => handleDatePickerChange(date, 'dateDay')}
                  value={values.dateDay}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  data-test-id={ComponentTestIds.startTimeField}
                  error={!!(touched.startTime && errors.startTime)}
                  fullWidth
                  required
                  variant="outlined"
                >
                  <StartTimeSelect
                    error={touched.startTime ? errors.startTime : undefined}
                    value={values.startTime}
                    handleBlur={handleBlur}
                    handleOnChange={handleStartTimeChange}
                    handleOnFocus={(event) =>
                      handleSelectFocus(event, values.startTime)
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  data-test-id={ComponentTestIds.endTimeField}
                  error={!!(touched.endTime && errors.endTime)}
                  fullWidth
                  required
                  variant="outlined"
                >
                  <EndTimeSelect
                    error={touched.endTime ? errors.endTime : undefined}
                    startTime={
                      values.startTime ? new Date(values.startTime) : undefined
                    }
                    value={values.endTime}
                    handleBlur={handleBlur}
                    handleOnChange={handleChange}
                    handleOnFocus={(event) =>
                      handleSelectFocus(event, values.startTime)
                    }
                  />
                </FormControl>
              </Grid>

              {!isEditView && (
                <Grid item xs={12}>
                  <FormControlLabel
                    data-test-id={ComponentTestIds.recurrenceTypeToggle}
                    control={
                      <Switch
                        checked={values.recurrenceType > RecurrenceType.ONCE}
                        onChange={handleRecurrenceToggleChange}
                        name="recurrenceType"
                        color="primary"
                      />
                    }
                    label={t('AppointmentForm.Fields.recurringElementLabel')}
                    labelPlacement="start"
                  />
                </Grid>
              )}

              {showRecurrenceFields && (
                <Grid item xs={12}>
                  <RecurringElement
                    endDay={values.recurrenceEndDay}
                    endDayError={
                      touched.recurrenceEndDay && errors.recurrenceEndDay
                    }
                    error={!!(touched.recurrenceType && errors.recurrenceType)}
                    title={t('AppointmentForm.Fields.recurringElementLabel')}
                    value={values.recurrenceType}
                    handleDatePickerChange={(date) =>
                      handleDatePickerChange(date, 'recurrenceEndDay')
                    }
                    handleOnChange={handleChange}
                  />
                </Grid>
              )}
            </Grid>

            {errors.submit && (
              <Box mt={3}>
                <Alert
                  data-test-id={ComponentTestIds.submitError}
                  severity="error"
                >
                  {errors.submit}
                </Alert>
              </Box>
            )}

            <Box mt={2} display="flex" justifyContent="center">
              <Button
                color="secondary"
                data-test-id={ComponentTestIds.submitButton}
                disabled={isSubmitting}
                loading={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                {formTopic}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default observer(AppointmentForm);

import { FC, useEffect } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import {
  eachDayOfInterval,
  isSameDay,
  lastDayOfWeek,
  parseISO,
  startOfWeek,
} from 'date-fns';

import { TestIds } from 'src/testIds';
import { Appointment, BlockTime, WeekYear } from 'src/types';
import {
  getAllDayBlockTimeEntryAtDate,
} from 'src/utils';
import getFirstDateOfISOWeek from 'src/utils/getFirstDateOfISOWeek';
import AppointmentsState from 'src/services/state/Appointments';
import BlockTimesState from 'src/services/state/BlockTimes';

import WeeklyAgendaDayWrapper from '../WeeklyBanner/WeeklyAgendaDayWrapper';
import WeeklyAppointmentCard from '../WeeklyCard/WeeklyAppointmentCard';
import WeeklyBlockTimeCard from '../WeeklyCard/WeeklyBlockTimeCard';

import useStyles from './WeeklyAgenda.styles';

const ComponentTestIds = TestIds.components.weeklyAgenda;

export interface WeeklyAgendaProps extends BoxProps {
  selectedWeekYear: WeekYear;
}

export const WeeklyAgenda: FC<WeeklyAgendaProps> = ({
  selectedWeekYear,
  ...props
}) => {
  const classes = useStyles();
  const appointments = AppointmentsState.appointments;
  const allBlockTimes = [
    ...BlockTimesState.blockTimes.single,
    ...BlockTimesState.blockTimes.recurring,
  ];
  const firstDateInWeek = getFirstDateOfISOWeek(
    selectedWeekYear.week,
    selectedWeekYear.year,
  );
  const lastDateInWeek = lastDayOfWeek(firstDateInWeek, { weekStartsOn: 1 });
  const daysInWeek = eachDayOfInterval({
    end: lastDateInWeek,
    start: firstDateInWeek,
  });

  const fullWeek = eachDayOfInterval({
    end: lastDayOfWeek(lastDateInWeek, { weekStartsOn: 1 }),
    start: startOfWeek(firstDateInWeek, { weekStartsOn: 1 }),
  });

  const appointmentEntryPerDay = daysInWeek.map((date) => ({
    appointments: appointments.filter((appointment) =>
      isSameDay(date, parseISO(appointment.dateDay)),
    ),
    date: date,
  }));

  /*
    Additional Condition: Display no Blocktimes during Block Day
  */
  const blockTimeEntryPerDay = daysInWeek.map((date) => ({
    blockTimes: allBlockTimes
      .filter((blockTime) => isSameDay(date, parseISO(blockTime.dateDay)))
      .filter((blockTime) =>
        !!getAllDayBlockTimeEntryAtDate(date)
          ? false
          : !blockTime.allDay,
      ),
    date,
  }));

  const entriesPerDay = appointmentEntryPerDay.map(
    (appointmentEntry, index) => ({
      date: appointmentEntry.date,
      entries: [
        ...appointmentEntry.appointments,
        ...blockTimeEntryPerDay[index].blockTimes,
      ].sort((a, b) => parseInt(a.startTime) - parseInt(b.startTime)),
    }),
  );

  useEffect(() => {
    document
      .querySelector(
        `[data-scroll-anchor="${new Date().toLocaleString('de-DE', {
          weekday: 'short',
        })}"]`,
      )
      ?.scrollIntoView();
  }, [selectedWeekYear]);

  return (
    <Box
      className={classes.root}
      data-test-id={ComponentTestIds.wrapper}
      {...props}
    >
      <Box className={classes.container}>
        {fullWeek.map((date, index) => {
          const weekDayString = date.toLocaleString('de-DE', {
            weekday: 'short',
          });

          return (
            <Box
              key={`${date.toISOString()}-${index}`}
              className={classes.dayItem}
              data-test-id={ComponentTestIds.timeSteps}
              data-scroll-anchor={weekDayString}
            >
              <Typography variant="subtitle2">{weekDayString}</Typography>
              <span className={classes.divider} />

              <Box className={classes.wrapper}>
                <WeeklyAgendaDayWrapper date={date}>
                  {entriesPerDay[index].entries.map((entry) => {
                    if (entry.hasOwnProperty('customerId')) {
                      return (
                        <WeeklyAppointmentCard
                          key={`${entry.startTime}-${index}`}
                          appointment={entry as Appointment}
                        />
                      );
                    }

                    return (
                      <WeeklyBlockTimeCard
                        key={`${entry.startTime}-${index}`}
                        blockTime={entry as BlockTime}
                      />
                    );
                  })}
                </WeeklyAgendaDayWrapper>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

import type { FC } from 'react';
import React from 'react';
import { Error as ErrorIcon } from '@mui/icons-material';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { TextField as MuiTextField } from '@mui/material'; // eslint-disable-line no-restricted-imports
import clsx from 'clsx';

import useStyles from './TextField.styles';

// Has to be a type as an interface can only extend an object type or
// intersection of object types with statically known members.
export type TextFieldProps = MuiTextFieldProps & { placeholder?: string };

/**
 * This component is wrapped so that a placeholder is always displayed.
 * The placeholder is needed so that the styles from "theme/muiOverrides" can be applied to the class "MuiInputBase".
 */
export const TextField: FC<TextFieldProps> = ({
  className,
  error,
  helperText,
  placeholder,
  ...props
}) => {
  const classes = useStyles();
  const showErrorText = error && helperText;

  const ErrorText = () => (
    <>
      <ErrorIcon />
      {helperText}
    </>
  );

  return (
    <MuiTextField
      className={clsx(classes.root, className)}
      error={error}
      helperText={showErrorText ? <ErrorText /> : helperText}
      placeholder={placeholder ?? ' '}
      {...props}
    />
  );
};

export default TextField;

import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';
import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
      boxSizing: 'border-box',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    wrapper: {
      marginLeft: 32,
      backgroundColor: themeColors.white,
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    container: {
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      position: 'relative',
    },
    dayItem: {
      marginTop: 6,
      minHeight: 85,
      color: theme.palette.text.primary,
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginTop: -11,
      position: 'absolute',
      right: 5,
      left: 37,
    },
  }),
  { name: 'WeeklyAgenda' },
);

export default useStyles;

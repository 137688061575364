import { colors } from '@mui/material';

const typography = {
  fontFamily: 'Work Sans',
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontSize: 16,

  h1: {
    get fontWeight() {
      return typography.fontWeightMedium;
    },
    fontSize: 32,
  },

  h2: {
    get fontWeight() {
      return typography.fontWeightMedium;
    },
    fontSize: 22,
  },

  h3: {
    get fontWeight() {
      return typography.fontWeightMedium;
    },
    fontSize: 20,
  },

  h4: {
    get fontWeight() {
      return typography.fontWeightMedium;
    },
    fontSize: 18,
  },

  h5: {
    get fontWeight() {
      return typography.fontWeightMedium;
    },
    fontSize: 16,
  },

  h6: {
    get fontWeight() {
      return typography.fontWeightMedium;
    },
    fontSize: 14,
  },

  body1: {
    fontSize: 14,
  },

  body2: {
    fontSize: 16,
  },

  subtitle2: {
    get fontWeight() {
      return typography.fontWeightRegular;
    },
    fontSize: 14,
  },

  caption: {
    color: colors.grey[600],
    fontSize: 12,
  },

  overline: {
    get fontWeight() {
      return typography.fontWeightRegular;
    },
  },

  italic: {
    fontStyle: 'italic',
  },
};

export default typography;

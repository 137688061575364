import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      height: '100%', // Use 100% in combination with max-height to solve flex-box issues on Safari for iOS.
      maxHeight: theme.settings.bottomNavHeight,
    },

    actionButton: {
      color: theme.palette.common.white,

      '&.Mui-selected': {
        color: theme.palette.secondary.light,
      },
    },
  }),

  { name: 'BottomNav' },
);

export default useStyles;

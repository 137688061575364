import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    transparent: {
      backgroundColor: `${theme.palette.success.main}26`, // 15% opacity.
      color: theme.palette.success.main,
      border: `1px solid ${theme.palette.success.main}`,
    },

    transparentError: {
      backgroundColor: `${theme.palette.error.main}26`, // 15% opacity.
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
    },

    transparentInfo: {
      backgroundColor: `${theme.palette.info.main}26`, // 15% opacity.
      color: theme.palette.info.main,
      border: `1px solid ${theme.palette.info.main}`,
    },

    transparentSuccess: {
      backgroundColor: `${theme.palette.success.main}26`, // 15% opacity.
      color: theme.palette.success.main,
      border: `1px solid ${theme.palette.success.main}`,
    },

    transparentWarning: {
      backgroundColor: `${theme.palette.warning.main}26`, // 15% opacity.
      color: theme.palette.warning.main,
      border: `1px solid ${theme.palette.warning.main}`,
    },
  }),

  { name: 'Alert' },
);

export default useStyles;

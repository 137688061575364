/**
 * Authentication is done using ai-kit-authentication
 * https://gitlab.ambient-innovation.com/ai/ai.kit/authentication
 * A backend coupled standardized solution for handling authentication
 * and user management in react-django apps by Ambient Innovation
 */
import { configureAuthReactRouter, de } from 'ai-kit-auth';

import { API_AUTH_PATH, API_URL } from 'src/constants';
import type { User } from 'src/types';

export const { UserStore, useUserStore, makeAuthRoutes, ProtectedRoute } =
  configureAuthReactRouter<User>({
    api: {
      authPath: API_AUTH_PATH,
      url: API_URL,
    },
    defaultTranslator: de,
  });

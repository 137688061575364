import { makeStyles } from '@mui/styles';

import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& [class*="MuiSelect-root"]': {
        '& $durationLabel': {
          display: 'none',
        },
      },
    },

    durationLabel: {
      marginLeft: 'auto',
    },

    signatureFieldLabel: {
      color: theme.palette.text.primary,
      height: theme.spacing(10),
      border: `dashed 2px ${theme.palette.primary.main}`,
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      '&[class*="error"]': {
        borderColor: theme.palette.error.main,
      },
    },

    signaturePreview: {
      backgroundColor: theme.palette.ternary.light,
      height: theme.spacing(10),
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: 4,
      position: 'relative',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },

    signatureName: {
      position: 'absolute',
      top: theme.spacing(0.5),
      left: theme.spacing(0.5),
      color: theme.palette.primary.dark,
    },

    editIcon: {
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      position: 'absolute',
      cursor: 'pointer',
    },
  }),

  { name: 'AppointmentRecordForm' },
);

export default useStyles;

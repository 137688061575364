import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';
import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
      boxSizing: 'border-box',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    appointment: {
      justifyContent: 'space-between',
      color: themeColors.primary4,
      backgroundColor: themeColors.primary1,
      borderLeft: `3px solid ${themeColors.primary4}`,
    },
    appointmentDescription: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '15px',
    },
    appointmentRestricted: {
      opacity: 0.5,
      backgroundColor: themeColors.primary1,
      border: `1px dashed ${themeColors.primary4}`,
      borderLeft: `0px !important`,
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
      marginLeft: '0px',
    },
    card: {
      height: '34px',
      width: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '15px',
      padding: '8px 4px',
      borderRadius: '4px',
      marginBottom: '2px',
    },
    cardWrapperBlockDay: {
      backgroundColor: 'rgba(240, 243, 245, 0.5)',
      borderLeft: `3px solid ${themeColors.shade4}`,
      backgroundImage: `repeating-linear-gradient(-39deg, transparent, transparent 21px, rgba(220, 226, 229, 0.5) 21px, rgba(220, 226, 229, 0.5) 41px)`,
    },
    blockTime: {
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'rgba(240, 243, 245, 0.5)',
      borderLeft: `3px solid ${themeColors.shade4}`,
      backgroundImage: `repeating-linear-gradient(-39deg, transparent, transparent 21px, rgba(220, 226, 229, 0.5) 21px, rgba(220, 226, 229, 0.5) 41px)`,
    },
    blockTimeDescription: {
      display: 'flex',
      flexDirection: 'row',
      gap: '24px',
      alignItems: 'center',
    },
  }),
  { name: 'WeeklyCard' },
);

export default useStyles;

import type { ElementType, FC } from 'react';
import React, { Fragment, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import { setNotificationHasSeen } from 'src/services/state/Notification/actions';
import { getUnseenAndValidNotifications } from 'src/services/state/Notification/getters';
import DefaultLayout from 'src/layouts/DefaultLayout/DefaultLayout';
import StackLayout from 'src/layouts/StackLayout/StackLayout';
import AppointmentCreateView from 'src/views/AppointmentCreateView/AppointmentCreateView';
import AppointmentEditView from 'src/views/AppointmentEditView/AppointmentEditView';
import AppointmentListView from 'src/views/AppointmentListView/AppointmentListView';
import AppointmentRecordView from 'src/views/AppointmentRecordView/AppointmentRecordView';
import BlockTimeEditView from 'src/views/BlockTimeEditView/BlockTimeEditView';
import BlockTimesView from 'src/views/BlockTimesView/BlockTimesView';
import ForgotPasswordView from 'src/views/ForgotPasswordView/ForgotPasswordView';
import HomeView from 'src/views/HomeView/HomeView';
import LoginView from 'src/views/LoginView/LoginView';
import MonthlySummaryView from 'src/views/MonthlySummaryView/MonthlySummaryView';
import NotFoundView from 'src/views/NotFoundView/NotFoundView';
import ProfileView from 'src/views/ProfileView/ProfileView';
import ResetPasswordView from 'src/views/ResetPasswordView/ResetPasswordView';
import AuthGuard from 'src/components/AuthGuard/AuthGuard';
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';
import LoginGuard from 'src/components/LoginGuard/LoginGuard';

import { useConfirm } from '../../contexts/ConfirmContext';

/* eslint-disable sort-keys-fix/sort-keys-fix */
export interface RouteType {
  component?: ElementType;
  exact?: boolean;
  guard?: any;
  isStack?: boolean;
  layout?: ElementType;
  path: string;
  routes?: RoutesConfig;
}
export interface RoutesConfig {
  [key: string]: RouteType;
}

export interface RoutesProps {
  routesConfig?: RoutesConfig;
}

/**
 * Static route configuration.
 * The Switch component renders the FIRST Route with a matching path. Thus the order of routes in this
 * object matters. Routes with more specific paths should be defined before routes with less specific paths.
 */
export const routes = {
  login: {
    component: LoginView,
    exact: true,
    guard: LoginGuard,
    path: '/login',
  },

  forgotPassword: {
    component: ForgotPasswordView,
    exact: true,
    path: '/forgot-password',
  },

  resetPassword: {
    component: ResetPasswordView,
    exact: true,
    path: '/reset-password',
  },

  notFound: {
    component: NotFoundView,
    exact: true,
    path: '/404',
  },

  stack: {
    guard: AuthGuard,
    isStack: true,
    layout: StackLayout,
    path: '/stack',
    routes: {
      appointmentCreate: {
        component: AppointmentCreateView,
        exact: true,
        isStack: true,
        path: '/stack/appointment/create',
      },
      appointmentEdit: {
        component: AppointmentEditView,
        exact: true,
        isStack: true,
        path: '/stack/appointment/edit/:singleOrEvery/:id/:index?',
      },
      appointmentRecord: {
        component: AppointmentRecordView,
        exact: true,
        isStack: true,
        path: '/stack/appointment/record/:customerId/:id/:index?',
      },
      blockTimeEdit: {
        component: BlockTimeEditView,
        exact: true,
        isStack: true,
        path: '/stack/block-time/edit/:id/:index?',
      },
    },
  },

  app: {
    guard: AuthGuard,
    layout: DefaultLayout,
    path: '/',
    routes: {
      home: {
        component: HomeView,
        exact: true,
        path: '/home',
      },
      appointmentList: {
        component: AppointmentListView,
        exact: true,
        path: '/appointment-list',
      },
      blockTimes: {
        component: BlockTimesView,
        exact: true,
        path: '/block-times',
      },
      monthlySummary: {
        component: MonthlySummaryView,
        exact: true,
        path: '/monthly-summary',
      },
      profile: {
        component: ProfileView,
        exact: true,
        path: '/profile',
      },
    },
  },
};

/**
 * Parses routes config to add default index and fallback routes to nested routes.
 */
export const parseRoutesConfig = (_routes: RoutesConfig): RoutesConfig =>
  Object.entries(_routes).reduce((routesConfig, [routeName, route]) => {
    const hasNestedRoutes = !!route.routes;
    const nestedRoutesIndex = hasNestedRoutes
      ? route.routes![Object.keys(route.routes!)[0]].path
      : route.path;

    const indexRoute = {
      // For routes without an own component to render, redirect to the first nested route.
      component: route.component || (() => <Redirect to={nestedRoutesIndex} />),
      exact: true,
      path: route.path,
    };

    const fallbackRoute = {
      component: () => <Redirect to={routes.notFound.path} />,
      path: route.path,
    };

    if (hasNestedRoutes) {
      route.routes = {
        ...route.routes,
        index: indexRoute,
        fallback: fallbackRoute,
      };
    }

    return { ...routesConfig, [routeName]: route };
  }, {});

/**
 * Returns the Route matching with the provided path.
 */
export const getRouteByPath = (
  path: string,
  nestedRoutes?: Record<string, RouteType>,
) => {
  const _routes: Record<string, RouteType> = nestedRoutes || routes;
  let route: { routeName: string; routeObject: RouteType } | undefined;

  Object.entries(_routes).some(([routeName, _route]) => {
    const isMatchingRoute = matchPath(path, {
      exact: true,
      path: _route.path,
      strict: true,
    });

    if (isMatchingRoute) {
      route = { routeName, routeObject: _route };
      return true;
    }

    if (_route.routes) {
      route = getRouteByPath(path, _route.routes);
      return !!route;
    }

    return false;
  });

  return route;
};

/**
 * Renders all Routes within a Switch and takes care of "Stack"-Routes to be rendered inside a separate Switch
 * to make them globally available as Views on top of the current View.
 */
export const Routes: FC<RoutesProps> = ({ routesConfig }) => {
  const parsedRoutesConfig = parseRoutesConfig(routesConfig || routes);
  const location = useLocation<any>();
  const stackRoot = location.state && location.state.stackRoot;
  const newNotifications = getUnseenAndValidNotifications();
  const { t } = useTranslation('translation');
  const confirm = useConfirm();

  useEffect(() => {
    const openNotifications = async () => {
      for (const notification of newNotifications) {
        let fullUrl = notification.linkUrl || undefined;

        if (
          fullUrl &&
          !fullUrl.startsWith('http://') &&
          !fullUrl.startsWith('https://')
        ) {
          fullUrl = `https://${fullUrl}`;
        }

        let buttonDescription = notification.buttonTitle || t('General.ok');

        await confirm({
          description: notification.text,
          primaryText: buttonDescription,
          secondaryText: t('General.remindLater'),
          title: notification.title,
          onSecondary: { shouldResolve: false },
        })
          .then(() => {
            if (notification.linkUrl) {
              window.open(fullUrl, '_blank', 'noopener,noreferrer');
            }
            setNotificationHasSeen(notification.id);
          })
          .catch((error) => {
            if (error === 'onSecondary') return;
          });
      }
    };

    if (newNotifications.length > 0) {
      openNotifications();
    }
  }, []);

  return (
    <Suspense fallback={<LoadingScreen />}>
      {/* Regular App Routing */}
      <Switch location={stackRoot || location}>
        {Object.entries(parsedRoutesConfig).map(([routeName, route]) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const nestedRoutes = route.routes;

          if (route.isStack) return null;

          return (
            <Route
              exact={route.exact}
              key={routeName}
              path={route.path}
              render={(props: any) => (
                <Guard>
                  <Layout>
                    {!nestedRoutes && Component && <Component {...props} />}
                    {!!nestedRoutes && <Routes routesConfig={nestedRoutes} />}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>

      {/* Stack Routing */}
      <Switch location={location}>
        {stackRoot &&
          Object.entries(parsedRoutesConfig).map(([routeName, route]) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;
            const nestedRoutes = route.routes;

            if (!route.isStack) return null;

            return (
              <Route
                exact={route.exact}
                key={routeName}
                path={route.path}
                render={(props: any) => (
                  <Guard>
                    <Layout>
                      {!nestedRoutes && Component && <Component {...props} />}

                      {!!nestedRoutes && <Routes routesConfig={nestedRoutes} />}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
      </Switch>
    </Suspense>
  );
};

export default Routes;

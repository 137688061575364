import type { FC, HTMLAttributes } from 'react';
import React, {
  createContext,
  createRef,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import type { ListChildComponentProps } from 'react-window';
import { VariableSizeList as List } from 'react-window';
import { Box, useTheme } from '@mui/material';
import { isArray } from 'lodash';

import useStyles from './VirtualListBox.styles';

export interface ListItemProps extends ListChildComponentProps {
  setItemSize: (index: number, size?: number) => void;
}

const OuterElementContext = createContext({});
const OuterElement = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

/**
 * List item
 */
const Row: FC<ListItemProps> = ({ data, index, setItemSize, style }) => {
  const theme = useTheme();
  const rowRef = useRef<HTMLElement>();
  const listboxPadding = theme.spacing(1);
  const styleTop = style.top
    ? parseFloat(style.top as string) + parseFloat(listboxPadding)
    : listboxPadding;

  // Use the 'setItemSize' callback to set and cache the item`s individual height.
  useEffect(() => {
    setItemSize(index, rowRef.current?.getBoundingClientRect().height);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return React.cloneElement(data[index], {
    ref: rowRef,
    // Remove the default static height to be able to get and set the individual height of this ListItem.
    style: { ...style, height: undefined, top: styleTop },
  });
};

/**
 * VirtualListBox can be used as ListboxComponent for the Autocomplete component to drastically improve it's
 * performance when rendering a larger amount of options.
 */
export const VirtualListBox: FC<HTMLAttributes<HTMLElement>> = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>(({ children, ...props }, ref) => {
  const classes = useStyles();
  const sizeMapRef = useRef<Record<number, any>>({});
  const listRef = createRef<List>();
  const itemData = isArray(children) ? children : [children];
  const itemCount = itemData.length;
  const itemSize = 40;

  /**
   * Retrieve the individual item`s size.
   */
  const getItemSize = (index: number) => sizeMapRef.current[index] ?? itemSize;

  /**
   * Dynamically calculate the list`s height up to a maximum of 8 items.
   */
  const getListHeight = () => {
    if (itemCount > 8) return 8 * itemSize;

    return itemData
      .slice(0, 7)
      .map((_, index) => getItemSize(index))
      .reduce((a, b) => a + b, 0);
  };

  /**
   * A callback to store and cache the individual height of an item once it has been rendered.
   */
  const setItemSize: ListItemProps['setItemSize'] = useCallback(
    (index, size) => {
      sizeMapRef.current = { ...sizeMapRef.current, [index]: size };
      listRef.current?.resetAfterIndex(index);
    },
    [listRef],
  );

  return (
    // Use a react context here as the provided props of the parent component can neither be directly passed
    // to the 'OuterElement' component nor the 'outerElementType' property.
    <OuterElementContext.Provider value={props}>
      <Box className={classes.root} {...{ ref }}>
        <List
          height={getListHeight()}
          innerElementType="ul"
          itemCount={itemCount}
          itemData={itemData}
          itemSize={getItemSize}
          outerElementType={OuterElement}
          overscanCount={25}
          ref={listRef}
          width="100%"
        >
          {(itemProps) => <Row setItemSize={setItemSize} {...itemProps} />}
        </List>
      </Box>
    </OuterElementContext.Provider>
  );
});

export default VirtualListBox;

export enum AppointmentStatus {
  DEFAULT,
  HAS_CLEARANCE,
  HAS_RECORD,
  IS_DELAYED,
  UPLOAD_PENDING,
}

export enum RecurrenceType {
  ONCE = 1,
  WORKDAYS = 2,
  WEEKLY = 3,
  BIWEEKLY = 4,
  MONTHLY = 5,
}

export interface Appointment {
  customerId: number | string;
  dateDay: string;
  endTime: string;
  id: number;
  recurrenceEndDay: string;
  recurrenceIndex: number | null;
  recurrenceType: RecurrenceType;
  startTime: string;
  status: AppointmentStatus;
  customerSpecifics?: string;
}

export interface AppointmentPostData
  extends Omit<Appointment, 'id' | 'status'> {
  id?: number;
  status?: AppointmentStatus;
}

export interface AppointmentPatchData extends Omit<Appointment, 'status'> {
  status?: AppointmentStatus;
  startRecurrenceIndex: number | null;
}

import localforage from 'localforage';
import { action, runInAction } from 'mobx';

import type {
  CreateAppointmentProps,
  DeleteAppointmentProps,
  EditAppointmentProps,
} from 'src/services/api';
import axi from 'src/services/api';

import type { AppointmentsStateProps } from './state';
import AppointmentsState, { initialAppointmentsState } from './state';

/**
 * Initialize the AppointmentsState.
 * Always use local data first to populate the state and override it with server data if possible.
 */
export const initializeAppointmentsState = action(
  'initializeAppointmentsState',
  async () => {
    await fillWithLocalState();
    await fetchAppointments();

    runInAction(() => (AppointmentsState.initialized = true));
  },
);

/**
 * Fill with local state.
 */
export const fillWithLocalState = action('fillWithLocalState', async () => {
  try {
    const localAppointmentsState =
      await localforage.getItem<AppointmentsStateProps>('appointmentsState');

    if (!localAppointmentsState) return;

    runInAction(() => {
      Object.assign(AppointmentsState, localAppointmentsState);
    });
  } catch (error: any) {
    console.warn('could not retrieve local data ', error);
  }
});

/**
 * Fetch appointments.
 */
export const fetchAppointments = action('fetchAppointments', async () => {
  const { data: appointments } = await axi.getAppointments();

  runInAction(() => (AppointmentsState.appointments = appointments));
});

/**
 * Create appointment.
 */
export const createAppointment = action(
  'createAppointment',
  async (requestProps: CreateAppointmentProps) => {
    const response = await axi.postAppointment(requestProps);
    await fetchAppointments();

    return response;
  },
);

/**
 * Edit appointment.
 */
export const editAppointment = action(
  'editAppointment',
  async (requestProps: EditAppointmentProps) => {
    await axi.patchAppointment(requestProps);
    await fetchAppointments();
  },
);

/**
 * Delete appointment.
 */
export const deleteAppointment = action(
  'deleteAppointment',
  async (requestProps: DeleteAppointmentProps) => {
    await axi.deleteAppointment(requestProps);
    await fetchAppointments();
  },
);

/**
 * Resets appointments state.
 */
export const resetAppointmentsState = action('resetAppointments', () => {
  Object.assign(AppointmentsState, initialAppointmentsState);
  localforage.removeItem('appointmentsState');
});

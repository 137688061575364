import localforage from 'localforage';
import _ from 'lodash';
import { action, runInAction } from 'mobx';

import type { AppointmentRecordPostData } from 'src/types';
import type { CreateAppointmentRecordProps } from 'src/services/api';
import axi from 'src/services/api';
import { actions as appointmentsActions } from 'src/services/state/Appointments';

import type { AppointmentRecordsStateProps as ARsStateProps } from './state';
import ARsState, {
  initialAppointmentRecordsState as initialARsState,
} from './state';

/**
 * Initialize the AppointmentRecordsState.
 * Always use local data first to populate the state and override it with server data if possible.
 */
export const initializeAppointmentRecordsState = action(
  'initializeAppointmentRecordsState',
  async () => {
    await fillWithLocalState();

    runInAction(() => (ARsState.initialized = true));
  },
);

/**
 * Fill with local state.
 */
export const fillWithLocalState = action('fillWithLocalState', async () => {
  try {
    const localARsState = await localforage.getItem<ARsStateProps>(
      'appointmentRecordsState',
    );

    if (!localARsState) return;

    runInAction(() => {
      Object.assign(ARsState, localARsState);
    });
  } catch (error: any) {
    console.warn('could not retrieve local data ', error);
  }
});

/**
 * Create appointment record.
 * If the upload fails, the appointment record will be saved as appointmentRecordPending.
 * If the upload succeeds, potentially appointmentRecordPending will be removed
 * and the appointments will be re-fetched.
 */
export const createAppointmentRecord = action(
  'createAppointmentRecord',
  async (requestProps: CreateAppointmentRecordProps) => {
    await axi
      .postAppointmentRecord(requestProps)
      .then(() => {
        removeAppointmentRecordPending(requestProps.data);

        // Fetch updated appointments explicitly. The response is not mandatory so we do no wait for it or
        // take any specific actions on failure.
        appointmentsActions
          .fetchAppointments()
          .catch(() =>
            console.warn('Updated appointments could not be loaded.'),
          );
      })
      .catch((error) => {
        addOrReplaceAppointmentRecordPending(requestProps.data);

        throw error;
      });
  },
);

/**
 * Add or replace a pending appointment record. Uses the appointments 'appointmentId' and 'recurrenceIndex'
 * to determine if a pending appointment record already exists.
 */
export const addOrReplaceAppointmentRecordPending = action(
  'addOrReplaceAppointmentRecordPending',
  (appointmentRecordPending: AppointmentRecordPostData) => {
    const { appointmentId, recurrenceIndex } = appointmentRecordPending;

    // Find potentially pending AppointmentRecord by 'appointmentId' and 'recurrenceIndex'.
    const index = _.findIndex(ARsState.appointmentRecordsPending, {
      appointmentId,
      recurrenceIndex,
    });

    if (index > -1) {
      // Replace previous AppointmentRecord.
      ARsState.appointmentRecordsPending[index] = appointmentRecordPending;
    } else {
      // Add AppointmentRecord.
      ARsState.appointmentRecordsPending.push(appointmentRecordPending);
    }
  },
);

/**
 * Remove pending appointment record. Uses the appointments 'appointmentId' and 'recurrenceIndex'
 * to find the potentially pending appointment record.
 */
export const removeAppointmentRecordPending = action(
  'removeAppointmentRecordPending',
  (appointmentRecordPending: AppointmentRecordPostData) => {
    const { appointmentId, recurrenceIndex } = appointmentRecordPending;

    // Find potentially pending AppointmentRecord by 'appointmentId' and 'recurrenceIndex'.
    const index = _.findIndex(ARsState.appointmentRecordsPending, {
      appointmentId,
      recurrenceIndex,
    });

    // Remove the pending AppointmentRecord if index was found.
    if (index > -1) ARsState.appointmentRecordsPending.splice(index, 1);
  },
);

/**
 * Resets appointment records state.
 */
export const resetAppointmentRecordsState = action(
  'resetAppointmentRecords',
  () => {
    Object.assign(ARsState, initialARsState);
    localforage.removeItem('appointmentRecordsState');
  },
);

import localforage from 'localforage';
import { autorun, observable, toJS } from 'mobx';

import { Notification } from 'src/types';

export interface NotificationsStateProps {
  notifications: Notification[];
  initialized: boolean;
}

export const initialNotificationsState: NotificationsStateProps = {
  initialized: false,
  notifications: [],
};

const NotificationsState = observable<NotificationsStateProps>(
  initialNotificationsState,
);

autorun(() => {
  // ! Note: 'initialized' is set to 'false' after hot module reload in development environment.
  if (!NotificationsState.initialized) {
    return;
  }

  // Converts the observable-object to a javascript-object as mobx-observables can not be stored.
  const notificationsState = toJS(NotificationsState);

  localforage
    .setItem('notificationsState', notificationsState)
    .catch((error: any) => console.warn('could not be stored locally ', error));
});

export default NotificationsState;

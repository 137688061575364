import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';
import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    blockTimeIcon: {
      color: themeColors.shade4,
    },
    note: (isDailyCard) => ({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '8px 4px',
      marginBottom: isDailyCard ? 0 : theme.spacing(0.5),
    }),
    vacationIcon: {
      color: themeColors.shade4,
      marginRight: theme.spacing(2),
    },
    blockTimeNote: {
      gap: theme.spacing(2),
    },
    // VacationBanner styles
    pending: {
      borderLeft: `solid 3px ${themeColors.warning}`,
      backgroundColor: themeColors.warningBackground,
      color: themeColors.warningFont,
      '& .elapsed': {
        opacity: 0.5,
      },
    },
    approved: {
      backgroundColor: themeColors.successBackground,
      color: themeColors.successFont,
      borderLeft: `solid 3px ${themeColors.successFont}`,
      '& .elapsed': {
        backgroundColor: themeColors.shade1,
        color: themeColors.shade4,
        borderLeft: `solid 3px ${themeColors.shade4}`,
      },
    },
    taken: {
      borderLeft: `solid 3px ${themeColors.shade4}`,
    },
    expired: {
      // To only affect background opacity we need to convert
      // 'themeColors.warningBackground' to rgba with 0.4 opacity
      backgroundColor: 'rgba(255, 237, 190, 0.4)',
    },
    statusText: {
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
  }),
  { name: 'BannerNotes' },
);

export default useStyles;

import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import type { BoxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { isFriday } from 'date-fns';
import _ from 'lodash';

import { PAGE_TITLE } from 'src/constants';

import useStyles from './ThankYou.styles';

const lottieFiles = [
  '/static/lottie/confetti-01.json',
  '/static/lottie/confetti-02.json',
  '/static/lottie/confetti-03.json',
  '/static/lottie/confetti-04.json',
  '/static/lottie/confetti-05.json',
  '/static/lottie/confetti-06.json',
  '/static/lottie/doves.json',
  '/static/lottie/fireworks-01.json',
  '/static/lottie/fireworks-02.json',
  '/static/lottie/high-five.json',
  '/static/lottie/monkey.json',
  '/static/lottie/rocket.json',
  '/static/lottie/sunny-day.json',
];

interface ThankYouProps extends BoxProps {}

export const ThankYou: FC<ThankYouProps> = ({ className, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const today = new Date();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Box className={classes.playerContainer}>
        <Player
          autoplay
          className={classes.player}
          loop
          src={_.sample(lottieFiles) || ''}
        />
      </Box>

      <Box className={classes.typographyContainer}>
        <Typography align="center" className={classes.title} variant="h1">
          {t('ThankYou.topJob')}
          <br />
          {t('ThankYou.thankYouForYourService')}
        </Typography>

        <Box mb={5} />

        <Typography align="center" className={classes.bodyText} variant="body2">
          {isFriday(today)
            ? `${
                t('ThankYou.bodyTextBeginning') +
                PAGE_TITLE +
                t('ThankYou.bodyTextOnFriday')
              }`
            : `${
                t('ThankYou.bodyTextBeginning') +
                PAGE_TITLE +
                t('ThankYou.bodyText')
              }`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ThankYou;

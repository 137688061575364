import AppointmentRecordsState from './state';

/**
 * Get appointment record by its id.
 */
export const getAppointmentRecordById = (id: number) => {
  const appointmentRecords = AppointmentRecordsState.appointmentRecords;

  return appointmentRecords.find(
    (appointmentRecord) => appointmentRecord.id === id,
  );
};

/**
 * Get appointment record by its appointmentId.
 */
export const getAppointmentRecordByAppointmentId = (appointmentId: number) => {
  const appointmentRecords = AppointmentRecordsState.appointmentRecords;

  return appointmentRecords.find(
    (appointmentRecord) => appointmentRecord.appointmentId === appointmentId,
  );
};

/**
 * Get pending appointment record by its 'appointmentId' and 'recurrenceIndex'.
 */
export const getAppointmentRecordPending = (
  appointmentId: number,
  recurrenceIndex: number | null,
) => {
  const appointmentRecordsPending =
    AppointmentRecordsState.appointmentRecordsPending;

  return appointmentRecordsPending.find(
    (appointmentRecordPending) =>
      appointmentRecordPending.appointmentId === appointmentId &&
      appointmentRecordPending.recurrenceIndex === recurrenceIndex,
  );
};

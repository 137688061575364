import BlockTimesState from './state';

/**
 * Get Block Time by Id.
 */
export const getBlockTime = (blockTimeId: string) => {
  const singleBlockTimes = BlockTimesState.blockTimes.single;
  const recurringBlockTimes = BlockTimesState.blockTimes.recurring;
  const allBlockTimes = [...singleBlockTimes, ...recurringBlockTimes];

  return allBlockTimes.find(
    (blockTime) => blockTime.id?.toString() === blockTimeId,
  );
};

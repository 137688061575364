import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Collapse,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import { ENVIRONMENT, IMPRINT, PAGE_TITLE, PRIVACY } from 'src/constants';
import { TestIds } from 'src/testIds';
import openInNewTab from 'src/utils/openInNewTab';
import withHttps from 'src/utils/withHttps';
import LoginForm from 'src/components/LoginForm/LoginForm';
import Page from 'src/components/Page/Page';
import { FeeLogoWhite, LogoWhite } from 'src/components/Svg';

import useStyles from './LoginView.styles';

const ViewTestIds = TestIds.views.login;

const LoginView: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showWelcomeText, setShowWelcomeText] = useState(true);

  return (
    <Page
      className={classes.root}
      data-test-id={ViewTestIds.pageWrapper}
      title={t('Views.Login.title')}
    >
      <Container maxWidth="sm">
        <Grid container>
          <Grid item container justifyContent="center" xs={12}>
            {ENVIRONMENT.startsWith('AFH') ? (
              <LogoWhite width="70" />
            ) : (
              <FeeLogoWhite width="140" />
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography align="center" gutterBottom variant="h1">
              {t('Views.Login.title')}
            </Typography>

            <Collapse in={showWelcomeText}>
              <Typography align="center" variant="subtitle2">
                {PAGE_TITLE === 'JP Servicefee'
                  ? t('Views.Login.welcomeTextJPS')
                  : t('Views.Login.welcomeTextAFH')}
              </Typography>
            </Collapse>
          </Grid>
        </Grid>

        <Box mt={3}>
          <LoginForm onClick={() => setShowWelcomeText(false)} />
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container justifyContent="space-around">
            <Grid item xs={6}>
              <Link
                onClick={() => openInNewTab(withHttps(IMPRINT))}
                color="inherit"
                style={{ cursor: 'pointer' }}
                variant="body1"
              >
                {t('Views.Login.imprint')}
              </Link>
            </Grid>
            <Grid item xs={2}>
              <Link
                onClick={() => openInNewTab(withHttps(PRIVACY))}
                color="inherit"
                style={{ cursor: 'pointer' }}
                variant="body1"
              >
                {t('Views.Login.dataPrivacy')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default LoginView;

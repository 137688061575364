import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useConfirm } from 'src/contexts/ConfirmContext';
import { useTopBar } from 'src/contexts/TopBarContext';
import { routes } from 'src/services/routing';
import { deleteBlockTime } from 'src/services/state/BlockTimes/actions';
import { getBlockTime } from 'src/services/state/BlockTimes/getters';
import BlockTimeForm from 'src/components/BlockTimeForm/BlockTimeForm';
import Page from 'src/components/Page/Page';

import useStyles from './BlockTimeEditView.styles';

const BlockTimeEditView: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{
    id: string;
  }>();
  const classes = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const topBar = useTopBar();
  const blockTime = getBlockTime(id);
  const recurrenceIndex = blockTime ? blockTime.recurrenceIndex : null;

  /**
   * Deletes Current Block Time
   */
  const deleteCurrentBlockTime = async () => {
    if (!blockTime) return;
    try {
      await confirm({
        description: t('Views.BlockTimes.Edit.deleteDescription'),
        primaryText: t('General.delete'),
        title: t('Views.BlockTimes.Edit.deleteTitle'),
      });

      await deleteBlockTime({ blockTimeId: id, recurrenceIndex });

      // Show a success snackbar message.
      enqueueSnackbar(t('Views.BlockTimes.Edit.deleteSuccessMessageSingle'), {
        variant: 'success',
      });

      // Redirect to BlockTimeView on success. Do not use 'topBar.handleBack' to avoid
      // being redirected to an BlockTimeEditView of the just deleted block time.
      history.push(routes.app.routes.blockTimes.path);
    } catch (error: any) {
      if (error === 'onClose' || error === 'onSecondary') return;

      if (error.response?.data) {
        const errorMessage = error.response.data.detail || error.response.data;

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('General.somethingWentWrong'), {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    topBar.setTitle(t('Views.BlockTimes.title'));
    topBar.setActionIcon(<DeleteOutlineIcon color="error" />);
    topBar.setHandleAction(() => deleteCurrentBlockTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title={t('Views.BlockTimes.title')}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <BlockTimeForm
            afterSubmit={topBar.handleBack}
            blockTime={blockTime}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default BlockTimeEditView;

import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useUserStore } from 'src/services/auth/auth';
import { actions as appointmentRecordsActions } from 'src/services/state/AppointmentRecords';
import { actions as appointmentsActions } from 'src/services/state/Appointments';
import { actions as blockTimeActions } from 'src/services/state/BlockTimes';
import { actions as customersActions } from 'src/services/state/Customers';
import { actions as monthlySummariesActions } from 'src/services/state/MonthlySummaries';
import UserState, { actions as userActions } from 'src/services/state/User';
import { actions as notificationActions } from 'src/services/state/Notification';
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';

interface StateInitProps {
  children?: ReactNode;
}

/**
 * Shows nested views only if the state has been initialized.
 */
const StateInit: FC<StateInitProps> = ({ children }) => {
  const {
    axiosRequestConfig: authConfig,
    csrf,
    loading: authLoading,
    loggedIn: authLoggedIn,
    user: authUser,
  } = useUserStore();
  const [loading, setLoading] = useState(false);
  const noResponse = !csrf;
  const userStateProps = { authConfig, user: authUser };

  // Update the UserState on every auth-request and initialize the remaining states if the "loggedIn" state
  // changed from "false" to "true" or reset the remaining states vice versa.
  // !Note: The local state will be unaffected if "noResponse" is "true" as the actual state could not be verified.
  useEffect(() => {
    if (authLoading || noResponse) return;

    const wasLoggedInBefore = !!UserState.user;
    const isLoggedInNow = authLoggedIn;

    userActions.setUserState(userStateProps);

    if (!wasLoggedInBefore && isLoggedInNow) {
      appointmentRecordsActions.initializeAppointmentRecordsState();
      appointmentsActions.initializeAppointmentsState();
      blockTimeActions.initializeBlockTimesState();
      customersActions.initializeCustomersState();
      monthlySummariesActions.initializeMonthlySummariesState();
      notificationActions.initializeNotificationsState();
    } else if (!isLoggedInNow) {
      appointmentRecordsActions.resetAppointmentRecordsState();
      appointmentsActions.resetAppointmentsState();
      blockTimeActions.resetBlockTimesState();
      customersActions.resetCustomersState();
      monthlySummariesActions.resetMonthlySummariesState();
      notificationActions.resetNotificationsState();
    }
  }, [authLoading, authUser]); // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize UserState on mount and use locally stored user information to determine
  // if the user was logged in most recently and if the remaining states should be initialized as well.
  // This will restore the app's last state even when not connected to the server.
  useEffect(() => {
    const initState = async () => {
      await userActions.initializeUserState();

      if (!UserState.user) return;

      try {
        setLoading(true);

        await Promise.allSettled([
          appointmentRecordsActions.initializeAppointmentRecordsState(),
          appointmentsActions.initializeAppointmentsState(),
          blockTimeActions.initializeBlockTimesState(),
          customersActions.initializeCustomersState(),
          monthlySummariesActions.initializeMonthlySummariesState(),
          notificationActions.initializeNotificationsState(),
        ]);
      } catch (error: any) {
        console.warn('State could not be initialized properly.');
      } finally {
        setLoading(false);
      }
    };

    initState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!UserState.initialized || loading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

export default observer(StateInit);

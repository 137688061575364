import localforage from 'localforage';
import { action, runInAction } from 'mobx';

import { Notification } from 'src/types';
import axi from 'src/services/api';

import type { NotificationsStateProps } from './state';
import NotificationsState, { initialNotificationsState } from './state';

/**
 * Initialize the NotificationsState.
 * Always use local data first to populate the state and override it with server data if possible.
 */
export const initializeNotificationsState = action(
  'initializeNotificationsState',
  async () => {
    await fillWithLocalState();
    await fetchNotifications();

    runInAction(() => (NotificationsState.initialized = true));
  },
);

/**
 * Fill with local state.
 */
export const fillWithLocalState = action('fillWithLocalState', async () => {
  try {
    const localNotificationsState =
      await localforage.getItem<NotificationsStateProps>('notificationsState');

    if (!localNotificationsState) return;

    runInAction(() => {
      Object.assign(NotificationsState, localNotificationsState);
    });
  } catch (error: any) {
    console.warn('could not retrieve local data ', error);
  }
});

/**
 * Fetch Notifications.
 */
export const fetchNotifications = action('fetchNotifications', async () => {
  const { data: notifications } = await axi.getNotifications();
  const existingNotifications: Notification[] = [
    ...NotificationsState.notifications,
  ];
  for (const notification of notifications) {
    const existingIndex = existingNotifications.findIndex(
      (item) => item.id === notification.id,
    );
    if (existingIndex >= 0) {
      // An existing item with the same ID was found
      if (
        existingNotifications[existingIndex].lastmodifiedAt !==
        notification.lastmodifiedAt
      ) {
        notification.hasSeen = false;
        // If 'lastModifiedAt' is different, replace the existing item with the new one
        existingNotifications.splice(existingIndex, 1, notification);
      }
    } else {
      notification.hasSeen = false;
      // No item with the same ID, push the new notification
      existingNotifications.push(notification);
    }
  }
  runInAction(() => (NotificationsState.notifications = existingNotifications));
});

/**
 * Mark a notification as seen.
 */
export const setNotificationHasSeen = action(
  'setNotificationHasSeen',
  async (id: number) => {
    const index = NotificationsState.notifications.findIndex(
      (n) => n.id === id,
    );
    if (index !== -1) {
      runInAction(() => {
        NotificationsState.notifications[index].hasSeen = true;
      });

      // update local storage with the new state
      await localforage.setItem('notificationsState', NotificationsState);
    }
  },
);
/**
 * Resets Notifications state.
 */
export const resetNotificationsState = action('resetNotifications', () => {
  Object.assign(NotificationsState, initialNotificationsState);
  localforage.removeItem('notificationsState');
});

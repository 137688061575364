import type { FC } from 'react';
import React, { useRef } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type {
  SnackbarAction,
  SnackbarOrigin,
  SnackbarProviderProps,
} from 'notistack';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

import { TestIds } from 'src/testIds';
import type { Theme } from 'src/theme/types';

const ComponentTestIds = TestIds.components.snackBar;

const useStyles = makeStyles(
  (theme: Theme) => ({
    error: {
      backgroundColor: theme.palette.error.light,
      border: `1px solid ${theme.palette.error.dark}`,
      color: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.info.light,
      border: `1px solid ${theme.palette.info.dark}`,
      color: theme.palette.info.dark,
    },
    root: {
      // .SnackbarItem-contentRoot
      '& > div': {
        // .SnackbarItem-message
        '& > div:nth-child(1)': {
          flexBasis: 0,
          flexGrow: 1,
        },
        // .SnackbarItem-action
        '& > div:nth-child(2)': {
          margin: 0,
          padding: 0,
        },
        // .SnackbarItem-action > button
        '& > div:nth-child(2) > button': {
          color: 'inherit',
        },
        'fontFamily': 'Work Sans',
        'padding': theme.spacing(1),
      },
    },
    success: {
      backgroundColor: theme.palette.success.light,
      border: `1px solid ${theme.palette.success.dark}`,
      color: theme.palette.success.dark,
    },
    warning: {
      backgroundColor: theme.palette.warning.light,
      border: `1px solid ${theme.palette.warning.dark}`,
      color: theme.palette.warning.dark,
    },
  }),
  { name: 'Snackbar' },
);

export const SnackbarProvider: FC<SnackbarProviderProps> = ({
  children,
  ...props
}) => {
  const styles = useStyles();
  const notistackRef = useRef<any>();

  const classes = {
    root: styles.root,
    variantError: styles.error,
    variantInfo: styles.info,
    variantSuccess: styles.success,
    variantWarning: styles.warning,
  };

  const anchorOrigin: SnackbarOrigin = {
    horizontal: 'center',
    vertical: 'top',
  };

  const closeButton: SnackbarAction = (key) => (
    <IconButton
      onClick={() => notistackRef.current?.closeSnackbar(key)}
      size="small"
      data-test-id={ComponentTestIds.closeButton}
    >
      <CloseIcon />
    </IconButton>
  );

  return (
    // @ts-ignore
    <NotistackSnackbarProvider
      action={closeButton}
      anchorOrigin={anchorOrigin}
      classes={classes}
      ref={notistackRef}
      {...props}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;

import * as api from './api';
import axiosInstance from './axios';
import axiosMock from './axiosMock';

export type {
  CreateAppointmentProps,
  CreateAppointmentRecordProps,
  DeleteAppointmentProps,
  EditAppointmentProps,
  GetAppointmentsProps,
  GetCustomersProps,
  GetMonthlySummariesProps,
} from './api';

export { axiosInstance, axiosMock };

export default api;

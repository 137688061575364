import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary,
      height: '100%',
      minHeight: 35,
      padding: theme.spacing(1),
      borderBottomRightRadius: 10,
      borderTopRightRadius: 10,
      borderColor: theme.palette.text.secondary,
      borderLeft: '3px solid',
      position: 'relative',
    },
  }),
  { name: 'CardWrapper' },
);

export default useStyles;

import { eachDayOfInterval, lastDayOfWeek } from 'date-fns';

import getFirstDateOfISOWeek from './getFirstDateOfISOWeek';
import { hasBlockTimeEntryAtDate } from './hasBlockTimeEntryAtDate';

const hasSingleBlockTimEntriesInWeek = (week: number, year: number) => {
  const firstDateInWeek = getFirstDateOfISOWeek(week, year);
  const lastDateInWeek = lastDayOfWeek(firstDateInWeek);
  const daysInWeek = eachDayOfInterval({
    end: lastDateInWeek,
    start: firstDateInWeek,
  });

  return daysInWeek.some((date: Date) =>
    hasBlockTimeEntryAtDate(date, 'recurring'),
  );
};
const hasRecurringBlockTimeEntriesInWeek = (week: number, year: number) => {
  const firstDateInWeek = getFirstDateOfISOWeek(week, year);
  const lastDateInWeek = lastDayOfWeek(firstDateInWeek);
  const daysInWeek = eachDayOfInterval({
    end: lastDateInWeek,
    start: firstDateInWeek,
  });

  return daysInWeek.some((date: Date) =>
    hasBlockTimeEntryAtDate(date, 'single'),
  );
};

export { hasSingleBlockTimEntriesInWeek, hasRecurringBlockTimeEntriesInWeek };

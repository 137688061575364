import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'row-reverse',
      borderRightRadius: '4px',
      width: '100%',
      height: '100%',
      marginLeft: 4,
      marginTop: 11,
    },
    allDayStyle: {
      marginTop: -2,
      gridArea: '1 / 2 / span 97',
    },
    blockTimeStyle: {
      backgroundColor: 'rgba(240, 243, 245, 0.5)',
      borderLeft: `solid 3px ${themeColors.shade4}`,
      backgroundImage: `repeating-linear-gradient(-39deg, transparent, transparent 21px, rgba(220, 226, 229, 0.5) 21px, rgba(220, 226, 229, 0.5) 41px)`,
    },
    icon: {
      marginTop: '8px',
      marginRight: '8px',
      color: themeColors.primary4,
      height: '16px',
      width: '16px',
    },
  }),
  { name: 'CalendarCard' },
);

export default useStyles;

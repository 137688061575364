import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      '& [class*="MuiSelect-root"]': {
        '& $durationLabel': {
          display: 'none',
        },
      },
    },
    durationLabel: {
      marginLeft: 'auto',
    },
    radioLabel: {
      display: 'inline-flex',
      verticalAlign: 'top',
      alignItems: 'center',
    },
  }),
  { name: 'BlockTimeForm' },
);

export default useStyles;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    radioLabel: {
      display: 'inline-flex',
      verticalAlign: 'top',
      alignItems: 'center',
    },
  }),
  { name: 'RecurringElement' },
);

export default useStyles;

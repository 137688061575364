import axios from 'axios';

import { API_URL } from 'src/constants';
import { toSnakeCase } from 'src/utils';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: { 'content-type': 'application/json' },
  timeout: 1000 * 15,
});

// Add a request interceptor and transform POST request URL-Params to snake_case.
axiosInstance.interceptors.request.use(
  (config) => {
    config.params = toSnakeCase(config.params);

    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;

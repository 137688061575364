import type { ChangeEvent, FC, MouseEventHandler } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouter } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Link } from '@mui/material';
import clsx from 'clsx';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { TestIds } from 'src/testIds';
import { useUserStore } from 'src/services/auth/auth';
import { routes } from 'src/services/routing';
import Alert from 'src/components/Alert/Alert';
import Button from 'src/components/Button/Button';
import Form from 'src/components/Form/Form';
import TextField from 'src/components/TextField/TextField';

import useStyles from './LoginForm.styles';

interface LoginFormProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLFormElement>;
}

interface Values {
  email: string;
  password: string;
  submit: string;
}

const ComponentTestIds = TestIds.components.loginForm;

export const LoginForm: FC<LoginFormProps> = ({ className, ...props }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useUserStore();
  const { t } = useTranslation();

  const initialValues: Values = {
    email: '',
    password: '',
    submit: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .max(255)
      .email(t('LoginForm.Fields.Email.invalid'))
      .required(t('LoginForm.Fields.Email.required')),
    password: Yup.string()
      .max(255)
      .required(t('LoginForm.Fields.Password.required')),
  });

  /**
   * Sanitize value string before calling formik's change handler.
   */
  const handleEmailChange = (
    event: ChangeEvent<any>,
    callback: (event: ChangeEvent<any>) => void,
  ) => {
    // Remove whitespace from value string.
    event.target.value = event.target.value.replace(/ /g, '');

    callback(event);
  };

  /**
   * Handle form submit.
   */
  const handleSubmit = async (
    values: Values,
    { setErrors }: FormikHelpers<Values>,
  ) => {
    try {
      await login(values.email, values.password);
    } catch (error: any) {
      if (error.response) {
        setErrors({ submit: t('LoginForm.wrongCredentials') });
      } else {
        setErrors({ submit: t('General.somethingWentWrong') });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form
          className={clsx(classes.root, className)}
          invertColor
          noValidate
          {...props}
        >
          <TextField
            data-test-id={ComponentTestIds.identityField}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={t('LoginForm.Fields.Email.label')}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={(event) => handleEmailChange(event, handleChange)}
            type="text"
            value={values.email}
            variant="outlined"
          />

          <TextField
            data-test-id={ComponentTestIds.passwordField}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t('LoginForm.Fields.Password.label')}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setShowPassword(!showPassword)}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Link
            color="textPrimary"
            component={ReactRouter}
            to={routes.forgotPassword.path}
            underline="always"
            variant="subtitle2"
          >
            {t('LoginForm.forgotPassword')}
          </Link>

          {errors.submit && (
            <Box mt={3}>
              <Alert
                data-test-id={ComponentTestIds.submitError}
                severity="error"
                variant="transparent"
              >
                {errors.submit}
              </Alert>
            </Box>
          )}

          <Box mt={2}>
            <Button
              color="secondary"
              data-test-id={ComponentTestIds.loginButton}
              disabled={isSubmitting}
              fullWidth
              loading={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              {t('LoginForm.login')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;

import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import { getters as appointmentStateGetters } from 'src/services/state/Appointments';
import AppointmentCard from 'src/components/AppointmentCard/AppointmentCard';
import Page from 'src/components/Page/Page';

import useStyles from './AppointmentListView.styles';

const ViewTestIds = TestIds.views.appointmentList;

const AppointmentListView: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const topBar = useTopBar();
  const appointments = appointmentStateGetters.getSortedAppointments();

  useEffect(() => topBar.setTitle(t('Views.AppointmentList.title')));

  return (
    <Page
      className={classes.root}
      data-test-id={ViewTestIds.pageWrapper}
      title={t('Views.AppointmentList.title')}
    >
      <Grid container justifyContent="center" spacing={2}>
        {appointments.map((appointment) => (
          <Grid
            item
            key={`${appointment.id}${appointment.recurrenceIndex}`}
            xs={12}
          >
            <AppointmentCard appointment={appointment} showAppointmentDetails />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default observer(AppointmentListView);

import { eachMinuteOfInterval, format } from 'date-fns';

import { TimeStep } from 'src/types';

const getTimeSteps = (type?: 'start' | 'end') => {
  const timeSteps = eachMinuteOfInterval(
    {
      end: new Date().setHours(23, 45, 0, 0),
      start: new Date().setHours(0, 0, 0, 0),
    },
    { step: 15 },
  ).map((timeStep): TimeStep => {
    const timeString = format(timeStep, 'HH:mm');
    const dateString = timeStep.toString();

    return {
      dateObject: timeStep,
      dateString,
      timeString,
    };
  });

  return timeSteps;
};

export default getTimeSteps;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { differenceInMinutes, parseISO } from 'date-fns';
import { toJS } from 'mobx';

import { TestIds } from 'src/testIds';
import { Appointment } from 'src/types';
import { getAllDayBlockTimeEntryAtDate } from 'src/utils/hasBlockTimeEntryAtDate';
import { getCustomerById } from 'src/services/state/Customers/getters';

import { CardWrapper } from '../CardWrapper/CardWrapper';

import useStyles from './WeeklyCard.styles';

interface Props {
  appointment: Appointment;
}

const WeeklyAppointmentCard: FC<Props> = ({ appointment }) => {
  const classes = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'Views.Home',
  });
  const date = new Date(appointment.dateDay);
  const isWithinBlockDay = getAllDayBlockTimeEntryAtDate(date);

  const eventDuration =
    differenceInMinutes(
      parseISO(`${appointment.dateDay}T${appointment.endTime}`),
      parseISO(`${appointment.dateDay}T${appointment.startTime}`),
    ) / 60;

  const firstName = toJS(getCustomerById(appointment.customerId))?.firstName;
  const lastName = toJS(getCustomerById(appointment.customerId))?.lastName;
  const startTime = appointment.startTime.slice(0, -3);

  return (
    <CardWrapper
      data-test-appointment-id={appointment.id}
      data-test-id={TestIds.components.appointmentCard.card}
      className={clsx(
        classes.card,
        classes.appointment,
        isWithinBlockDay
          ? classes.appointmentRestricted
          : null,
      )}
    >
      <Box className={clsx(classes.appointmentDescription)}>
        <AccessAlarmIcon fontSize="inherit" />
        <Typography variant="h6">
          {t('appointmentCardWeekView', {
            firstName: firstName,
            lastName: lastName,
            start: startTime,
          })}
        </Typography>
      </Box>
      <Typography variant="subtitle2">
        {t('appointmentCardWeekTime', {
          time: eventDuration,
        })}
      </Typography>
    </CardWrapper>
  );
};

export default WeeklyAppointmentCard;

import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import AppointmentForm from 'src/components/AppointmentForm/AppointmentForm';
import Page from 'src/components/Page/Page';

import useStyles from './AppointmentCreateView.styles';

const ViewTestIds = TestIds.views.appointmentCreate;

const AppointmentCreateView: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const topBar = useTopBar();

  return (
    <Page
      className={classes.root}
      data-test-id={ViewTestIds.pageWrapper}
      title={t('Views.AppointmentCreate.title')}
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <AppointmentForm afterSubmit={topBar.handleBack} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default AppointmentCreateView;

import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

interface TopBarHeightProps {
  topBarHeight: number | undefined;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    contentWrapper: ({ topBarHeight }: TopBarHeightProps) => ({
      marginTop: `${topBarHeight || theme.settings.topBarHeight}px`,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    }),

    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }),

  { name: 'StackLayout' },
);

export default useStyles;

import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';
import { Theme } from 'src/theme/types';

const dotSize = 6;

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    currentDay: {
      backgroundColor: theme.palette.ternary.main,
    },
    appointmentColor: {
      backgroundColor: theme.palette.secondary.light,
    },
    dot: {
      borderRadius: '50%',
      display: 'block',
      height: dotSize,
      margin: 'auto',
      width: dotSize,
      flex: 'none',
      marginBottom: '6px',
    },
    muiIndicator: {
      backgroundColor: themeColors.primary2,
      height: '100%',
      borderRadius: 25,
      zIndex: -1,
    },
    muiSelected: {
      backgroundColor: 'transparent',
      color: themeColors.shade4,
      transition: 'all 0.2s 0.2s',
    },
    muiTabRoot: {
      borderRadius: 25,
      minHeight: 66,
      maxHeight: 66,
      minWidth: 42,
      maxWidth: 42,
      margin: `0 ${theme.spacing(1)}`,
      transition: 'all 0.1s 0.1s',
    },
    placeholderDot: {
      display: 'block',
      height: dotSize,
      margin: 'auto',
      width: dotSize,
      flex: 'none',
    },
    absenceColor: {
      backgroundColor: themeColors.primary4,
    },
  }),
  { name: 'TimeIntervalSlider' },
);

export default useStyles;

import { FC } from 'react';
import { Alert, Button, Snackbar } from '@mui/material';
import clsx from 'clsx';

import useStyles from './UpdateNotification.styles';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

export const UpdateNotification: FC<Props> = ({ isOpen, onClick }) => {
  const classes = useStyles();

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Alert className={classes.alert} severity="warning">
        <span className={clsx(classes.font, classes.margin)}>
          Um alle Funktionen der App nutzen zu können, muss die App neu geladen
          werden.
        </span>
        <Button className={classes.font} variant="outlined" onClick={onClick}>
          Neu Laden
        </Button>
      </Alert>
    </Snackbar>
  );
};

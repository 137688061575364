import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';
import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(0),
      position: 'relative',
    },
    appointmentCard: {
      marginTop: 11,
      marginLeft: 4,
    },
    bannerWrapper: {
      position: 'sticky',
      left: 0,
      right: 0,
      top: 0,
      marginLeft: 46.75,
      backgroundColor: themeColors.white,
      zIndex: 100,
    },
    banner: {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    blockTime: {
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'rgba(240, 243, 245, 0.5)',
      borderLeft: `solid 3px ${themeColors.shade4}`,
      backgroundImage: `repeating-linear-gradient(-39deg, transparent, transparent 21px, rgba(220, 226, 229, 0.5) 21px, rgba(220, 226, 229, 0.5) 41px)`,
    },
    blockTimeBanner: {
      borderLeft: `solid 3px ${themeColors.shade4}`,
      backgroundColor: themeColors.shade2,
    },
    blockTimeDescription: {
      display: 'flex',
      flexDirection: 'row',
      gap: '24px',
      alignItems: 'center',
    },
    blockTimeOverlappingMargin: {
      marginRight: theme.spacing(4),
    },
    card: {
      height: '34px',
      width: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '15px',
      padding: '8px 4px',
      backgroundColor: 'red',
      borderRadius: '4px',
      marginBottom: '2px',
      marginLeft: 0,
    },
    currentTime: {
      borderTop: `1px dashed ${theme.palette.secondary.light}`,
      marginLeft: theme.spacing(0.5),
      position: 'relative',
      zIndex: 100,

      '&:after': {
        backgroundColor: theme.palette.secondary.main,
        content: '""',
        width: 5,
        height: 5,
        border: `2px solid ${theme.palette.secondary.light}`,
        borderRadius: '50%',
        marginTop: -5,
        marginLeft: -2,
        position: 'absolute',
      },
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginTop: -11,
      position: 'absolute',
      right: 5,
      left: '3em',
    },
    gridRows: {
      display: 'grid',
      gridAutoRows: 20,
      gridTemplateColumns: 'auto 1fr',
      width: 'auto',
    },
    gridRowsContainer: {
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      position: 'relative',
    },
    hoursItem: {
      gridColumn: '1 / span 1',
      color: theme.palette.text.primary,
    },
    imperceptibleAppointments: {
      color: themeColors.shade4,
      backgroundColor: themeColors.shade2,
      border: `1px dashed ${themeColors.shade4}`,
      borderLeft: `3px solid ${themeColors.shade4}`,
    },
  }),
  { name: 'DailyAgenda' },
);

export default useStyles;

import localforage from 'localforage';
import { autorun, observable, toJS } from 'mobx';

import { Appointment } from 'src/types';

export interface AppointmentsStateProps {
  appointments: Appointment[];
  initialized: boolean;
}

export const initialAppointmentsState: AppointmentsStateProps = {
  appointments: [],
  initialized: false,
};

const AppointmentsState = observable<AppointmentsStateProps>(
  initialAppointmentsState,
);

autorun(() => {
  // ! Note: 'initialized' is set to 'false' after hot module reload in development environment.
  if (!AppointmentsState.initialized) {
    return;
  }

  // Converts the observable-object to a javascript-object as mobx-observables can not be stored.
  const appointmentsState = toJS(AppointmentsState);

  localforage
    .setItem('appointmentsState', appointmentsState)
    .catch((error: any) => console.warn('could not be stored locally ', error));
});

export default AppointmentsState;

import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { TestIds } from 'src/testIds';
import { useUserStore } from 'src/services/auth/auth';
import Alert from 'src/components/Alert/Alert';
import Button from 'src/components/Button/Button';
import Form from 'src/components/Form/Form';
import TextField from 'src/components/TextField/TextField';

import useStyles from './ForgotPasswordForm.styles';

type FormSubmitStatus = 'initial' | 'success' | 'error';

export interface ForgotPasswordFormProps {
  className?: string;
  afterSubmit?: (formSubmitStatus: FormSubmitStatus) => void;
}

export interface Values {
  email: string;
  submit: string;
}

const ComponentTestIds = TestIds.components.forgotPasswordForm;

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  className,
  afterSubmit = () => {},
  ...props
}) => {
  const classes = useStyles();
  const { requestPasswordReset } = useUserStore();
  const { t } = useTranslation();

  const initialValues: Values = {
    email: '',
    submit: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .max(255)
      .email(t('ForgotPasswordForm.Fields.Email.invalid'))
      .required(t('ForgotPasswordForm.Fields.Email.required')),
  });

  const handleSubmit = async (
    values: Values,
    { setErrors }: FormikHelpers<Values>,
  ) => {
    try {
      await requestPasswordReset(values.email);

      afterSubmit('success');
    } catch (error: any) {
      setErrors({ submit: t('General.somethingWentWrong') });

      afterSubmit('error');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form
          className={clsx(classes.root, className)}
          invertColor
          noValidate
          {...props}
        >
          <TextField
            data-test-id={ComponentTestIds.emailField}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={t('ForgotPasswordForm.Fields.Email.label')}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.email}
            variant="outlined"
          />

          {errors.submit && (
            <Box mt={3}>
              <Alert
                data-test-id={ComponentTestIds.submitError}
                severity="error"
                variant="transparent"
              >
                {errors.submit}
              </Alert>
            </Box>
          )}

          <Grid item container justifyContent="center" xs={12}>
            <Box mt={2}>
              <Button
                color="secondary"
                data-test-id={ComponentTestIds.submitButton}
                disabled={isSubmitting}
                loading={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                {t('ForgotPasswordForm.requestPassword')}
              </Button>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;

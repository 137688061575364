import { parseISO } from 'date-fns';
import _ from 'lodash';

import { Appointment, AppointmentStatus } from 'src/types';

import AppointmentRecordsState from '../AppointmentRecords';

import AppointmentsState from './state';

/**
 * Get appointment by its id and the recurrenceIndex.
 * Returns the first appointment element if no recurrenceIndex
 * was provided or 'undefined' if no appointment was found.
 */
export const getAppointment = (
  appointmentId: number,
  recurrenceIndex: number | null,
) => {
  const appointments = AppointmentsState.appointments;

  // Return first matching appointment when no recurrenceIndex was provided.
  if (recurrenceIndex === undefined)
    return appointments.find((appointment) => appointment.id === appointmentId);

  // Return the appointment with a matching id and matching recurrenceIndex.
  return appointments.find(
    (appointment) =>
      appointment.id === appointmentId &&
      appointment.recurrenceIndex === recurrenceIndex,
  );
};

/**
 * Get sorted Appointments by date (default: ascending).
 */
export const getSortedAppointments = (ascending = true) => {
  const appointments = _.cloneDeep(AppointmentsState.appointments);

  return appointments.sort((a, b) =>
    ascending
      ? new Date(a.dateDay).getTime() - new Date(b.dateDay).getTime()
      : new Date(b.dateDay).getTime() - new Date(a.dateDay).getTime(),
  );
};

/**
 * Get extended appointment status.
 * UPLOAD_PENDING and IS_DELAYED status are only used in the frontend application.
 */
export const getExtendedAppointmentStatus = (appointment: Appointment) => {
  const today = new Date();
  const endTimeDate = parseISO(`${appointment.dateDay}T${appointment.endTime}`);

  const isDelayed =
    today.getTime() - endTimeDate.getTime() > 30 * 60 * 1000 &&
    appointment.status === AppointmentStatus.HAS_CLEARANCE;

  const uploadPending = AppointmentRecordsState.appointmentRecordsPending.some(
    (appointmentRecordPending) =>
      appointmentRecordPending.appointmentId === appointment.id,
  );

  if (uploadPending) {
    return AppointmentStatus.UPLOAD_PENDING;
  }

  if (isDelayed) {
    return AppointmentStatus.IS_DELAYED;
  }

  return appointment.status;
};

import { isWithinInterval } from 'date-fns';

import BlockTimesState from 'src/services/state/BlockTimes';

const hasBlockTimeEntryAtDate = (date: Date, type: 'single' | 'recurring') => {
  const blockTimes = BlockTimesState.blockTimes[type];

  return blockTimes.some((blockTime) =>
    isWithinInterval(date.setHours(0, 0, 0, 0), {
      start: new Date(blockTime.dateDay).setHours(0, 0, 0, 0),
      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
      end: new Date(blockTime.dateDay).setHours(0, 0, 0, 0),
    }),
  );
};

const getAllDayBlockTimeEntryAtDate = (date: Date) => {
  const allBlockTimes = [
    ...BlockTimesState.blockTimes.single,
    ...BlockTimesState.blockTimes.recurring,
  ];

  return allBlockTimes.find(
    (blockTime) =>
      blockTime.allDay === true &&
      isWithinInterval(date.setHours(0, 0, 0, 0), {
        start: new Date(blockTime.dateDay).setHours(0, 0, 0, 0),
        // eslint-disable-next-line sort-keys-fix/sort-keys-fix
        end: new Date(blockTime.dateDay).setHours(0, 0, 0, 0),
      }),
  );
};

export { hasBlockTimeEntryAtDate, getAllDayBlockTimeEntryAtDate };

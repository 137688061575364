import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';

const useStyles = makeStyles(
  () => ({
    profileItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: '16px',
      paddingTop: '16px',
      borderBottom: '1px solid',
      borderBottomColor: themeColors.shade2,
      fontSize: '16px',

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),

  { name: 'ProfileView' },
);

export default useStyles;

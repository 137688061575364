declare global {
  interface Window {
    env: any;
  }
}

// Read environment variables either from the window object or the process object
// see ../env.generate.js for details
function readEnv(name: string) {
  if (window.env && window.env[name]) {
    return window.env[name];
  }
  return process.env[name];
}

function getCurrentCompanyOption(afhOption: string, feeOption: string) {
  if (ENVIRONMENT && ENVIRONMENT.toString().startsWith('AFH')) {
    return afhOption;
  }
  if (ENVIRONMENT && ENVIRONMENT.toString().startsWith('JP-SERVICEFEE')) {
    return feeOption;
  }

  // Although we have checked above, return AFH as default, should anything go wrong
  return afhOption;
}

export const BACKEND_URL = readEnv('REACT_APP_BACKEND_URL');
export const ENVIRONMENT = readEnv('REACT_APP_ENVIRONMENT');
export const SENTRY_URL = readEnv('REACT_APP_SENTRY_URL');

export const API_URL = `${BACKEND_URL}/api/v1/`;
export const API_AUTH_PATH = 'auth/';

export const ANO_URL = 'https://www.datev.de/ano/';

export const IMPRINT = getCurrentCompanyOption(
  'https://www.agentur-fuer-haushaltshilfe.de/impressum/',
  'https://www.jpservicefee.de/impressum',
);
export const PAGE_TITLE = getCurrentCompanyOption(
  'Agentur für Haushaltshilfe',
  'JP Servicefee',
);
export const PRIVACY = getCurrentCompanyOption(
  'https://www.agentur-fuer-haushaltshilfe.de/datenschutz/',
  'https://www.jpservicefee.de/datenschutz',
);

export const HEADER_LOGO = getCurrentCompanyOption(
  '/static/favicon/apple-touch-icon.png',
  '/static/favicon/apple-touch-icon-fee.png',
);

// Use manifest.json for AHF & FEE
export const MANIFEST_DATA = getCurrentCompanyOption(
  `${process.env.PUBLIC_URL}/manifest.json`,
  `${process.env.PUBLIC_URL}/fee-manifest.json`,
);

export const DESCRIPTION = getCurrentCompanyOption('AFH', 'JP-SERVICEFEE');

import localforage from 'localforage';
import { action, runInAction } from 'mobx';

import type { UserStateProps } from './state';
import UserState, { initialUserState } from './state';

/**
 * Initialize the UserState.
 * Always use local data first to populate the state and override it with server data if possible.
 */
export const initializeUserState = action('initializeUserState', async () => {
  await fillWithLocalState();

  runInAction(() => (UserState.initialized = true));
});

/**
 * Fill with local state.
 */
export const fillWithLocalState = action('fillWithLocalState', async () => {
  try {
    const localUserState =
      await localforage.getItem<UserStateProps>('userState');

    if (!localUserState) return;

    runInAction(() => {
      Object.assign(UserState, localUserState);
    });
  } catch (error: any) {
    console.warn('could not retrieve local data ', error);
  }
});

/**
 * Set user state.
 */
export const setUserState = action(
  'setUser',
  (initProps: Pick<UserStateProps, 'authConfig' | 'user'>) => {
    Object.assign(UserState, initProps);
  },
);

/**
 * Resets user state.
 */
export const resetUserState = action('resetUser', () => {
  Object.assign(UserState, initialUserState);
  localforage.removeItem('userState');
});

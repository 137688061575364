import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    titleWrapper: {
      display: 'flex',
    },

    iconWrapper: {
      minWidth: 33, // Avoid shifting the title to the left or right regardless of if this button is rendered or not.
      display: 'flex',
    },

    toolBar: {
      minHeight: `calc(${theme.settings.topBarHeight}px - ${theme.spacing(4)})`,
      justifyContent: 'space-between',
    },

    content: {
      marginTop: theme.spacing(1),
    },
  }),

  { name: 'TopBar' },
);

export default useStyles;

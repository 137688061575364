import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { routes } from 'src/services/routing';
import UserState from 'src/services/state/User';

interface LoginGuardProps {
  children?: ReactNode;
}

/**
 * Shows nested views only to logged out users and redirects logged in users accordingly.
 */
const LoginGuard: FC<LoginGuardProps> = ({ children }) => {
  const location = useLocation();
  const locationState = location.state as any;

  if (UserState.user) {
    if (locationState) {
      // 'from' is only set if the user visited a page of this application but was not logged in.
      // Redirecting to 'from' shows the user the page he initially visited, after a successful login.
      return <Redirect to={locationState.from} />;
    }

    // If 'from' is not set, the user is simply redirected to this applications root page.
    return <Redirect to={routes.app.path} />;
  }

  return <>{children}</>;
};

export default observer(LoginGuard);

import localforage from 'localforage';
import { action, runInAction } from 'mobx';

import axi from 'src/services/api';
import {
  CreateBlockTimeProps,
  DeleteBlockTimeProps,
  EditBlockTimeProps,
} from 'src/services/api/api';
import type { BlockTimesStateProps } from 'src/services/state/BlockTimes/state';
import BlockTimesState from 'src/services/state/BlockTimes/state';

/**
 * Initialize the BlockTimesState.
 * Always use local data first to populate the state and override it with server data if possible.
 */
export const initializeBlockTimesState = action(
  'initializeBlockTimesState',
  async () => {
    await fillWithLocalState();
    await fetchBlockTimes();

    runInAction(() => (BlockTimesState.initialized = true));
  },
);

/**
 * Fill with local state.
 */
export const fillWithLocalState = action('fillWithLocalState', async () => {
  try {
    const localBlockTimesState =
      await localforage.getItem<BlockTimesStateProps>('blockTimesState');

    if (!localBlockTimesState) return;

    runInAction(() => {
      Object.assign(BlockTimesState, localBlockTimesState);
    });
  } catch (error: any) {
    console.warn('could not retrieve local data ', error);
  }
});

/**
 * Fetch BlockTimes.
 */
export const fetchBlockTimes = action('fetchBlockTimes', async () => {
  const { data: blockTimes } = await axi.getBlockTimes();
  
  runInAction(() => (BlockTimesState.blockTimes = blockTimes));
});

/**
 * Create Block Time.
 */
export const createBlockTime = action(
  'createBlockTime',
  async (requestProps: CreateBlockTimeProps) => {
    const response = await axi.postBlockTimes(requestProps);
    await fetchBlockTimes();

    return response;
  },
);

/**
 * Edit Block Time.
 */
export const editBlockTime = action(
  'editBlockTime',
  async (requestProps: EditBlockTimeProps) => {
    await axi.patchBlockTime(requestProps);

    await fetchBlockTimes();
  },
);

/**
 * Delete Block Time.
 */
export const deleteBlockTime = action(
  'deleteBlockTime',
  async (requestProps: DeleteBlockTimeProps) => {
    await axi.deleteBlockTime(requestProps);
    await fetchBlockTimes();
  },
);

/**
 * Resets BlockTimesState.
 */
export const resetBlockTimesState = action('resetBlockTimes', () => {
  
  BlockTimesState.reset()
  localforage.removeItem('blockTimesState');
});

import { createTheme as createMuiTheme } from '@mui/material/styles';
import _ from 'lodash';

import { muiOverrides as overrides } from './muiOverrides';
import themes from './themes';
import { Theme, ThemeConfig } from './types';
import typography from './typography';

const baseConfig: ThemeConfig = {
  name: 'base',
  ...overrides,
  settings: {
    topBarHeight: 68,
    bottomNavHeight: 64,
  },
  typography,
};

export const createTheme = (themeName: string): Theme => {
  let themeConfig = themes.find((theme) => theme.name === themeName);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${themeName} is not valid`));

    [themeConfig] = themes;
  }

  const theme = createMuiTheme(_.merge({}, baseConfig, themeConfig));
  // const theme = createMuiTheme();

  return theme as Theme;
};

import type { FC } from 'react';
import React from 'react';
import { StyledEngineProvider, ThemeProvider, useTheme } from '@mui/material';
import clsx from 'clsx';
import type { FormikFormProps } from 'formik';
import { Form as FormikForm } from 'formik';

import { createTheme } from 'src/theme';
import type { Theme } from 'src/theme/types';

import useStyles from './Form.styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export interface FormProps extends FormikFormProps {
  invertColor?: boolean;
}

export const Form: FC<FormProps> = ({
  children,
  className = '',
  invertColor,
  ...props
}) => {
  const defaultTheme = useTheme<Theme>();
  const nestedTheme = invertColor ? createTheme('dark') : defaultTheme;
  const classes = useStyles(nestedTheme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={nestedTheme}>
        <FormikForm
          className={clsx({
            [classes.root]: true,
            [classes.invertColor]: invertColor,
            [className]: true,
          })}
          {...props}
        >
          {children}
        </FormikForm>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default Form;

import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cached, DateRange } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { TestIds } from 'src/testIds';
import { RecurrenceType } from 'src/types/appointment';
import TextField from 'src/components/TextField/TextField';

import useStyles from './RecurringElement.styles';

const ComponentTestIds = TestIds.components.appointmentForm;

interface Props {
  endDay?: string | null;
  endDayError?: React.ReactNode;
  error?: boolean;
  title?: string;
  value: RecurrenceType;
  handleDatePickerChange: (value: any, keyboardInputValue?: string) => void;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

const RecurringElement: FC<Props> = ({
  endDay,
  endDayError,
  error,
  title,
  value,
  handleDatePickerChange,
  handleOnChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [typeValue, setTypeValue] = useState(value);

  const onChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    handleOnChange(event, value);
    setTypeValue(Number(value));
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <FormControl component="fieldset" error={error} required>
        {title && (
          <FormLabel>
            <Box className={classes.radioLabel} marginBottom={1}>
              <Cached color="primary" style={{ marginRight: 8 }} />
              <Typography color="primary" variant="h4">
                {title}
              </Typography>
            </Box>
          </FormLabel>
        )}
        <RadioGroup name="recurrenceType" onChange={onChange}>
          <FormControlLabel
            data-test-id={ComponentTestIds.recurrenceTypeWorkdays}
            control={
              <Radio
                checked={typeValue === RecurrenceType.WORKDAYS}
                color="primary"
              />
            }
            label={t('RecurringElement.RecurrenceType.workdays')}
            value={RecurrenceType.WORKDAYS}
          />
          <FormControlLabel
            data-test-id={ComponentTestIds.recurrenceTypeWeekly}
            control={
              <Radio
                checked={typeValue === RecurrenceType.WEEKLY}
                color="primary"
              />
            }
            label={t('RecurringElement.RecurrenceType.weekly')}
            value={RecurrenceType.WEEKLY}
          />
          <FormControlLabel
            data-test-id={ComponentTestIds.recurrenceTypeBiWeekly}
            control={
              <Radio
                checked={typeValue === RecurrenceType.BIWEEKLY}
                color="primary"
              />
            }
            label={t('RecurringElement.RecurrenceType.biWeekly')}
            value={RecurrenceType.BIWEEKLY}
          />
          <FormControlLabel
            data-test-id={ComponentTestIds.recurrenceTypeMonthly}
            control={
              <Radio
                checked={typeValue === RecurrenceType.MONTHLY}
                color="primary"
              />
            }
            label={t('RecurringElement.RecurrenceType.monthly')}
            value={RecurrenceType.MONTHLY}
          />
        </RadioGroup>
      </FormControl>

      <Box mt={2}>
        <DatePicker
          inputFormat="dd | MM | yyyy"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <DateRange />
              </InputAdornment>
            ),
          }}
          label={t('RecurringElement.endDayLabel')}
          renderInput={(props) => (
            <TextField
              data-test-id={ComponentTestIds.recurrenceEndDayField}
              {...props}
              error={!!endDayError}
              name="recurrenceEndDay"
              helperText={endDayError}
              variant="outlined"
              fullWidth
            />
          )}
          value={endDay}
          closeOnSelect
          onChange={handleDatePickerChange}
        />
      </Box>
    </Box>
  );
};

export default RecurringElement;

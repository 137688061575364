import type { FC, Ref } from 'react';
import { createRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import type { DialogProps, SlideProps } from '@mui/material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { TestIds } from 'src/testIds';
import Button from 'src/components/Button/Button';

import useStyles from './Signature.styles';

const ComponentTestIds = TestIds.components.signature;

export interface SignatureProps extends DialogProps {
  description?: string;
  onSuccess: (params?: any) => void;
  signatory: string;
}

const SlideTransition = forwardRef(
  ({ children, ...props }: SlideProps, ref: Ref<unknown>) => (
    <Slide direction="left" ref={ref} {...props}>
      {children}
    </Slide>
  ),
);

export const Signature: FC<SignatureProps> = ({
  description,
  onClose = () => {},
  onSuccess,
  signatory,
  title,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const signatureCanvasRef = createRef<SignatureCanvas>();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  /**
   * Clears the signatureCanvas.
   */
  const handleClear = () => {
    if (!signatureCanvasRef.current) return;

    signatureCanvasRef.current.clear();
  };

  /**
   * Handles signature confirmation.
   * Checks if there is a signature before returning it as a base64 encoded image.
   * Shows an error notification if no signature was provided yet.
   */
  const handleConfirm = () => {
    if (!signatureCanvasRef.current) return;

    if (signatureCanvasRef.current.isEmpty()) {
      return enqueueSnackbar(t('Signature.noSignature'), {
        variant: 'error',
      });
    }

    const signatureBase64 = signatureCanvasRef.current
      .getTrimmedCanvas()
      .toDataURL('image/png');

    onSuccess(signatureBase64);
    onClose({}, 'escapeKeyDown');
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen
      onClose={onClose}
      TransitionComponent={SlideTransition}
      {...props}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container alignContent="center" alignItems="center">
          <Grid item xs={2}>
            <IconButton
              onClick={(event) => onClose(event, 'escapeKeyDown')}
              size="small"
            >
              <ChevronLeftIcon />
            </IconButton>
          </Grid>

          <Grid item xs={8}>
            <Typography gutterBottom variant="h2" align="center">
              {title}
            </Typography>
          </Grid>

          <Grid item xs={2}></Grid>
        </Grid>
      </DialogTitle>

      <DialogContent
        className={classes.signaturePadWrapper}
        data-test-id={ComponentTestIds.signaturePadWrapper}
      >
        <Typography gutterBottom align="left">
          {description}
        </Typography>
        <Box className={classes.signatureCanvasWrapper}>
          <SignatureCanvas
            canvasProps={{ className: classes.signaturePad, id: 'signPad' }}
            maxWidth={3}
            minWidth={2}
            ref={signatureCanvasRef}
          />
        </Box>
        {smUp && (
          <Typography className={classes.signatureArrow}>
            {t('Signature.signature')}
          </Typography>
        )}

        <hr className={classes.signBorder} />
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm>
            <Typography
              align={smUp ? 'left' : 'center'}
              gutterBottom
              style={{ padding: smUp ? theme.spacing(1) : 0 }}
              variant="body2"
            >
              {t('Signature.signatory', { signatory })}
            </Typography>
          </Grid>

          <Grid className={classes.buttonWrapper} item xs={12} sm>
            <Button
              color="secondary"
              data-test-id={ComponentTestIds.retryButton}
              fullWidth={!smUp}
              onClick={handleClear}
            >
              {t('Signature.retry')}
            </Button>

            <Button
              data-test-id={ComponentTestIds.confirmButton}
              fullWidth={!smUp}
              onClick={handleConfirm}
              style={{
                color: theme.palette.primary.light,
              }}
            >
              {t('Signature.confirm')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Signature;

import type { CSSProperties, FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { BoxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  differenceInMinutes,
  eachHourOfInterval,
  format,
  isSameDay,
  isToday,
  parseISO,
  subHours,
} from 'date-fns';
import { observer } from 'mobx-react-lite';

import { TestIds } from 'src/testIds';
import {
  type Appointment,
  type BlockTime,
} from 'src/types';
import { getAllDayBlockTimeEntryAtDate } from 'src/utils';
import AppointmentsState from 'src/services/state/Appointments';
import BlockTimesState from 'src/services/state/BlockTimes';
import AppointmentCard from 'src/components/AppointmentCard/AppointmentCard';

import { BlockTimeBannerNote } from '../BannerNotes/BlockTimeBannerNote';
import { CalendarCard } from '../CalendarCard/CalendarCard';

import useStyles from './DailyAgenda.styles';

const ComponentTestIds = TestIds.components.dailyAgenda;


export interface DailyAgendaProps extends BoxProps {
  selectedDate: Date;
}

interface AppointmentExtended extends Appointment {
  style?: {
    gridArea: string;
  };
  overlapsBlockTime?: boolean;
}

interface BlockTimeExtended extends BlockTime {
  style?: {
    gridArea: string;
  };
}

const fullHours = eachHourOfInterval({
  end: new Date().setHours(24, 0, 0, 0),
  start: new Date().setHours(0, 0, 0, 0),
});

/**
 * Returns the top-offset of the current time in px.
 * Currently every grid row represents 15 minutes with a fixed height of 20px.
 * Thus each minute has a fixed height of (20/15)px.
 */
const getPositionOfCurrentTime = (rowSpanInMinutes: number) => {
  const now = new Date();
  const currentTimeInMinutes =
    now.getHours() * 60 + // Current hours in minutes.
    now.getMinutes(); // Minutes remaining from the current hour.

  return currentTimeInMinutes * (20 / rowSpanInMinutes) + 10; // +10px because of the "marginTop: -10" from the dividers.
};

const getDailyEntries = (
  entries: (BlockTime | Appointment)[],
  rowSpanInMinutes: number,
  selectedDate: Date,
) =>
  entries
    .filter((entry) => isSameDay(selectedDate, parseISO(entry.dateDay)))
    .map((dailyEntry) => {
      const startTimeDate = parseISO(
        `${dailyEntry.dateDay}T${dailyEntry.startTime}`,
      );
      const endTimeDate = parseISO(
        `${dailyEntry.dateDay}T${dailyEntry.endTime}`,
      );
      const startTimeInMinutes =
        startTimeDate.getHours() * 60 + startTimeDate.getMinutes();
      const durationInMinutes = differenceInMinutes(endTimeDate, startTimeDate);

      const entryRowStart = Math.round(
        startTimeInMinutes / rowSpanInMinutes + 1,
      );
      const entryRowEnd = Math.round(durationInMinutes / rowSpanInMinutes);

      const gridAreaStyle = `${entryRowStart} / 2 / span ${entryRowEnd}`;

      return {
        ...dailyEntry,
        style: { gridArea: gridAreaStyle },
      };
    });

const parseTimeString = (timeString: string) => {
  // Set a default date we only care about the time part to make it compareable
  const dateString = `1970-01-01 ${timeString}`;
  const date = new Date(dateString);

  return date;
};

const markOverlappingAppointments = (
  appointments: AppointmentExtended[],
  blockTimes: BlockTime[],
) =>
  appointments.map((appointment) => {
    const appointmentStartTime = parseTimeString(
      appointment.startTime,
    ).getTime();
    const appointmentEndTime = parseTimeString(appointment.endTime).getTime();

    let appointmentToReturn = appointment;

    blockTimes.forEach((blockTime) => {
      const blockTimeStartTime = parseTimeString(blockTime.startTime).getTime();
      const blockTimeEndTime = parseTimeString(blockTime.endTime).getTime();

      if (
        (appointmentStartTime < blockTimeEndTime &&
          appointmentEndTime > blockTimeStartTime) ||
        (appointmentStartTime > blockTimeStartTime &&
          appointmentStartTime < blockTimeEndTime) ||
        (appointmentEndTime > blockTimeStartTime &&
          appointmentEndTime < blockTimeEndTime)
      ) {
        appointmentToReturn = { ...appointment, overlapsBlockTime: true };
      }
    });

    return appointmentToReturn;
  });

export const DailyAgenda: FC<DailyAgendaProps> = ({
  selectedDate,
  ...props
}) => {
  const [currentTimePosition, setCurrentTimePosition] = useState(0);
  const isTodaysDate = isToday(selectedDate);

  const {
    root,
    appointmentCard,
    banner,
    bannerWrapper,
    blockTimeBanner,
    currentTime,
    divider,
    gridRows,
    gridRowsContainer,
    hoursItem,
    imperceptibleAppointments,
    blockTimeOverlappingMargin,
  } = useStyles();
  const appointments = AppointmentsState.appointments;
  const blockTimes = [
    ...BlockTimesState.blockTimes.single,
  ];
  const today = new Date();
  const hourDividend = 4;
  const rowSpanInMinutes = 60 / hourDividend;

  const dailyBlockTimes = getDailyEntries(
    blockTimes,
    rowSpanInMinutes,
    selectedDate,
  ) as BlockTimeExtended[];

  const dailyAppointmentsTemp = getDailyEntries(
    appointments,
    rowSpanInMinutes,
    selectedDate,
  ) as AppointmentExtended[];

  const dailyAppointments = markOverlappingAppointments(
    dailyAppointmentsTemp,
    dailyBlockTimes,
  );

  const allDayBlockTime = getAllDayBlockTimeEntryAtDate(selectedDate);

  // Scroll to the current time into view, when the selected date changes.
  useEffect(() => {
    document
      .querySelector(
        `[data-scroll-anchor="${format(subHours(today, 1), 'HH')}:00"]`,
      )
      ?.scrollIntoView();
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Sets the initial position of the "current time divider" on mount.
  // Also sets an interval to update the position every minute.
  useEffect(() => {
    setCurrentTimePosition(getPositionOfCurrentTime(rowSpanInMinutes));

    const interval = setInterval(
      () => {
        setCurrentTimePosition(getPositionOfCurrentTime(rowSpanInMinutes));
      },
      1000 * 60 * 1,
    );

    return function cleanUp() {
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      data-test-id={ComponentTestIds.wrapper}
      className={root}
      paddingTop={allDayBlockTime ? 0 : 2}
      {...props}
    >
      {(allDayBlockTime) && (
        <Box className={bannerWrapper}>
          <Box className={clsx(banner, blockTimeBanner)}>
            <BlockTimeBannerNote />
          </Box>
        </Box>
      )}

      <Box className={gridRowsContainer}>
        <Box className={gridRows}>
          {fullHours.map((hour, index) => {
            const timeString = format(hour, 'HH:mm');

            return (
              <Box
                key={index}
                className={hoursItem}
                data-test-id={ComponentTestIds.timeSteps}
                data-scroll-anchor={timeString}
                style={{ gridRow: index * hourDividend + 1 }}
              >
                <Typography variant="subtitle2">{timeString}</Typography>
                <span className={divider} />
              </Box>
            );
          })}

          {/* Renders either whole day VacationCard, whole day BlockTimeCard or single BlockTimeCards */}
          {allDayBlockTime ? (
            <CalendarCard type="blockTime" allDay />
          ) : (
            dailyBlockTimes.map((dailyBlockTime, index) => {
              const { style, id } = dailyBlockTime;

              return (
                <CalendarCard
                  key={`${index}-${id}`}
                  style={style}
                  type="blockTime"
                />
              );
            })
          )}

          {dailyAppointments.map((dailyAppointment) => {
            const { style, overlapsBlockTime, ...appointment } =
              dailyAppointment;

            return (
              <AppointmentCard
                key={`${appointment.id}${appointment.recurrenceIndex}`}
                style={style}
                className={clsx(
                  appointmentCard,
                  (allDayBlockTime) &&
                    imperceptibleAppointments,
                  !allDayBlockTime &&
                    overlapsBlockTime &&
                    blockTimeOverlappingMargin,
                )}
                appointment={appointment}
              />
            );
          })}

          {/* We hide the current time indicator if there is an allDay event as it would overlap */}
          {isTodaysDate && !allDayBlockTime && (
            <Box
              data-test-id={ComponentTestIds.currentTimeDivider}
              className={currentTime}
              style={{ marginTop: currentTimePosition }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default observer(DailyAgenda);

import { FC, FocusEvent, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DateRange as CalendarIcon } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';
import type { MobileDatePickerProps } from '@mui/x-date-pickers';
import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { add, addMonths, format, isAfter, parse } from 'date-fns';
import type { FormikHelpers, FormikProps } from 'formik';
import { Formik } from 'formik';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { TestIds } from 'src/testIds';
import { BlockTime, RecurrenceType } from 'src/types';
import i18n from 'src/services/i18n/i18n';
import { routes } from 'src/services/routing';
import { actions as blockTimeActions } from 'src/services/state/BlockTimes';
import { editBlockTime } from 'src/services/state/BlockTimes/actions';
import Alert from 'src/components/Alert/Alert';
import Button from 'src/components/Button/Button';
import Form from 'src/components/Form/Form';
import TextField from 'src/components/TextField/TextField';

import { EndTimeSelect } from '../EndTimeSelect/EndTimeSelect';
import RecurringElement from '../RecurringElement/RecurringElement';
import { StartTimeSelect } from '../StartTimeSelect/StartTimeSelect';

import useStyles from './BlockTimeForm.styles';

interface Props {
  afterSubmit?: () => void;
  blockTime?: BlockTime;
  className?: string;
  editRecurrence?: boolean;
}

interface Values extends BlockTime {
  id?: string;
  submit?: string | undefined;
}

const validationSchema = Yup.object().shape({
  allDay: Yup.boolean(),
  dateDay: Yup.date()
    .nullable()
    .required(i18n.t('General.Form.dateDayRequired')),
  endTime: Yup.date()
    .nullable()
    .required(i18n.t('General.Form.endRequired'))
    .when(
      'startTime',
      (startTime, schema) =>
        startTime &&
        schema.min(
          add(startTime, { minutes: 1 }),
          i18n.t('General.Form.mustBeAfterStartTime'),
        ),
    ),
  recurrenceEndDay: Yup.string()
    .nullable()
    .when(['dateDay', 'recurrenceType'], (dateDay, recurrenceType) =>
      Yup.string()
        .nullable()
        .test(
          'isAfter DateDay',
          i18n.t('Views.BlockTimes.Error.mustBeAfterDateDay'),
          (recurrenceEndDay) =>
            dateDay &&
            recurrenceType !== RecurrenceType.ONCE &&
            recurrenceEndDay
              ? isAfter(new Date(recurrenceEndDay), new Date(dateDay))
              : true,
        ),
    ),
  recurrenceIndex: Yup.number().nullable(),
  recurrenceType: Yup.number().required(
    i18n.t('RecurringElement.RecurrenceType.required'),
  ),
  startTime: Yup.date()
    .nullable()
    .required(i18n.t('General.Form.startRequired')),
});

export const BlockTimeForm: FC<Props> = ({
  afterSubmit: _afterSubmit,
  blockTime,
  editRecurrence,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const formikRef = useRef<FormikProps<Values>>(null);
  const allDayEndTime = useMemo(() => {
    const endTime = new Date();
    endTime.setHours(23, 59, 59, 0);

    return endTime;
  }, []);

  const isEditView = !!blockTime;
  const formTitle = isEditView
    ? t('General.edit')
    : t('Views.BlockTimes.create');
  const allDay = blockTime?.allDay || false;
  const today = new Date();
  const dateDay = blockTime ? blockTime.dateDay : '';
  const recurrenceType = blockTime?.recurrenceType || RecurrenceType.ONCE;
  const endTime = allDay
    ? parse('23:59:59', 'HH:mm:ss', today).toString()
    : blockTime
    ? parse(blockTime.endTime, 'HH:mm:ss', today).toString()
    : '';
  const startTime = blockTime
    ? parse(blockTime.startTime, 'HH:mm:ss', today).toString()
    : '';
  const showRecurrenceFields =
    recurrenceType > RecurrenceType.ONCE || editRecurrence;
  const afterSubmit =
    _afterSubmit || (() => history.push(routes.app.routes.blockTimes.path));

  const initialValues: Values = {
    allDay,
    dateDay,
    endTime,
    id: blockTime?.id,
    recurrenceEndDay:
      blockTime?.recurrenceEndDay ||
      format(addMonths(new Date(), 3), 'yyyy-MM-dd'),
    recurrenceIndex: blockTime?.recurrenceIndex,
    recurrenceType,
    startTime,
  };

  // Correctly set endtime in case of allDay blockTime
  useEffect(() => {
    if (isEditView && allDay) {
      formikRef.current?.setFieldValue('endTime', allDayEndTime);
    }
  }, [isEditView, allDay, allDayEndTime]);

  /**
   * Updates formik after changing the DatePicker`s selection.
   */
  const handleDatePickerChange: MobileDatePickerProps<
    Date,
    string
  >['onChange'] = (date, fieldName) => {
    formikRef.current?.setFieldValue(
      fieldName!,
      format(new Date(date || ''), 'yyyy-MM-dd'),
    );
  };

  /**
   * Scrolls to the first third of the select drop down if no item has been selected yet.
   */
  const handleSelectFocus = (
    event: FocusEvent<HTMLDivElement>,
    value?: string,
  ) => {
    if (value) return;

    const menuElement = event.target.closest('[class*="MuiList-root"]')
      ?.parentElement;

    // Only scroll if the drop down was not scrolled already to prevent
    // strange behavior caused by bubbling focus events.
    if (menuElement && !menuElement.scrollTop) {
      menuElement.scrollTo({ top: menuElement?.scrollHeight / 3 });
    }
  };

  /**
   * Sets the recurrenceType value to 'WORKDAYS' when the toggle is checked.
   * Setting it to any value above 'ONCE' it will render the recurrenceType radio group.
   */
  const handleRecurrenceToggleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = event.target;
    const value = target.checked
      ? RecurrenceType.WORKDAYS
      : RecurrenceType.ONCE;

    formikRef.current?.setFieldValue('recurrenceType', value);
  };

  /**
   * Sets the allDay value to 'true' when the toggle is checked.
   * Also sets startTime to "00:00" to & endTime to "23:59" after setting selects to disabled
   */
  const handleAllDayToggleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const allDayToggle = event.target;

    const startTime = new Date();

    startTime.setHours(0);
    startTime.setMinutes(0);
    startTime.setSeconds(0);

    formikRef.current?.setFieldValue('allDay', allDayToggle.checked);
    formikRef.current?.setFieldValue(
      'startTime',
      allDayToggle.checked ? startTime : '',
    );
    formikRef.current?.setFieldValue(
      'endTime',
      allDayToggle.checked ? allDayEndTime : '',
    );
    formikRef.current?.setTouched({}, false);
  };

  /**
   * Prepare the form`s values and handle success and error cases after submitting them.
   */
  const handleSubmit = async (
    values: Values,
    { setErrors }: FormikHelpers<Values>,
  ) => {
    // Abort submitting when editing a blocktime and the values have not been changed by the user.
    if (isEditView && _.isEqual(initialValues, values)) {
      return setErrors({ submit: t('BlockTimeForm.changeValues') });
    }

    try {
      const data = { ...values };
      delete data.submit;

      // Format times for backend.
      data.startTime = format(new Date(data.startTime), 'HH:mm');
      data.endTime = format(new Date(data.endTime), 'HH:mm');

      if (isEditView) {
        await editBlockTime({
          data: {
            ...data,
            id: blockTime.id,
            recurrenceIndex: editRecurrence ? null : blockTime.recurrenceIndex,
          },
        });

        enqueueSnackbar(t('BlockTimeForm.successEdit'), {
          variant: 'success',
        });
      } else {
        const { data: response } = await blockTimeActions.createBlockTime({
          data,
        });

        if (response.appointmentConflicts) {
          response.appointmentConflicts.map((appointmentConflict) =>
            enqueueSnackbar(appointmentConflict, {
              variant: 'warning',
            }),
          );
        }

        // Show a success snackbar message.
        enqueueSnackbar(t('BlockTimeForm.successCreate'), {
          variant: 'success',
        });
      }

      // Execute the afterSubmit callback. If no callback was provided, this redirects to the HomeView.
      afterSubmit();
    } catch (error: any) {}
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        isSubmitting,
        touched,
        values,
        handleBlur,
        handleChange,
      }) => (
        <Form className={classes.root}>
          <Box mb={3}>
            <Typography variant="h3">
              {isEditView
                ? t('Views.BlockTimes.Edit.title')
                : t('Views.BlockTimes.startLabel')}
            </Typography>
          </Box>
          <Grid
            container
            display="flex"
            mt={4}
            spacing={2}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid
              data-test-id={TestIds.components.blockTimes.date}
              item
              xs={12}
              mb={2}
            >
              <DatePicker
                closeOnSelect
                inputFormat="dd | MM | yyyy"
                renderInput={(props) => (
                  <TextField
                    fullWidth
                    helperText={touched.dateDay && errors.dateDay}
                    name="dateDay"
                    variant="outlined"
                    required
                    {...props}
                    error={!!(touched.dateDay && errors.dateDay)}
                  />
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                label={t('General.Form.dateLabel')}
                onChange={(date) => handleDatePickerChange(date, 'dateDay')}
                value={values.dateDay}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
            <Grid
              data-test-id={TestIds.components.blockTimes.startTime}
              item
              xs={6}
            >
              <FormControl
                error={!!(touched.startTime && errors.startTime)}
                variant="outlined"
                fullWidth
              >
                <StartTimeSelect
                  disabled={values.allDay}
                  error={touched.startTime ? errors.startTime : undefined}
                  value={values.startTime}
                  handleBlur={handleBlur}
                  handleOnChange={handleChange}
                  handleOnFocus={(event) =>
                    handleSelectFocus(event, values.startTime)
                  }
                  required
                />
              </FormControl>
            </Grid>
            <Grid
              data-test-id={TestIds.components.blockTimes.endTime}
              item
              xs={6}
            >
              <FormControl
                error={!!(touched.endTime && errors.endTime)}
                variant="outlined"
                fullWidth
              >
                <EndTimeSelect
                  disabled={values.allDay}
                  error={touched.endTime ? errors.endTime : undefined}
                  startTime={
                    values.startTime ? new Date(values.startTime) : undefined
                  }
                  value={values.endTime}
                  handleBlur={handleBlur}
                  handleOnChange={handleChange}
                  handleOnFocus={(event) =>
                    handleSelectFocus(event, values.startTime)
                  }
                  required
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={3}>
            <FormControlLabel
              control={
                <Switch
                  data-test-id={TestIds.components.blockTimes.allDaySwitch}
                  color="primary"
                  checked={values.allDay}
                  name="allDay"
                  onChange={handleAllDayToggleChange}
                />
              }
              label={t('Views.BlockTimes.allDayLabel')}
              labelPlacement="start"
            />
          </Box>

          {!isEditView && (
            <>
              <FormControlLabel
                data-test-id={TestIds.components.blockTimes.recurrenceSwitch}
                control={
                  <Switch
                    checked={values.recurrenceType > RecurrenceType.ONCE}
                    color="primary"
                    name="recurrenceType"
                    onChange={handleRecurrenceToggleChange}
                  />
                }
                label={t('Views.BlockTimes.switchLabel')}
                labelPlacement="start"
              />
              {values.recurrenceType > RecurrenceType.ONCE && (
                <Box mt={2}>
                  <RecurringElement
                    endDay={values.recurrenceEndDay}
                    endDayError={
                      touched.recurrenceEndDay && errors.recurrenceEndDay
                    }
                    error={!!(touched.recurrenceType && errors.recurrenceType)}
                    value={values.recurrenceType}
                    handleDatePickerChange={(date) =>
                      handleDatePickerChange(date, 'recurrenceEndDay')
                    }
                    handleOnChange={handleChange}
                  />
                </Box>
              )}
            </>
          )}

          {showRecurrenceFields && (
            <Box mt={1}>
              <RecurringElement
                endDay={values.recurrenceEndDay}
                endDayError={
                  touched.recurrenceEndDay && errors.recurrenceEndDay
                }
                error={!!(touched.recurrenceType && errors.recurrenceType)}
                value={values.recurrenceType}
                handleDatePickerChange={(date) =>
                  handleDatePickerChange(date, 'recurrenceEndDay')
                }
                handleOnChange={handleChange}
              />
            </Box>
          )}

          {errors.submit && (
            <Box mt={3}>
              <Alert severity="error">{errors.submit}</Alert>
            </Box>
          )}

          <Box display="flex" justifyContent="center" mt={2} mb={4}>
            <Button
              data-test-id={TestIds.components.blockTimes.submitBtn}
              color="secondary"
              disabled={isSubmitting}
              loading={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              {formTitle}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default observer(BlockTimeForm);

import type { FC, ReactNode } from 'react';
import React, { useRef } from 'react';
import { Box, Container } from '@mui/material';

import { TopBarProvider } from 'src/contexts/TopBarContext';
import { useRefDimensions } from 'src/hooks';
import { TestIds } from 'src/testIds';
import useScrollReset from 'src/hooks/useScrollReset';
import BottomNav from 'src/components/BottomNav/BottomNav';
import TopBar from 'src/components/TopBar/TopBar';

import useStyles from './DefaultLayout.styles';

const LayoutTestIds = TestIds.layouts.defaultLayout;

export interface DefaultLayoutProps {
  children?: ReactNode;
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const topBarRef = useRef<HTMLDivElement>(null);
  const { borderBoxHeight: topBarHeight } = useRefDimensions(topBarRef);
  const classes = useStyles({ topBarHeight });

  useScrollReset();

  return (
    <Box className={classes.root} data-test-id={LayoutTestIds.wrapper}>
      <TopBarProvider>
        <TopBar ref={topBarRef} />

        <Box className={classes.contentWrapper}>
          <Container className={classes.content} maxWidth="md">
            <>{children}</>
          </Container>
        </Box>

        <BottomNav />
      </TopBarProvider>
    </Box>
  );
};

export default DefaultLayout;

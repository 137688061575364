import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router';
import { Autorenew, Create } from '@mui/icons-material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { format, isAfter } from 'date-fns';
import { de } from 'date-fns/locale';

import { TestIds } from 'src/testIds';
import { RecurrenceType } from 'src/types';
import { BlockTime } from 'src/types/blockTimes';
import i18n from 'src/services/i18n/i18n';
import { routes } from 'src/services/routing';
import themeColors from 'src/theme/colors';

import { CardWrapper } from '../CardWrapper/CardWrapper';

import useStyles from './BlockTimeCard.styles';

/**
 * Handles Recurrence Text
 * e.g Wöchentlich / Monatlich / ...
 */
const getRecurrenceText = (type: RecurrenceType) => {
  switch (type) {
    case 2:
      return i18n.t('RecurringElement.RecurrenceType.workdays');
    case 3:
      return i18n.t('RecurringElement.RecurrenceType.weekly');
    case 4:
      return i18n.t('RecurringElement.RecurrenceType.biWeekly');
    case 5:
      return i18n.t('RecurringElement.RecurrenceType.monthly');
    default:
      return '';
  }
};

export const BlockTimeCard: FC<BlockTime> = ({
  allDay,
  dateDay,
  endTime,
  id,
  recurrenceType,
  recurrenceEndDay,
  startTime,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'BlockTimeCard' });
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<any>();
  const blockTimeEnd = new Date(dateDay);
  const hasElapsed = isAfter(blockTimeEnd, new Date());
  const canEdit = hasElapsed || recurrenceType > RecurrenceType.ONCE;
  /**
   * Handles time period
   * e.g von 11:00 bis 13:00 Uhr
   */
  const getPeriodText = () =>
    t('DescriptionText.period', {
      endTime: endTime.substring(0, endTime.length - 3),
      startTime: startTime.substring(0, endTime.length - 3),
    });


  /**
   * Handles the description text specific to the recurrenceType.
   */
  const getDescriptionText = () => {
    const startDate = format(new Date(dateDay), 'dd.MM.yyyy');
    const endDate = format(new Date(recurrenceEndDay), 'dd.MM.yyyy');
    const weekDay = format(new Date(dateDay), 'EEEE', {
      locale: de,
    });

    const dateRangeDescription = t('DescriptionText.date_range', {
      endDate: endDate,
      startDate: startDate,
    })
    const periodText = allDay ? t('DescriptionText.allDay') : getPeriodText();
    // allDay specifier should be at the beginning if there is one
    const dateTimeDescription = `${dateRangeDescription} ${periodText}`;

    if (recurrenceType === RecurrenceType.ONCE) {
      return `${weekDay} ${startDate} ${periodText}`;
    }
    if (recurrenceType === RecurrenceType.WORKDAYS) {
      return `${dateTimeDescription}`;
    }

    return `${t('DescriptionText.weekDay', { weekDay: weekDay })} ${dateTimeDescription}`;
  }

  /**
   * Handles redirecting to the BlockTimeForm when the card was clicked.
   */
  const handleClickBox = () => {
    if (canEdit) {
      history.push(
        generatePath(routes.stack.routes.blockTimeEdit.path, {
          id,
        }),
        { stackRoot: location },
      );
    }
  };

  return (
    <CardWrapper
      data-test-id={TestIds.components.blockTimes.card}
      className={clsx(
        classes.cardStyle,
        !hasElapsed ? classes.isPast : classes.isFuture,
      )}
      onClick={handleClickBox}
    >
      <Box display="flex" sx={{ justifyContent: 'space-between' }}>
        <Box>
          <Box display="flex">
            {recurrenceType > RecurrenceType.ONCE && (
              <Autorenew sx={{ fontSize: 20 }} />
            )}
            <Typography variant="h6">{t('title')}</Typography>
          </Box>
          <Box display="flex" mt={1}>
            <EmojiPeopleIcon fontSize="small" />
            <Typography ml={1} color={themeColors.shade4}>
              {`${getRecurrenceText(recurrenceType)} ${getDescriptionText()}`}
            </Typography>
          </Box>
        </Box>
        {canEdit && <Create fontSize="small" />}
      </Box>
    </CardWrapper>
  );
};

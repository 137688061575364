import { FC } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { range } from 'lodash';

interface Props {
  disabled: boolean;
  handleOnChange: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => void;
  value: number;
}

export const HourSelect: FC<Props> = ({ disabled, handleOnChange, value }) => {
  // Create range from 0 to 10 with 0.5 steps
  const timeSteps = range(0, 10, 0.5);

  return (
    <Select
      disabled={disabled}
      onChange={handleOnChange}
      name="hourSelect"
      value={value}
    >
      {timeSteps.map((timeStep) => (
        <MenuItem key={timeStep} value={timeStep}>
          {timeStep}
        </MenuItem>
      ))}
    </Select>
  );
};

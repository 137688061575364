import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import React, { createContext, useContext, useState } from 'react';

export interface TopBarProviderProps {
  children?: ReactNode;
  value?: Partial<TopBarContextValue>;
}

export interface TopBarContextValue {
  actionIcon?: ReactNode | (() => ReactNode);
  content?: ReactNode | (() => ReactNode);
  handleBack?: () => any;
  handleAction?: () => any;
  resetTopBar: () => any;
  setContent: Dispatch<
    SetStateAction<ReactNode | (() => ReactNode) | undefined>
  >;
  title?: string;
  setHandleBack: Dispatch<SetStateAction<(() => any) | undefined>>;
  setHandleAction: Dispatch<SetStateAction<(() => any) | undefined>>;
  setActionIcon: Dispatch<
    SetStateAction<ReactNode | (() => ReactNode) | undefined>
  >;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
}
export const TopBarContext = createContext<TopBarContextValue>({
  resetTopBar: () => {},
  setActionIcon: () => {},
  setContent: () => {},
  setHandleAction: () => {},
  setHandleBack: () => {},
  setTitle: () => {},
});

export const TopBarProvider: FC<TopBarProviderProps> = ({
  children,
  value,
}) => {
  const [handleBack, setHandleBack] = useState<() => any>();
  const [handleAction, setHandleAction] = useState<() => any>();
  const [title, setTitle] = useState<string>();
  const [actionIcon, setActionIcon] = useState<ReactNode | (() => ReactNode)>();
  const [content, setContent] = useState<ReactNode | (() => ReactNode)>();

  const resetTopBar = () => {
    setHandleBack(undefined);
    setHandleAction(undefined);
    setTitle(undefined);
    setContent(undefined);
  };

  return (
    <TopBarContext.Provider
      value={{
        actionIcon,
        content,
        handleAction,
        handleBack,
        resetTopBar,
        setActionIcon,
        setContent,
        setHandleAction,
        setHandleBack,
        setTitle,
        title,
        ...value,
      }}
    >
      {children}
    </TopBarContext.Provider>
  );
};

export const TopBarConsumer = TopBarContext.Consumer;

export const useTopBar = (): TopBarContextValue => useContext(TopBarContext);

export default TopBarContext;

import {
  addMonths,
  getISOWeek,
  getISOWeeksInYear,
  getYear,
  subMonths,
} from 'date-fns';

import { TimeIntervalTab } from 'src/components/TimeIntervalSelectionSlider/TimeIntervalSelectionSlider';

import {
  hasEntryInWeek,
  hasRecurringBlockTimeEntriesInWeek,
  hasSingleBlockTimEntriesInWeek,
} from '.';

/**
 * @description Returns an Array of TimeIntervalTab that contains at least a
 * full list of in TimeIntervalSelectionSlider displayable of weeks from the
 * year the given date is in. At the start of the year also can contain the
 * weeks up to a full month of the past year, at the end of a year it can
 * contain up to 3 months in weeks of the next year
 */
const getWeekTimeIntervalTabs = (date: Date) => {
  const maxWeeks = getISOWeeksInYear(date);
  const weekOfDate = getISOWeek(date);
  const weeksInYearOfDate = Array.from({ length: maxWeeks }, (_, i) => i + 1);
  const dayOneMonthAgo = subMonths(date, 1);
  const yearOneMonthAgo = getYear(dayOneMonthAgo);
  const dayInThreeMonths = addMonths(date, 3);
  const yearInThreeMonths = getYear(dayInThreeMonths);
  const dateYear = getYear(date);

  const tabsDateYear: TimeIntervalTab[] = weeksInYearOfDate.map((week) => ({
    date: { week: week, year: dateYear },
    hasAppointments: hasEntryInWeek(week, dateYear, 'appointment'),
    hasBlockTimes:
      hasSingleBlockTimEntriesInWeek(week, dateYear) ||
      hasRecurringBlockTimeEntriesInWeek(week, dateYear),
    highlight: weekOfDate === week,
    lowerLabel: `${week}`,
    testLabel: `KW-${week}`,
    upperLabel: 'KW',
  }));

  if (dateYear !== yearOneMonthAgo) {
    const weekOneMonthAgo = getISOWeek(dayOneMonthAgo);
    const maxWeeksPastYear = getISOWeeksInYear(dayOneMonthAgo);
    const difference = maxWeeksPastYear - weekOneMonthAgo + 1;
    const weeksPastYear = Array.from(
      { length: difference },
      (_, i) => i + weekOneMonthAgo,
    );
    const tabsPastYear: TimeIntervalTab[] = weeksPastYear.map((week) => ({
      date: { week: week, year: yearOneMonthAgo },
      hasAppointments: hasEntryInWeek(week, yearOneMonthAgo, 'appointment'),
      hasBlockTimes: hasSingleBlockTimEntriesInWeek(week, yearOneMonthAgo),
      highlight: false,
      lowerLabel: `${week}`,
      testLabel: `${yearOneMonthAgo}-KW-${week}`,
      upperLabel: 'KW',
    }));

    return tabsPastYear.concat(tabsDateYear);
  } else if (dateYear !== yearInThreeMonths) {
    const weekInThreeMonths = getISOWeek(dayInThreeMonths);
    const weeksNextYear = Array.from(
      { length: weekInThreeMonths },
      (_, i) => i + 1,
    );
    const tabsPastYear: TimeIntervalTab[] = weeksNextYear.map((week) => ({
      date: { week: week, year: yearInThreeMonths },
      hasAppointments: hasEntryInWeek(week, yearInThreeMonths, 'appointment'),
      hasBlockTimes: hasSingleBlockTimEntriesInWeek(week, yearInThreeMonths),
      highlight: false,
      lowerLabel: `${week}`,
      testLabel: `${yearInThreeMonths}-KW-${week}`,
      upperLabel: 'KW',
    }));

    return tabsDateYear.concat(tabsPastYear);
  }

  return tabsDateYear;
};

export default getWeekTimeIntervalTabs;

import type { FC, MouseEvent } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  CheckCircle as CheckCircleIcon,
  Create as CreateIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { format, isToday, parseISO } from 'date-fns';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';

import { useConfirm } from 'src/contexts/ConfirmContext';
import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import { AppointmentStatus, RecurrenceType } from 'src/types';
import { routes } from 'src/services/routing';
import appointmentsState, {
  actions as appointmentsActions,
  getters as appointmentsGetters,
} from 'src/services/state/Appointments';
import { getters as customersGetters } from 'src/services/state/Customers';
import type { Theme } from 'src/theme/types';
import AppointmentInformation from 'src/components/AppointmentInformation/AppointmentInformation';
import AppointmentRecordForm from 'src/components/AppointmentRecordForm/AppointmentRecordForm';
import Page from 'src/components/Page/Page';
import ThankYou from 'src/components/ThankYou/ThankYou';

import useStyles from './AppointmentRecordView.styles';

const AppointmentRecordTestIds = TestIds.views.appointmentRecord;

const AppointmentRecordView: FC = () => {
  const { t } = useTranslation();
  const { id, index } = useParams<{ id: string; index?: string }>();
  const appointmentId = parseInt(id);
  const recurrenceIndex = index === undefined ? null : parseInt(index);
  const classes = useStyles();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation<any>();
  const topBar = useTopBar();
  const theme = useTheme<Theme>();
  const appointment = appointmentsGetters.getAppointment(
    appointmentId,
    recurrenceIndex,
  );
  const customer = appointment
    ? customersGetters.getCustomerById(appointment.customerId)
    : undefined;
  const hasRecord =
    appointment && appointment.status === AppointmentStatus.HAS_RECORD;
  const isRecurring =
    appointment && appointment.recurrenceType > RecurrenceType.ONCE;

  /**
   * Handles generating and differentiating between recurring and single appointments
   * when redirecting to the edit view.
   */
  const goToEditView = (editRecurrence = false) => {
    history.push(
      generatePath(routes.stack.routes.appointmentEdit.path, {
        id: appointment?.id,
        index:
          typeof appointment?.recurrenceIndex === 'number'
            ? appointment.recurrenceIndex
            : undefined,
        singleOrEvery: editRecurrence ? 'every' : 'single',
      }),
      { stackRoot: location.state.stackRoot },
    );
  };

  /**
   * Deletes the current appointment.
   */
  const deleteAppointment = async () => {
    if (!appointment) return;

    let singleOrEvery;

    try {
      await confirm({
        description: isRecurring
          ? t('Views.AppointmentRecord.Delete.recurrenceDialogDescription', {
              date: format(new Date(appointment.dateDay), 'dd.MM.yyyy'),
            })
          : t('Views.AppointmentRecord.Delete.dialogDescription'),
        onPrimary: {
          shouldResolve: true,
          valueReason: 'single',
        },
        onSecondary: {
          shouldResolve: isRecurring,
          valueReason: isRecurring ? 'every' : 'abort',
        },
        primaryText: isRecurring
          ? t('Views.AppointmentRecord.Delete.appointment')
          : t('General.delete'),
        secondaryText: isRecurring
          ? t('Views.AppointmentRecord.Delete.recurrence')
          : t('General.abort'),
        title: isRecurring
          ? t('Views.AppointmentRecord.Delete.recurrenceDialogTitle')
          : t('Views.AppointmentRecord.Delete.dialogTitle'),
      }).then(async (valueReason: any) => {
        singleOrEvery = isRecurring ? valueReason : 'single';

        await appointmentsActions.deleteAppointment({
          appointmentId,
          recurrenceIndex: singleOrEvery === 'every' ? null : recurrenceIndex,
          startRecurrenceIndex: recurrenceIndex,
        });
      });

      // Show a success snackbar message.
      enqueueSnackbar(
        singleOrEvery === 'every'
          ? t('Views.AppointmentRecord.deleteSuccessMessageEvery')
          : t('Views.AppointmentRecord.deleteSuccessMessageSingle'),
        {
          variant: 'success',
        },
      );

      // Close this view on success.
      topBar.handleBack
        ? topBar.handleBack()
        : history.push(routes.app.routes.home.path);
    } catch (error: any) {
      if (error === 'onClose' || error === 'onSecondary' || error === 'abort')
        return;

      if (error.response?.data) {
        const errorMessage = error.response.data.detail || error.response.data;

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('General.somethingWentWrong'), {
          variant: 'error',
        });
      }
    }
  };

  /**
   * Handles opening the AppointmentEditView when the CreateIcon was clicked.
   */
  const handleAppointmentEdit = async (event: MouseEvent) => {
    if (!appointment) return;
    if (!isRecurring) return goToEditView();

    try {
      const editRecurrence = (await confirm({
        description: t('Views.AppointmentRecord.Edit.dialogDescription', {
          date: format(new Date(appointment.dateDay), 'dd.MM.yyyy'),
        }),
        onPrimary: { shouldResolve: true, valueReason: false },
        onSecondary: { shouldResolve: true, valueReason: true },
        primaryText: t('Views.AppointmentRecord.Edit.appointment'),
        secondaryText: t('Views.AppointmentRecord.Edit.recurrence'),
        title: t('Views.AppointmentRecord.Edit.dialogTitle'),
      })) as boolean;
      goToEditView(editRecurrence);
    } catch {
      /* User closed the dialog */
    }
  };

  /**
   * Handles redirecting after successfully submitting the appointment record and potentially
   * showing an incentive screen beforehand.
   */
  const handleAfterSubmit = async () => {
    if (isLastAppointmentOfToday())
      await confirm({
        autoCloseAfter: 5000,
        content: <ThankYou />,
        dialogProps: { fullScreen: true },
        primaryText: undefined,
        secondaryText: undefined,
        title: undefined,
        variant: 'dark',
      }).catch(() => null);

    topBar.handleBack && topBar.handleBack();
  };

  /**
   * Get the last appointment of the current day and evaluate if it is the same appointment as
   * in this current AppointmentRecordView.
   */
  const isLastAppointmentOfToday = () => {
    const todayAppointments = appointmentsState.appointments.filter(
      (appointment) => isToday(parseISO(appointment.dateDay)),
    );

    const todayLastAppointment = _.maxBy(todayAppointments, 'endTime');

    if (!todayLastAppointment || !appointment) return false;

    return (
      appointment.id === todayLastAppointment.id &&
      appointment.recurrenceIndex === todayLastAppointment.recurrenceIndex
    );
  };

  useEffect(() => {
    if (customer && appointment) {
      topBar.setTitle(`${customer.firstName} ${customer.lastName}`);
      topBar.setActionIcon(<DeleteOutlineIcon color="error" />);
      topBar.setHandleAction(() => deleteAppointment);
      topBar.setContent(
        <AppointmentInformation
          appointment={appointment}
          customer={customer}
          icon={
            <IconButton
              data-test-id={AppointmentRecordTestIds.editIcon}
              onClick={handleAppointmentEdit}
              size="small"
            >
              <CreateIcon fontSize="small" />
            </IconButton>
          }
        />,
      );
    }

    // Set TopBar properties back to default, when unmounting this view.
    return () => {
      topBar.resetTopBar();
    };
  }, [appointment, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page
      className={classes.root}
      data-test-id={AppointmentRecordTestIds.pageWrapper}
      title={t('Views.AppointmentRecord.title')}
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          {appointment && hasRecord && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  style={{
                    color: theme.palette.success.main,
                  }}
                >
                  <Box mb={2}>
                    <CheckCircleIcon />
                  </Box>

                  <Typography align="center" variant="h2">
                    {t('Views.AppointmentRecord.appointmentRecordSubmitted')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography align="center" variant="body1">
                  {t('Views.AppointmentRecord.yourRecordWasSubmitted')}
                </Typography>
              </Grid>
            </Grid>
          )}

          {appointment && !hasRecord && (
            <AppointmentRecordForm
              afterSubmit={handleAfterSubmit}
              appointment={appointment}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default observer(AppointmentRecordView);

import localforage from 'localforage';
import { action, runInAction } from 'mobx';

import axi from 'src/services/api';

import type { CustomersStateProps } from './state';
import CustomersState, { initialCustomersState } from './state';

/**
 * Initialize the CustomersState.
 * Always use local data first to populate the state and override it with server data if possible.
 */
export const initializeCustomersState = action(
  'initializeCustomersState',
  async () => {
    await fillWithLocalState();
    await fetchCustomers();

    runInAction(() => (CustomersState.initialized = true));
  },
);

/**
 * Fill with local state.
 */
export const fillWithLocalState = action('fillWithLocalState', async () => {
  try {
    const localCustomersState =
      await localforage.getItem<CustomersStateProps>('customersState');

    if (!localCustomersState) return;

    runInAction(() => {
      Object.assign(CustomersState, localCustomersState);
    });
  } catch (error: any) {
    console.warn('could not retrieve local data ', error);
  }
});

/**
 * Fetch customers.
 */
export const fetchCustomers = action('fetchCustomers', async () => {
  const { data: customers } = await axi.getCustomers();

  runInAction(() => (CustomersState.customers = customers));
});

/**
 * Resets customers state.
 */
export const resetCustomersState = action('resetCustomers', () => {
  Object.assign(CustomersState, initialCustomersState);
  localforage.removeItem('customersState');
});

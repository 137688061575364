import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import themeColors from 'src/theme/colors';
import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    cardStyle: {
      position: 'relative',
      height: '100%',
      minHeight: 35,
      padding: theme.spacing(1),
      borderBottomRightRadius: 10,
      borderTopRightRadius: 10,
      borderLeft: '3px solid',
      marginTop: '16px',
    },
    isPast: {
      color: theme.palette.text.secondary,
      borderColor: theme.palette.text.secondary,
      background: themeColors.shade1,
    },
    isFuture: {
      background: theme.palette.ternary.main,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
    recurrenceIcon: {
      fontSize: 'inherit',
      marginRight: theme.spacing(0.5),
    },
    statusIcon: {
      width: 16,
      marginRight: theme.spacing(0.5),
    },
    loadingSpinner: {
      backgroundColor: alpha(theme.palette.background.default, 0.5),
      width: '100%',
      top: 0,
      left: 0,
      position: 'absolute',
      zIndex: 1,
    },
  }),
  { name: 'BlockTimeCard' },
);

export default useStyles;

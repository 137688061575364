import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useConfirm } from 'src/contexts/ConfirmContext';
import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import { useUserStore } from 'src/services/auth/auth';
import { routes } from 'src/services/routing';
import Button from 'src/components/Button/Button';
import Page from 'src/components/Page/Page';

import useStyles from './ProfileView.styles';

const ProfileView: FC = () => {
  const { profileItem } = useStyles();
  const history = useHistory();
  const { user } = useUserStore();
  const topBar = useTopBar();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { logout } = useUserStore();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(
    () => topBar.setTitle(`${user?.firstName} ${user?.lastName}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.firstName, user?.lastName],
  );

  const handleLogout = async () => {
    try {
      await confirm({
        description: t('Views.Profile.Logout.dialogDescription'),
        primaryText: t('Views.Profile.Logout.title'),
        title: t('Views.Profile.Logout.dialogTitle'),
      });
      logout();
    } catch (reason) {
      if (reason === 'onSecondary') {
        enqueueSnackbar(t('Views.Profile.Logout.logoutCanceled'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('Views.Profile.Logout.logoutError'), {
          variant: 'error',
        });
      }
    }
  };

  return (
    <Page
      data-test-id={TestIds.views.profile.pageWrapper}
      title={t('Views.Profile.title')}
    >
      <Box display="flex" justifyContent="left" mb={2} mt={4}>
        <Typography variant="h3" fontSize="18px">
          {t('Views.Profile.TimeManagement.title')}
        </Typography>
      </Box>
      <Box
        className={profileItem}
        onClick={() => history.push(routes.app.routes.blockTimes.path)}
      >
        <Box display="flex">
          <EmojiPeopleIcon fontSize="small" />
          <Typography ml={1}>
            {t('Views.Profile.TimeManagement.blockTimes')}
          </Typography>
        </Box>
        <ChevronRightOutlinedIcon fontSize="small" />
      </Box>
      <Box display="flex" justifyContent="center" mt={8}>
        <Button
          color="secondary"
          loading={false}
          size="medium"
          type="submit"
          variant="contained"
          onClick={() => handleLogout()}
        >
          {t('Views.Profile.Logout.title')}
        </Button>
      </Box>
    </Page>
  );
};

export default ProfileView;

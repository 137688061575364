import localforage from 'localforage';
import _ from 'lodash';
import { action, runInAction } from 'mobx';

import type { GetMonthlySummariesProps } from 'src/services/api';
import axi from 'src/services/api';

import type { MonthlySummariesStateProps } from './state';
import MonthlySummariesState, { initialMonthlySummariesState } from './state';

/**
 * Initialize the MonthlySummariesState.
 */
export const initializeMonthlySummariesState = action(
  'initializeMonthlySummariesState',
  async () => {
    await fillWithLocalState();
    await fetchMonthlySummaries();

    runInAction(() => (MonthlySummariesState.initialized = true));
  },
);

/**
 * Fill with local state.
 */
export const fillWithLocalState = action('fillWithLocalState', async () => {
  try {
    const localMonthlySummariesState =
      await localforage.getItem<MonthlySummariesStateProps>(
        'monthlySummariesState',
      );

    if (!localMonthlySummariesState) return;

    runInAction(() => {
      Object.assign(MonthlySummariesState, localMonthlySummariesState);
    });
  } catch (error: any) {
    console.warn('could not retrieve local data ', error);
  }
});

/**
 * Fetch monthly summaries.
 * By default additional fetched summaries will be merged with existing summaries.
 * Setting 'replaceExisting: true' will replace existing summaries.
 */
export const fetchMonthlySummaries = action(
  'fetchMonthlySummaries',
  async (requestProps?: GetMonthlySummariesProps, replaceExisting = false) => {
    const { data: monthlySummaries } =
      await axi.getMonthlySummaries(requestProps);

    runInAction(() => {
      // Decide wether to replace or merge existing summaries.
      const fetchedSummaries = replaceExisting
        ? monthlySummaries
        : _.sortBy(
            _.unionBy(
              monthlySummaries,
              MonthlySummariesState.monthlySummaries,
              'yearMonth',
            ),
            'yearMonth',
          );

      return (MonthlySummariesState.monthlySummaries = fetchedSummaries);
    });
  },
);

/**
 * Reset monthly summaries state.
 */
export const resetMonthlySummariesState = action(
  'resetMonthlySummariesState',
  () => {
    Object.assign(MonthlySummariesState, initialMonthlySummariesState);
    localforage.removeItem('monthlySummariesState');
  },
);

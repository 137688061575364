import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    playerContainer: {
      // Target the actual lottie player container.
      '& > *': {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'end',
        pointerEvents: 'none',
      },
    },

    player: {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'inline-block',
      alignSelf: 'flex-end',
    },

    typographyContainer: {
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
      position: 'relative',
    },

    title: {
      color: theme.palette.secondary.light,
    },

    bodyText: {
      color: theme.palette.text.contrast,
    },
  }),

  { name: 'ThankYou' },
);

export default useStyles;

import { FC } from 'react';
import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';

import useStyles from './CardWrapper.styles';

export const CardWrapper: FC<BoxProps> = ({
  className,
  children,
  ...props
}) => {
  const { root } = useStyles();

  return (
    <Box className={clsx(root, className)} {...props}>
      {children}
    </Box>
  );
};

import { makeStyles } from '@mui/styles';

import type { ConfirmOptions } from 'src/contexts/ConfirmContext';
import type { Theme } from 'src/theme/types';

interface VariantProps {
  variant: ConfirmOptions['variant'];
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    paperRoot: ({ variant }: VariantProps) => ({
      backgroundImage:
        variant === 'dark' ? theme.palette.gradients.primary : undefined,
    }),
    closeButton: ({ variant }: VariantProps) => ({
      color: variant === 'dark' ? theme.palette.secondary.light : undefined,
      padding: 0,
    }),
    primaryButton: {
      color: theme.palette.primary.light,
    },
    secondaryButton: {},
  }),
  { name: 'ConfirmDialog' },
);

export default useStyles;

import { makeStyles } from '@mui/styles';

import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& [class*="MuiSelect-root"]': {
        '& $durationLabel': {
          display: 'none',
        },
      },
    },

    durationLabel: {
      marginLeft: 'auto',
    },

    radioLabel: {
      display: 'inline-flex',
      verticalAlign: 'top',
      alignItems: 'center',
    },
  }),

  { name: 'AppointmentForm' },
);

export default useStyles;

import { compareAsc, intervalToDuration, isValid } from 'date-fns';
import { t } from 'i18next';

import { TimeStep } from 'src/types';

import getTimeSteps from './getTimeSteps';

/**
 * Formats the timeSteps for the endTime selection.
 * Hides invalid options and adds a durationString.
 */
const formatEndTimeSteps = (startTimeDate?: Date) => {
  const timeSteps = getTimeSteps('end');

  return timeSteps.reduce<TimeStep[]>((formattedTimeSteps, timeStep) => {
    const endTimeDate = timeStep.dateObject;
    const hasPositiveDuration =
      startTimeDate && compareAsc(startTimeDate, endTimeDate) < 0;
    const duration: Duration = intervalToDuration({
      end: endTimeDate,
      start: (isValid(startTimeDate) && startTimeDate) || endTimeDate,
    });

    // Hides options with a negative duration
    if (startTimeDate && !hasPositiveDuration) {
      timeStep.hide = true;
    }

    // Shows options with a positive duration and add the durationString.
    if (startTimeDate && hasPositiveDuration) {
      // Only hides options when the duration is longer than 24 hours.
      timeStep.hide = !!duration.days;

      timeStep.durationString = duration.hours
        ? duration.minutes
          ? t('AppointmentForm.durationHoursAndMinutes', {
              hours: duration.hours,
              minutes: duration.minutes,
            })
          : t('AppointmentForm.durationHours', {
              hours: duration.hours,
            })
        : t('AppointmentForm.durationMinutes', {
            minutes: duration.minutes,
          });
    }

    formattedTimeSteps.push(timeStep);

    return formattedTimeSteps;
  }, []);
};

export default formatEndTimeSteps;

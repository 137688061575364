import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BlockIcon from '@mui/icons-material/Block';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import { BlockTime } from 'src/types';

import { CardWrapper } from '../CardWrapper/CardWrapper';

import useStyles from './WeeklyCard.styles';

interface Props {
  blockTime: BlockTime;
}

const WeeklyBlockTimeCard: FC<Props> = ({ blockTime }) => {
  const classes = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'Views.Home',
  });

  return (
    <CardWrapper className={clsx(classes.card, classes.blockTime)}>
      <Box className={classes.blockTimeDescription}>
        <Typography variant="h6">
          {t('blockTimeNoteStartTime', {
            start: blockTime.startTime.slice(0, -3),
          })}
        </Typography>
        <Typography variant="h6">
          {t('blockTimeNoteEndTime', {
            start: blockTime.endTime.slice(0, -3),
          })}
        </Typography>
      </Box>
      <BlockIcon fontSize="inherit" />
    </CardWrapper>
  );
};

export default WeeklyBlockTimeCard;

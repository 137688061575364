import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme/types';

interface TopBarHeightProps {
  topBarHeight: number | undefined;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
    },

    // The contentWrapper element is needed to be able to only scroll the content with the scroll bar being
    // rendered at the right border of the viewport rather than at the right border of the "content" element.
    contentWrapper: ({ topBarHeight }: TopBarHeightProps) => ({
      marginTop: `${topBarHeight || theme.settings.topBarHeight}px`,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    }),

    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }),

  { name: 'DefaultLayout' },
);

export default useStyles;

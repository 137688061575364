import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckCircle as CheckCircleIcon,
  ChildCare as ChildCareIcon,
  ExpandMore as ExpandMoreIcon,
  Room as RoomIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material';
import type { AccordionProps } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import { TestIds } from 'src/testIds';
import { WorkSummary } from 'src/types';

import useStyles from './SummaryAccordion.styles';

const ComponentTestIds = TestIds.components.summaryAccordion;

export interface SummaryAccordionProps
  extends Omit<AccordionProps, 'children'> {
  summaryType: 'done' | 'planned' | 'predicted';
  workHoursPredicted?: number | null;
  workSummary?: WorkSummary | null;
}

export const SummaryAccordion: FC<SummaryAccordionProps> = ({
  className,
  summaryType,
  workHoursPredicted,
  workSummary,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const title = workSummary?.totalHours || workHoursPredicted || 0;
  const subtitle = t(`SummaryAccordion.${summaryType}`);

  let summaryTypeIcon;

  switch (summaryType) {
    case 'done':
      summaryTypeIcon = <CheckCircleIcon fontSize="small" />;
      break;

    case 'planned':
      summaryTypeIcon = <ScheduleIcon fontSize="small" />;
      break;

    case 'predicted':
      summaryTypeIcon = <ChildCareIcon fontSize="small" />;
      break;

    default:
      summaryTypeIcon = undefined;
      break;
  }

  return (
    <>
      <Accordion
        className={clsx(classes.root, className)}
        data-test-id={ComponentTestIds.accordion}
        {...props}
      >
        <AccordionSummary
          className={clsx(classes.accordionSummary, summaryType)}
          classes={{
            content: classes.accordionSummaryContent,
            expandIconWrapper: classes.accordionSummaryExpandIcon,
          }}
          expandIcon={summaryTypeIcon}
        >
          <Typography className={classes.title} variant="h2">
            {t('SummaryAccordion.totalHours', { hours: title })}
          </Typography>

          <Typography className={classes.subtitle} variant="body1">
            {subtitle}

            {summaryType !== 'predicted' && (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
          </Typography>
        </AccordionSummary>

        {workSummary && (
          <AccordionDetails
            className={workSummary.items.length > 6 ? 'scrollable' : undefined}
            classes={{ root: classes.accordionDetailsRoot }}
            data-test-id={ComponentTestIds.summaryItem}
          >
            {workSummary.items.map((summaryItem) => (
              <Box
                className={classes.summaryItem}
                key={summaryItem.customerName}
              >
                <Typography>
                  {t('SummaryAccordion.totalHoursWithName', {
                    customerName: summaryItem.customerName,
                    hours: summaryItem.hours,
                  })}
                </Typography>
              </Box>
            ))}
          </AccordionDetails>
        )}
      </Accordion>

      {summaryType === 'done' && workSummary && (
        <Paper className={classes.attachment}>
          <Typography className={classes.title} variant="h2">
            <RoomIcon fontSize="inherit" />
            {workSummary.totalTravelDistance} km
          </Typography>

          <Typography variant="body1">{subtitle}</Typography>
        </Paper>
      )}
    </>
  );
};

export default SummaryAccordion;

import { Grid, Typography } from '@mui/material';

export const SectionTitle = ({
  title,
  noTopMargin,
}: {
  title: string;
  noTopMargin?: boolean;
}) => (
  <Grid item xs={12} mt={noTopMargin ? 0 : 4}>
    <Typography variant="h4">{title}</Typography>
  </Grid>
);

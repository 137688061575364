/* eslint-disable eqeqeq */
import { format } from 'date-fns';

import i18n from 'src/services/i18n/i18n';
import {
  CareAllowanceOptions,
  NeedsAssessmentFormData,
  NeedsAssessmentPostData,
} from 'src/components/NeedsAssessment/needsAssessment';

interface RequiredServicesLabel {
  [key: string]: string;
}

const requiredServicesLabel: RequiredServicesLabel = {
  bedLinen: i18n.t('NeedsAssessmentForm.RequiredServices.bedLinen'),
  cooking: i18n.t('NeedsAssessmentForm.RequiredServices.cooking'),
  errands: i18n.t('NeedsAssessmentForm.RequiredServices.errands'),
  ironing: i18n.t('NeedsAssessmentForm.RequiredServices.ironing'),
  normalChores: i18n.t('NeedsAssessmentForm.RequiredServices.normalChores'),
  shopping: i18n.t('NeedsAssessmentForm.RequiredServices.shopping'),
  strolling: i18n.t('NeedsAssessmentForm.RequiredServices.strolling'),
  windowCleaning: i18n.t('NeedsAssessmentForm.RequiredServices.windowCleaning'),
};

const mapToPostData = (
  formData: NeedsAssessmentFormData,
): NeedsAssessmentPostData => {
  const {
    receivingCareAllowance,
    requiredServices,
    specifics,
    startDate,
    ...restFormData
  } = formData;

  const postData: NeedsAssessmentPostData = {
    deploymentPlanning: {
      careAllowance: false,
      dispositionHoursBiWeekly: 0,
      dispositionHoursWeekly: 0,
      dispositionSelection: 'weekly',
      privateAccount: false,
    },
    freeOfChargeAidRequested: {
      homeEmergencyCall: false,
      nursingAdvice: false,
      shouldOrderCareBox: false,
    },
    healthInsuranceBenefits: {
      selection: 'withoutVP',
    },
    healthInsuranceInfo: {
      budgetEl: 0,
      budgetVp: 0,
      registeredCarer: '',
      vpAlreadyBilled: false,
    },
    receivingCareAllowance: {
      careService: undefined,
      qualityAssuranceInterview: false,
      qualityAssurancePerson: undefined,
      value: CareAllowanceOptions.NO,
    },
    requiredServices: [],
    signatureCustomer: '',
    specifics: { animals: '', others: '', smoker: false },
    startDate: '',
    teamManagerComment: '',
  };

  // Create 'teamManagerComment' accordingly to selection
  if (formData.healthInsuranceBenefits.selection === 'EL&VP') {
    postData.teamManagerComment = 'VP Ja / EL';
  } else {
    postData.teamManagerComment = 'VP Nein / EL';
  }

  if (formData.deploymentPlanning.careAllowance) {
    postData.teamManagerComment += ' / UW';
  }
  if (formData.deploymentPlanning.privateAccount) {
    postData.teamManagerComment += ' / PR';
  }

  // Remove fields according to selection path for 'receivingCareAllowance'
  postData.receivingCareAllowance.value = receivingCareAllowance.value;

  const takingInterview =
    receivingCareAllowance.qualityAssuranceInterview === 'yes';

  // Convert string-value to boolean
  postData.receivingCareAllowance.qualityAssuranceInterview = takingInterview;

  if (
    receivingCareAllowance.value == CareAllowanceOptions.YES &&
    takingInterview
  ) {
    postData.receivingCareAllowance.qualityAssurancePerson =
      receivingCareAllowance.qualityAssurancePerson;
    delete postData.receivingCareAllowance.careService;
  } else {
    postData.receivingCareAllowance.careService =
      receivingCareAllowance.careService;
    delete postData.receivingCareAllowance.qualityAssurancePerson;
  }

  // Convert RequiredServices for usage in PDF generation (backend)
  Object.entries(requiredServices).forEach(([label, value]) =>
    postData.requiredServices.push({
      label: requiredServicesLabel[label],
      value: value,
    }),
  );

  // Convert 'specifics.smoker' to boolean
  const { smoker, ...restFormSpecifics } = specifics;
  postData.specifics = {
    smoker: specifics?.smoker === 'yes',
    ...restFormSpecifics,
  };

  // Convert 'startDate' to string while removing timestamp
  postData.startDate = format(new Date(startDate), 'yyyy-MM-dd');

  // Remove submit (only needed in Form)
  delete restFormData.submit;

  return { ...postData, ...restFormData };
};

export default mapToPostData;

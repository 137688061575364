import type { ChangeEvent, FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FactCheckOutlined as FactCheckOutlinedIcon,
  Home as HomeIcon,
  List as ListIconNew,
  Person as PersonIcon,
} from '@mui/icons-material/';
import type { BottomNavigationProps } from '@mui/material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import clsx from 'clsx';

import { TestIds } from 'src/testIds';
import { getRouteByPath, routes } from 'src/services/routing';

import useStyles from './BottomNav.styles';

const ComponentTestIds = TestIds.components.bottomNav;

export interface BottomNavProps
  extends Omit<BottomNavigationProps, 'children'> {}

export const BottomNav: FC<BottomNavProps> = ({ className, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const currentRoute = getRouteByPath(location.pathname);
  const { t } = useTranslation();
  const [value, setValue] = useState(currentRoute?.routeName);

  const handleChange = (event: ChangeEvent<{}>, value: any) => {
    setValue(value);

    switch (value) {
      case 'home':
        history.push(routes.app.routes.home.path);
        break;

      case 'appointmentList':
        history.push(routes.app.routes.appointmentList.path);
        break;

      case 'monthlySummary':
        history.push(routes.app.routes.monthlySummary.path);
        break;

      case 'profile':
        history.push(routes.app.routes.profile.path);
        break;
    }
  };

  return (
    <BottomNavigation
      className={clsx(classes.root, className)}
      data-test-id={ComponentTestIds.wrapper}
      onChange={handleChange}
      showLabels
      value={value}
      {...props}
    >
      <BottomNavigationAction
        classes={{ root: classes.actionButton }}
        icon={<HomeIcon />}
        label={t('BottomNav.home')}
        value="home"
      />

      <BottomNavigationAction
        classes={{ root: classes.actionButton }}
        icon={<ListIconNew />}
        label={t('BottomNav.appointmentList')}
        value="appointmentList"
      />

      <BottomNavigationAction
        classes={{ root: classes.actionButton }}
        icon={<FactCheckOutlinedIcon />}
        label={t('BottomNav.monthlySummary')}
        value="monthlySummary"
      />

      <BottomNavigationAction
        classes={{ root: classes.actionButton }}
        icon={<PersonIcon />}
        label={t('BottomNav.profileView')}
        value="profile"
      />
    </BottomNavigation>
  );
};

export default BottomNav;

import localforage from 'localforage';
import { autorun, observable, toJS } from 'mobx';

import type { AppointmentRecord, AppointmentRecordPostData } from 'src/types';

export interface AppointmentRecordsStateProps {
  appointmentRecords: AppointmentRecord[];
  appointmentRecordsPending: AppointmentRecordPostData[];
  initialized: boolean;
}

export const initialAppointmentRecordsState: AppointmentRecordsStateProps = {
  appointmentRecords: [],
  appointmentRecordsPending: [],
  initialized: false,
};

const AppointmentRecordsState = observable<AppointmentRecordsStateProps>(
  initialAppointmentRecordsState,
);

autorun(() => {
  // ! Note: 'initialized' is set to 'false' after hot module reload in development environment.
  if (!AppointmentRecordsState.initialized) {
    return;
  }

  // Converts the observable-object to a javascript-object as mobx-observables can not be stored.
  const appointmentRecordsState = toJS(AppointmentRecordsState);

  localforage
    .setItem('appointmentRecordsState', appointmentRecordsState)
    .catch((error: any) => console.warn('could not be stored locally ', error));
});

export default AppointmentRecordsState;
